import { combineReducers } from 'redux'

import auth from './auth'
import status from "./results";
import global from "./global";

export default combineReducers({
 auth,
 status,
 global
})

import React, { useEffect } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import * as Const from '../../../constants'
import { Controller } from "react-hook-form";

function InputArray(props) {
  const [shouldAutoFocus, setShouldAutoFocus] = React.useState(false);
  const [indexes, setIndexes] = React.useState([0]);
  const maxIndex = React.useRef(0)
  const lastInputRef = React.useRef()
  const name = props.name
  const defaultValues = props.defaultValues
  const maxLength = props.maxLength ? props.maxLength : Const.INPUT_ARRAY_MAX_LENGTH;

  const add = () => {
    const maxIndex = Math.max(...indexes)
    setShouldAutoFocus(true)
    setIndexes(prevIndexes => [...prevIndexes, maxIndex + 1]);
  };

  const remove = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
  };

  useEffect(() => {
    if (shouldAutoFocus) {
      setShouldAutoFocus(false)
      const maxIndexNow = Math.max(...indexes)
      if (maxIndex.current < maxIndexNow) {
        maxIndex.current = maxIndexNow
        if (lastInputRef.current) {
          lastInputRef.current.focus()
        }
      }
    }
  }, [indexes, shouldAutoFocus]);

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues.length === 0) {
        setIndexes(Array.from(Array(defaultValues.length),(x,i)=>i)) 
        setTimeout(() => {
          setIndexes(Array.from(Array(defaultValues.length + 1),(x,i)=>i)) 
        }, 100);
      } else {
        setIndexes(Array.from(Array(defaultValues.length + 1),(x,i)=>i)) 
      }
    }
  }, [defaultValues]);

  const renderAddonBtn = (index) => {
    const maxIndex = Math.max(...indexes)
    if (index === maxIndex) {
      if (indexes.length < maxLength) {
        return <Button color="secondary" onClick={add}>+</Button> 
      } else {
        return null
      }
    } else {
      return <Button color="secondary" onClick={remove(index)}>-</Button>
    }
  }

  let Child = props.child
  if (!Child) {
    Child = Input
  }
  return indexes.map((index) => {
    const fieldName = `${name}[${index}]`;
    let defaultValue = ''
    if (props.defaultValues && props.defaultValues.length > 0) {
      defaultValue = props.defaultValues[index]
      if (!defaultValue) {
        defaultValue = ''
      }
    }
    return (
      <InputGroup key={index} className="mb-2">
        <Controller
          as={Child}
          control={props.control}
          name={fieldName}
          defaultValue={defaultValue}
          innerRef={(e) => {
            const maxIndex = Math.max(...indexes)
            if (index === maxIndex) {
              lastInputRef.current = e
            }
          }}
        />
        <InputGroupAddon addonType="append">
          {renderAddonBtn(index)}
        </InputGroupAddon>
      </InputGroup>
    );
  })
}

export default InputArray;

export const INPUT_ARRAY_MAX_LENGTH = 20
export const DEFAULT_PAGE_SIZE = 30

export const DEFAULT_PAGE_SMALL_SIZE = [
  {
    text: '30', value: 10
  },
  {
    text: '50', value: 30
  },
  {
    text: '100', value: 50
  }
]

export const DEFAULT_PAGE_SIZE_LIST = [
  {
    text: '30', value: 30
  },
  {
    text: '50', value: 50
  },
  {
    text: '100', value: 100
  }
]

export const DEFAULT_PAGINATION_CONFIG = {
  sizePerPageList: [
    {
      text: '30', value: 30
    },
    {
      text: '50', value: 50
    },
    {
      text: '100', value: 100
    }
  ],
}

export const SORT_ORDER = [
  {
    id: 1,
    label: 'ASC',
    value: 'asc'
  },
  {
    id: 2,
    label: 'DESC',
    value: 'desc'
  }
];

export const STATUS_OPTIONS = [
  {
    id: 1,
    value: "active",
    display_name: "Active",
  },
  {
    id: 2,
    value: "inactive",
    display_name: "Inactive",
  },
];

// Redux key
export const SET_TOKEN = 'SET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';

export const UPDATE_RESULT_STATUS = "UPDATE_RESULT_STATUS";
export const BACKEND_CLIENT_VERSION = "BACKEND_CLIENT_VERSION";

export const IS_NEW_BACKEND_CLIENT_VERSION = "IS_NEW_BACKEND_CLIENT_VERSION";






// ========================================================================

export const QUILL_BASIC_OPTIONs_TOOLBARD = [
  ['emoji']
];

export const QUILL_FULL_OPTIONS_TOOLBAR = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
  ['link', 'video']['emoji'],
  ['clean'],
  ['file-text'],
  ['convert-html']
];

export const QUILL_FULL_OPTIONS_FORMATS = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
  'image',
  'video'
];

export const QUILL_BASIC_OPTIONS_TOOLBAR = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'align': [] }],
  ['clean'],
  ['image']
  ['file-text'],
  ['convert-html']
]

export const QUILL_BASIC_OPTIONS_FORMATS = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula'
]

export const MAX_SIZE = 2000000;

export const MAX_SIZE_UPLOAD_BANNERS = 5000000;

export const LIST_GAME_HAS_KEI = ["silang_homo", '5050', 'kembang_kempis', '5050_2d', 'tepi_tengah', 'dasar'];

export const sortFieldBetsPage = [
  {
    id: 1,
    label: 'Bet',
    value: 'bet'
  },
  {
    id: 2,
    label: 'Total Paid',
    value: 'total_paid'
  },
  {
    id: 3,
    label: 'Date',
    value: 'date'
  }
];


export const STATUSES_OF_MEMBER_IN_MEMBER_LIST = [
  {
    id: 1,
    origin_status: "suspend",
    done_status: "suspended",
    undo_status: "unsuspend"
  },
  {
    id: 2,
    origin_status: "lock",
    done_status: "locked",
    undo_status: "unlock"
  },
  {
    id: 3,
    origin_status: "ban",
    done_status: "banned",
    undo_status: "unban"
  }
];

export const STATUSES_OF_COMPANY_IN_COMPANY_LIST = [
  {
    id: 1,
    origin_status: "suspend",
    done_status: "suspended",
    undo_status: "unsuspend"
  },
  {
    id: 2,
    origin_status: "lock",
    done_status: "locked",
    undo_status: "unlock"
  },
  {
    id: 3,
    origin_status: "inactivate",
    done_status: "inactive",
    undo_status: "activate"
  }
];

export const STATUSES_OF_MASTER_WHITEALBEL_LIST = [
  {
    id: 1,
    origin_status: "suspend",
    done_status: "suspended",
    undo_status: "unsuspend"
  },
  {
    id: 2,
    origin_status: "lock",
    done_status: "locked",
    undo_status: "unlock"
  }
];
import isEmpty from "lodash/isEmpty";

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export function accountType() {
  if (!isEmpty(JSON.parse(localStorage.getItem('user')))) {
    const { account_type } = JSON.parse(localStorage.getItem('user'));
    if (account_type) {
      if (account_type.includes('admin')) {
        return 'Admin';
      }
      
      if (account_type.includes("master whitelabel")) {
        return 'Master';
      }

      if (account_type.includes("whitelabel")) {
        return 'Whitelabel';
      }
      
      return "Company"
    }
  }
}

export function isMainAccount() {
  if (!isEmpty(JSON.parse(localStorage.getItem('user')))) {
    const { account_type } = JSON.parse(localStorage.getItem('user'));
    if (account_type) {
      if (account_type.includes("sub")) {
        return false
      }
      return true;
    }
  }
}

export function escape(str) {
  const stringify = JSON.stringify(str);
  return stringify.replace(/\\n/g, "\\n")
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f").substring(1, stringify.length - 1);
}

export const copyToClipboard = value => {
  const $textarea = document.createElement('textarea');
  $textarea.value = value;
  document.body.appendChild($textarea);
  $textarea.select();
  document.execCommand('copy');
  document.body.removeChild($textarea);
};

export const renderStatusBadgeClassName = (status, transfer_status) => {
  if (transfer_status === "manual_transferred" || transfer_status === "auto_transferred") {
    return "transferred"
  }
  switch (status) {
    case 'lost':
      return 'dark';
    case 'cancelled':
      return 'warning';
    case 'running':
      return 'info';
  default:
    return 'success'
  }
}
import React, {
	forwardRef,
	useEffect,
	useState,
	useImperativeHandle,
	useCallback,
} from 'react';
import get from 'lodash/get';

import LoginAPI from 'api/LoginAPI';

const Captcha = forwardRef(({ height = 25, width = 60, onChange = () => {}, className = '' }, ref) => {
	const [captcha, setCaptcha] = useState({});

	const loadCaptcha = useCallback(() => {
		setCaptcha({}); // Reset captcha data

		LoginAPI.generateCaptcha({
			height,
			width,
			"color": { R: 0,G: 0,B: 0,A: 255 },
      "bg_color": { R: 244, G: 244, B: 244, A: 255 },
		}).then((data) => {
			if (get(data, 'success')) {
				setCaptcha(get(data, 'data'));
			}
		});
  }, [setCaptcha, height, width]);

	useEffect(() => {
		loadCaptcha();
	}, [loadCaptcha]);

	useEffect(() => {
		onChange(captcha.captcha_id);
	}, [onChange, captcha.captcha_id]);

	useImperativeHandle(ref, () => ({
		reset: () => {
			loadCaptcha();
		}
	}), [loadCaptcha]);

	return (
		<div style={{
			height,
			width,
			justifyContent: 'center'
		}}
		>
			{
				captcha.image &&
				<img
					alt=''
					height={25}
					width={60}
					src={captcha.image}
				/>
			}
		</div>
	);
});

export default Captcha;

import React, { useState, useEffect } from 'react';
import { formatTime } from '../../../api/Utils'

const style = {
  width: '230px',
}

const RealTimeDisplay = (props) => {
  const [timeLeft, setTimeLeft] = useState(formatTime(new Date()));

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(formatTime(new Date()));
    }, 1000);
  }, [timeLeft]);
  return (
    <div className="ml-4" style={style}>
      {timeLeft}
    </div>
  );
}

export default RealTimeDisplay;

import React, { memo } from "react";
import {
  Col,
  Row,
  FormText,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const SortInput = memo((props) => {
  const [t] = useTranslation()
  const { register, onChange, fields, defaultSortOrder = "asc", colWidth = "6", colWidthXs = "6" } = props;

  return (
    <Row>
      <Col xs={colWidthXs} xl={colWidth}>
        <FormText color="muted">{t("Sort")}</FormText>
        <Input
          bsSize="sm"
          innerRef={register}
          type="select"
          name="sort_field"
          onChange={onChange}
          defaultValue={props.defaultSortField}
        >
          {fields.map((value, index) => {
            if (value.sortable) {
              return (<option value={value.dataField} key={index}>{t(value.text)}</option>) 
            }
            return null
          })}
        </Input>
      </Col>
      <Col xs={colWidthXs} xl={colWidth}>
        <FormText color="muted">{t("Sort Order")}</FormText>
        <Input
          bsSize="sm"
          innerRef={register}
          type="select"
          name="sort_order" 
          onChange={onChange}
          defaultValue={defaultSortOrder}
        >
          <option value='asc'>{t('ASC')}</option>
          <option value='desc'>{t('DESC')}</option>
        </Input>
      </Col>
    </Row>
  )
});

export default SortInput;
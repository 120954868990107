export default {
  "1 popup banner with HD quality. Max 5MB": "1 hình ảnh với chất lượng HD. Nặng tối đa 5MB",
  "2 banners with dimension 872 x 147 and 294 x 364. Max 5MB": "2 hình ảnh với kích thước 872 x 147 và 294 x 364",
  "2D depan": "2D Depan",
  "2D tengah": "2D Tengah",
  "2d": "2D",
  "2d_depan": "2D Depan",
  "2d_tengah": "2D Tengah",
  "3d": "3D",
  "4d": "4D",
  "4d3d2d": "4D / 3D / 2D",
  "4d3d2d_set": "4D / 3D / 2D",
  "4d_set": "4D Set",
  "5050": "50-50",
  "5050_2d": "50-50 2D",
  "APK Link": "Đường dẫn tải APK",
  "APK Message": "Tin Nhắn",
  "APK Name": "Tên APK",
  "APK Status": "Trạng thái APK",
  "ASC": "A -\u003e Z (VN)",
  "About Us": "Về chúng tôi",
  "Account ID": "ID của tài khoản",
  "Account Name": "Tên tài khoản",
  "Account Name required": "Yêu cầu tên tài khoản.",
  "Account Number": "Số tài khoản",
  "Account Number length": "Độ dài yêu cầu của số tài khoản",
  "Account Number required": "Yêu cầu số tài khoản",
  "Account name or number": "Tên hoặc số tài khoản",
  "Account type": "Loại tài khoản",
  "AccountNumberRangeDigits": "Số tài khoản có độ dài {{min_number_of_digits}} - {{number_of_digits}} ký tự. Phải là chữ số.",
  "Action": "Hành Động",
  "Active": "Hoạt động",
  "Active Companies": "Active Companies",
  "Active Members": "Active Members",
  "Active Whitelabels": "Active Whitelabels",
  "Activity": "Hành động",
  "Add": "Cộng",
  "Add Result": "Thêm kết quả",
  "Add a new promotion": "Thêm một chương trình khuyến mãi mới",
  "Add promotion": "Thêm chương trình khuyến mãi",
  "Add result success": "Thêm kết quả thành công. Sẽ mất một ít phút để tính toán thắng thua",
  "Admin Log": "Là log đó mà",
  "After Login": "Sau khi đăng nhập",
  "Agent Type": "Loại tài khoản",
  "All": "Tất cả",
  "All Activities": "Tất cả hoạt động",
  "All Banks": "Tất cả ngân hàng",
  "All Companies": "Tất cả công ty",
  "All Company": "Tất cả công ty",
  "All Groups": "Tất cả các nhóm",
  "All Member": "Tất cả thành viên",
  "All Members": "Tất cả thành viên",
  "All Providers": "Tất cả các nhà cung cấp",
  "All WhiteLabels": "Tất cả WhiteLabels",
  "All Whitelabels": "Tất cả Whitelabels",
  "All game": "Tất cả game",
  "Allow to access all reports": "Cho phép vào hết báo cáo",
  "Allow to access and manage the Bank Account List": "Cho phép truy cập và quản lý danh sách tài khoản ngân hàng",
  "Allow to access and manage the Deposit and Withdraw Request List": "Cho phé truy cập và quản lý danh sách đặt cọc và yêu cầu rút tiền",
  "Allow to access and manage the Member List": "Cho phép truy cập và quản lý danh sách thành viên",
  "Allow to access and manage the Result List": "Cho phép truy cập và quản lý danh sách kết quả",
  "Allow to access and manage the Whitelabel List": "Cho phép truy cập và quản lý danh sách Whitelabel",
  "Allow to access and update the Site Config": "Cho phép truy cập tất cả chức năng của CTMZT",
  "Allow to access the Announcement menu": "Cho phép vào bảng thông báo",
  "Allow to access the Bank Management menu": "Cho phép vào quản lý ngân hàng",
  "Allow to access the Bets menu": "Cho phép vào bảng cược",
  "Allow to access the Bonus Payment menu": "Cho phép truy cập menu thanh toán tiền thưởng",
  "Allow to access the Company menu": "Cho phép vào hết bảng điều khiển công ty",
  "Allow to access the Dashboard menu": "Cho phép vào bảng điều khiển và thống kê thiết bị đang sử dụng",
  "Allow to access the Global Member List menu": "Cho phép vào danh sách tất cả người chơi",
  "Allow to access the Logs menu": "Cho phép vào bảng hành động",
  "Allow to access the Memo menu": "Cho phép vào bảng ghi nhớ",
  "Allow to access the Reports menu": "Cho phép truy cập vào menu báo cáo",
  "Allow to access the Result List menu": "Cho phép vào bảng kết quả",
  "Allow to access the Whitelabel menu": "Cho phép vào hết mục Whitelabel",
  "Allow to access these menus": "Cho phép vào những bảng sau",
  "Allow to customize the Company site": "Cho phép tuỳ chỉnh trang web công ty",
  "Allow to edit": "Cho phép chỉnh sửa",
  "AllowedExtensionsList": "Chấp nhận file có đuôi: {{list}}",
  "Amount": "Số lượng",
  "Amount required": "Yêu cầu nhập số tiền",
  "AmountFreebetFormText": "Dùng để tính tiền bonus",
  "AmountHintMinValue": "Nhập số lượng, lớn hơn {{minValue}}",
  "Analyze Result": "Phân tích",
  "App List": "DS ứng dụng",
  "App Logo": "Logo ứng dụng",
  "App Name": "Tên ứng dụng",
  "Apply": "Áp dụng",
  "Approve": "Duyệt",
  "Approve upload bonus success": "Tải file lên thành công",
  "Approved Deposit Requests": "Yêu cầu tiền gửi được chấp thuận",
  "Approved Withdrawal Requests": "Yêu cầu rút tiền được chấp thuận",
  "Are you sure": "Bạn có chắc không",
  "Are you sure want to approve upline bank account?": "Bạn có chắc muốn phê duyệt tài khoản ngân hàng tuyến trên này?",
  "Are you sure want to cancel request or transaction?": "Bạn có chắc muốn huỷ yêu cầu hoặc giao dịch?",
  "Are you sure want to cancel this result?": "Bạn có chắc muốn huỷ kết quả này?",
  "Are you sure want to create bank group?": "Bạn có chắc muốn tạo nhóm ngân hàng?",
  "Are you sure want to create manual DW?": "Bạn có chắc chắn muốn nạp / rút tiền cho member này?",
  "Are you sure want to deactivate this pool?": "Bạn có muốn vô hiệu hoá pool này?",
  "Are you sure want to delete bank group?": "Bạn có chắc muốn xoá nhóm ngân hàng?",
  "Are you sure want to delete company bank account?": "Bạn có chắc muốn xoá tài khoản ngân hàng",
  "Are you sure want to delete this mail?": "Bạn có chắc muốn xóa email này?",
  "Are you sure want to edit credit?": "Bạn có chắc chắn muốn chỉnh sửa tín dụng?",
  "Are you sure want to reject upline bank account?": "Bạn có chắc muốn từ chối tài khoản ngân hàng tuyến trên này?",
  "Are you sure you want to cancel this bet?": "Bạn có chắc muốn hủy bet này ko?",
  "At": "Vào lúc",
  "Auto Memo": "Ghi nhớ tự động",
  "Back": "Quay lại",
  "Balance": "Số dư",
  "Ban": "Cấm",
  "Bank": "Ngân Hàng",
  "Bank Account": "Tài khoản ngân hàng",
  "Bank Account Banned": "Khoá T/K Ngân Hàng",
  "Bank Account List": "Danh sách tài khoản ngân hàng",
  "Bank Account Name": "Tên tài khoản ngân hàng",
  "Bank Account Name / Bank Account Number": "Tên tài khoản / Số tài khoản",
  "Bank Account No": "Số tài khoản ngân hàng",
  "Bank Balance": "Số dư tài khoản",
  "Bank Group": "Nhóm Ngân Hàng",
  "Bank Information": "Thông tin ngân hàng",
  "Bank Initials": "Mã ngân hàng",
  "Bank Name": "Tên ngân hàng",
  "Bank Transfer Logs": "Nhật ký chuyển khoản ngân hàng",
  "Bank account name or number": "Tên tài khoản / Số tài khoản",
  "Bank initals or Bank name": "Mã ngân hàng hoặc Tên ngân hàng",
  "BankAccountNumberHint": "Yêu cầu {{number_of_digit}} con số. Chỉ cho phép số.",
  "Banned": "Bị cấm",
  "Banned Date": "Ngày bị cấm",
  "Banned Members": "Banned Members",
  "Banner": "banner",
  "Banner is required": "Yêu cầu upload banner",
  "BannerPopupTips": "1 hình ảnh với chất lượng HD. Nặng tối đa {{size}}MB",
  "Banners": "Banner",
  "Banners Image": "Ảnh banners",
  "BannersMobileAndDesktopTips": "Khuyến nghị kích thước ảnh {{value}} và chất lượng HD. Nặng tối đa {{size}}MB",
  "BannersTips": "Khuyến nghị kích thước ảnh {{value}} và chất lượng HD. Nặng tối đa {{size}}",
  "Before Login": "Trước khi đăng nhập",
  "Beginning Balance": "Số dư đầu kỳ",
  "Bet": "Cược",
  "Bet (Total)": "Cược (tổng cộng)",
  "Bet ID": "Mã Bet",
  "Bet Lose": "Đặt cược thua",
  "Bet Options": "Các lựa chọn đặt cược",
  "Bet Win": "Đặt cược thắng",
  "Bet/Game ID": "Mã Bet/ Game",
  "Bet/Games ID": "Mã Bet/ Game",
  "Bet/Lose": "Cược/Thua",
  "Bets List": "DS bet",
  "Bets Table": "Bảng bet",
  "Black": "Đen",
  "Blacklist IP Address": "Danh sách IP đen",
  "BlacklistIpHint": "Tối đa 20 IPs. Dùng chặn IP của người chơi khi vào trang của công ty. Để trống để bỏ kiểm tra IP",
  "Block Numbers": "Chặn số",
  "Blue White": "Xanh trắng",
  "Bonus": "Tiền thưởng",
  "Bonus Deposit": "Thưởng Nạp Tiền",
  "Bonus New Member": "Thưởng Member Mới",
  "Bonus Referral": "Tiền hoa hồng",
  "Bonus Report": "Báo cáo thưởng",
  "Bonus/Promo/Freebet": "Tiền thưởng/ cho",
  "Browser": "Trình duyệt",
  "Building App": "Đang build app",
  "By User": "Bởi tài khoản",
  "By device": "By",
  "CANCELED RESULT": "Hủy kết quả",
  "Cancel": "Huỷ",
  "Cancel Period success": "Kết quả đang được hủy. Vui lòng đợi ...",
  "Cancel Request": "Huỷ yêu cầu",
  "Cancel bet Success!": "Bet đã bị hủy",
  "Cancel bet member message": "Hủy tin nhắn thành viên đặt cược",
  "Cancel transfer": "Hủy chuyển",
  "CancelTransferBetSuccess": "Cancel transfer bet success!",
  "CasinoDesktopBannersHelpText": "Khuyến nghị kích thước ảnh 1232 x 472 và chất lượng HD. Nặng tối đa 5MB",
  "CasinoMobileBannersHelpText": "Khuyến nghị kích thước ảnh 640 x 383 và chất lượng HD. Nặng tối đa 5MB",
  "Change": "Thay đổi",
  "Change Password": "Đổi mật khẩu",
  "Change Status": "Đổi trạng thái",
  "Change password": "Đổi mật khẩu",
  "ChangeUplineBankAccountSuccess": "Cập nhật thành công tài khoản ngân hàng tuyến trên.",
  "Clear": "Xóa",
  "Close": "Đóng",
  "Close Time": "Đóng",
  "Code": "Vietnamese",
  "Color": "Màu",
  "Commission": "Hoa hồng",
  "Commission Is Invalid": "Hoa hồng của {{game}} không hợp lệ",
  "Commission Notice": "Tỉ lệ hoa hồng sẽ được áp dụng từ phiên đoạn tiếp theo {{time}}",
  "CommissionHintArg": "Từ {{min}}% tới {{max}}%",
  "Company": "Công ty",
  "Company Commission": "Hoa hồng cho công ty",
  "Company Name": "Tên công ty",
  "Company or Whitelabel": "Company / Whitelabel",
  "CompanyNameTagDesc": "Nó là tên thương hiệu trang web bạn. Tên trong hồ sơ.",
  "Compose": "Soạn thư",
  "Confirm": "Xác nhận",
  "Confirm Password": "Nhập lại mật khẩu",
  "Confirm action message": "Bạn có chắc chắn muốn {{action}} tài khoản này",
  "ConfirmApproveRequest": "Bạn có chắc muốn phê duyệt tài khoản ngân hàng tuyến trên này?",
  "ConfirmCancelRequest": "Bạn có chắc muốn huỷ yêu cầu hoặc giao dịch?",
  "ConfirmCancelTransferBet": "Bạn có chắc chắn muốn hủy chuyển khoản đặt cược này không?",
  "ConfirmRejectRequest": "Bạn có chắc muốn từ chối tài khoản ngân hàng tuyến trên này?",
  "ConfirmTransferBet": "Bạn có chắc chắn muốn chuyển tiền cược này không?",
  "Contact Us": "Liên hệ",
  "Content": "Nội dung",
  "ContentTagNote": "Bạn có thể sử dụng những thẻ này để gắn vào nội dung dưới đây. Khi hiển thị lên trang web, nó sẽ là những từ hoặc giá trị thực sự mà bạn đang thiết lập.",
  "ContentTagsTitle": "Thẻ",
  "Copied to clipboard": "Đã lưu vào bộ nhớ tạm.",
  "Country": "Quốc gia",
  "Create": "Tạo",
  "Create Bank": "Tạo ngân hàng",
  "Create Bank Account": "Tạo tài khoản ngân hàng",
  "Create Bank Account Successfully!": "Tạo tài khoản ngân hàng thành công!",
  "Create Bank Group": "Tạo nhóm ngân hàng",
  "Create Bank Group success": "Tạo nhóm ngân hàng thành công",
  "Create Company": "Tạo công ty",
  "Create Manual DW Success": "Nạp tiền / rút tiền thành công",
  "Create Member": "Tạo thành viên",
  "Create Member Account Success": "Member đã được tạo thành công",
  "Create Other Payment Methods": "Thêm phương thức thanh toán khác",
  "Create Pool Success": "Tạo pool thành công!",
  "Create Pools": "Tạo Đài",
  "Create Sub Account Success": "Tài khoản phụ đã được tạo thành công",
  "Create Whitelabel": "Tạo Whitelabel",
  "Create promotion": "Thêm chương trình khuyến mãi",
  "Create promotion success": "Tạo thành công chương trình khuyến mãi.",
  "CreateArg": "Tạo {{arg}}",
  "CreateMemberEmailHint": "Nhập email. Bắt buộc",
  "Created Date": "Ngày tạo",
  "Credit": "Tiền cộng",
  "Customization": "Tùy biến",
  "CutNotHavePercent": "Cắt giảm",
  "DESC": "Z -\u003e A (VN)",
  "DISCOUNT": "Discount (%)",
  "DISCOUNT_2D": "Discount 2D (%)",
  "DISCOUNT_2D_DEPAN": "Discount 2D Depan (%)",
  "DISCOUNT_2D_TENGAH": "Discount 2D Tengah (%)",
  "DISCOUNT_3D": "Discount 3D (%)",
  "DISCOUNT_4D": "Discount 4D (%)",
  "Daily Free Bet": "Free Bet Hàng Ngày",
  "Date": "Ngày",
  "Date of Week": "Ngày trong tuần",
  "Deactive": "Vô hiệu hoá",
  "Deactive pool success": "Vô hiệu hoá pool thành công!",
  "Debit": "Tiền trừ",
  "Deduct": "Khấu trừ",
  "Default": "Mặc định",
  "Delete": "Xoá",
  "Delete Bank Group success": "Xoá nhóm ngân hàng thành công.",
  "Delete Company Bank Account success!": "Xoá tài khoản ngân hành thành công!",
  "Delete mail mail success": "Xóa mail thành công",
  "Delete mail success": "Xóa mail thành công",
  "Deposit": "Đặt cọc",
  "Deposit Max": "Đặt cọc tối đa",
  "Deposit Max required": "Yêu cầu nhập số tiền đặt cọc tối đa",
  "Deposit Min": "Đặt cọc tối thiểu",
  "Deposit Min required": "Yêu cầu nhập số tiền đặt cọc tối thiểu",
  "Deposit Request List": "Tiền đang chờ xử lý",
  "Deposit Time End": "Lần nạp tiền cuối",
  "Deposit amount of new member must be from": "Số tiền nạp của member mới phải từ",
  "Deposit/Withdraw": "Nạp / Rút tiền",
  "Deposit/Withdrawal Form": "Gửi / Rút tiền",
  "DepositTimeEndFormText": "Lần nạp tiền cuối mà member có thể nhận bonus",
  "DepositWithdrawTooltip": "Nạp / Rút tiền",
  "Description": "Mô tả",
  "Description required": "Yêu cầu nhập mô tả",
  "DescriptionHintMaxLength": "Nhập mô tả, tối đa {{maxLength}} ký tự.",
  "Desktop Banners": "Desktop Banner",
  "Destination Bank": "Ngân hàng đích",
  "Detail Type": "Loại chi tiết",
  "Details": "Chi tiết",
  "Device": "Thiết bị",
  "Device Report": "Báo cáo thiết bị người dùng sử dụng",
  "Different Banks": "Khác ngân hàng",
  "Discount": "Discount (%)",
  "Discount required": "Yêu cầu nhập số discount",
  "DiscountNotHavePercent": "Cắt giảm",
  "Display Bank List": "D/S Ngân Hàng Phô Trương",
  "Downline": "Tuyến dưới",
  "Downlines": "Tuyến dưới",
  "Download": "Tải xuống",
  "Download CSV": "Tải xuống CSV",
  "Download Excel": "Tải xuống Excel",
  "DragdropFileNote": "Chỉ file định dạng *.{{fileType}} được chấp nhận",
  "DragdropPlaceholder": "Kéo thả để chọn file",
  "DragdropxmlFileNote": "Chỉ file định dạng *.xml được chấp nhận",
  "Draw Day": "Kỳ xổ",
  "Edit": "Sửa",
  "Edit Credit": "Chỉnh sửa tín dụng",
  "Edit Member": "Chỉnh sửa thành viên",
  "Edit Pool": "Sửa Đài",
  "Edit promotion": "Sửa chương trình khuyến mãi",
  "EditArg": "Chỉnh sửa {{arg}}",
  "Ending Balance": "Số dư cuối kỳ",
  "Enter Account Name": "Nhập tên tài khoản",
  "Enter Account number": "Nhập số tài khoản",
  "Enter Amount": "Nhập tiền thưởng",
  "Enter Bank Account Name": "Nhập tên tài khoản ngân hàng",
  "Enter Bank Account Number": "Nhập số tài khoản ngân hàng",
  "Enter Deposit Time End": "Nhập Deposit Time End",
  "Enter Email": "Nhập email",
  "Enter Event Name": "Nhập Tên Sự Kiện",
  "Enter Max Amount": "Nhập tiền thưởng tối đa",
  "Enter Min Deposit Amount": "Nhập Tiền nạp tối thiểu",
  "Enter Phone Number": "Nhập số điện thoại",
  "Enter company name": "Nhập tên công ty",
  "Enter confirm password": "Nhập xác nhận mật khẩu",
  "Enter current password": "Nhập mật khẩu hiện tại",
  "Enter favicon": "Nhập favicon",
  "Enter logo": "Nhập logo",
  "Enter name": "Nhập tên",
  "Enter password": "Nhập mật khẩu",
  "Enter username": "Nhập username",
  "Enter whitelabel name": "Nhập tên whitelabel",
  "EnterField": "Nhập {{field}}",
  "EnterReason": "Nhập lý do",
  "Error!": "Lỗi",
  "Event": "Sự kiện",
  "Event Name": "Tên sự kiện",
  "Event Rule": "Luật thưởng:",
  "Event Rules": "Event Rules",
  "Event Type": "Loại sự kiện",
  "Event name": "Tên sự kiện",
  "EventNameHint": "Bắt buộc, tối đa 50 ký tự",
  "Everyday": "Mỗi ngày",
  "FAQ": "Câu hỏi",
  "Failed Attempts Since Last Login": "Cố gắng thất bại từ lần đăng nhập cuối cùng: {{times}} lần",
  "Failed to fetch": "Tải thất bại. Vui lòng thử lại",
  "Fake member": "Fake member",
  "Favicon": "Favicon Tiếng Việt",
  "Field is required": "Yêu cầu nhập trường bắt buộc",
  "FieldBlankError": "Ô nhập không được để trống",
  "File": "tập tin",
  "File is larger than 2000000 bytes": "Tập tin lớn hơn 2MB",
  "File is larger than 5000000 bytes": "Tập tin lớn hơn 5MB",
  "File type must be image/*": "Vui lòng chỉ tải tệp hình ảnh",
  "Files": "Tập tin",
  "Fill if percent is selected": "Vui lòng nhập nếu chọn %",
  "Fishing": "Bắn cá",
  "Fishing Game": "Bắn cá",
  "Fishing Spadegaming": "Spadegaming",
  "Footer": "Footer",
  "Free Bet": "Free Bet vn",
  "Free Bet Report": "Free Bet Report vn",
  "Friday": "T6",
  "Friendly Game Instance ID": "ID phiên bản trò chơi",
  "From": "Từ",
  "From Bank": "Từ ngân hàng",
  "Game": "Trò chơi",
  "Game Bet/Lose": "Cược/ Thua",
  "Game Code": "Mã trò chơi",
  "Game Name": "Tên game",
  "Game Refund": "Hoàn tiền",
  "Game Settings": "Sửa game setting",
  "Game Win": "Thắng",
  "GameBetLoseAmount": "Cược/Thua: ({{amount}})",
  "GameDepositAmount": "Đặt cược: ({{amount}})",
  "GameID": "Mã ID trò chơi",
  "GameJackpotWinAmount": "Win JP: ({{amount}})",
  "GameRefundAmount": "Hoàn tiền: ({{amount}})",
  "GameWinAmount": "Thắng: ({{amount}})",
  "GameWithdrawAmount": "Rút tiền: ({{amount}})",
  "GameWithdrawAmountAuto": "Rút tiền (Tự động): ({{amount}})",
  "Games": "Trò chơi",
  "Grand Total": "Tổng cộng",
  "Group": "Nhóm",
  "Group Bet Options": "Nhóm các lựa chọn đặt cược",
  "Group Name": "Tên nhóm",
  "GroupArg": "Nhóm {{arg}}",
  "Guess": "Đoán",
  "Habanero Referral": "Hoa hồng Habanero",
  "Habanero Transaction": "Giao dịch Habanero",
  "Holiday": "Nghỉ",
  "HomeDesktopBannersHelpText": "Khuyến nghị kích thước ảnh 1167 x 520 và chất lượng HD. Nặng tối đa 5MB",
  "HomeMobileBannersHelpText": "Khuyến nghị kích thước ảnh 640 x 412 và chất lượng HD. Nặng tối đa 5MB",
  "HomeOtherBannersHelpText": "2 hình ảnh với kích thước 872 x 147 và 294 x 364",
  "HomePopupBannersHelpText": "1 hình ảnh với chất lượng HD. Nặng tối đa 5MB",
  "How To Play": "Hướng dẫn",
  "ION Referral": "Hoa hồng ION",
  "ION Transaction": "Giao dịch ION",
  "IOS Link": "Đường dẫn tải iOS",
  "IOS Message": "Tin Nhắn",
  "IOS Name": "Tên iOS",
  "IOS Status": "Trạng thái iOS",
  "IP Address": "Địa chỉ IP",
  "ImageRequiredMaxSizeArg": "Hình với đuôi jpeg, jpg, png, gif, bmp. Nặng tối đa {{size}}. Bắt buộc.",
  "In use": "Số công ty/WL đang sử dụng",
  "Inactivate": "Bất hoạt động",
  "Inactive": "Không hoạt động",
  "Inactive Companies": "Inactive Companies",
  "Inactive Whitelabels": "Inactive Whitelabels",
  "Inbox": "Hộp thư đến",
  "Incorrect Captcha": "Mã Captcha không hợp lệ",
  "Information": "Thông tin",
  "Inject File": "Tải file lên",
  "Input 4 digits": "Nhập 4 con số",
  "Invalid min length": "Độ dài không đủ.",
  "Invalid number of digits!": "Số tài khoản ngân hàng không hợp lệ! Chữ số tài khoản của ngân hàng này là:",
  "Joined": "Tham gia",
  "Joined Date": "Ngày tham gia",
  "Joker Referral": "Hoa hồng Joker",
  "Joker Transaction": "Giao dịch Joker",
  "KEI_AS_BESAR": "Kei As Besar (%)",
  "KEI_AS_GANJIL": "Kei As Ganjil (%)",
  "KEI_AS_GENAP": "Kei As Genap (%)",
  "KEI_AS_KECIL": "Kei As Kecil (%)",
  "KEI_BELAKANG_HOMO": "Kei Homo Belakang (%)",
  "KEI_BELAKANG_KEMBANG": "Kei Kembang Belakang (%)",
  "KEI_BELAKANG_KEMBAR": "Kei Kembar Belakang (%)",
  "KEI_BELAKANG_KEMPIS": "Kei Kempis Belakang (%)",
  "KEI_BELAKANG_SILANG": "Kei Silang Belakang (%)",
  "KEI_BESAR": "Kei Besar (%)",
  "KEI_DEPAN_HOMO": "Kei Homo Depan (%)",
  "KEI_DEPAN_KEMBANG": "Kei Kembang Depan (%)",
  "KEI_DEPAN_KEMBAR": "Kei Kembar Depan (%)",
  "KEI_DEPAN_KEMPIS": "Kei Kempis Depan (%)",
  "KEI_DEPAN_SILANG": "Kei Silang Depan (%)",
  "KEI_EKOR_BESAR": "Kei Ekor Besar (%)",
  "KEI_EKOR_GANJIL": "Kei Ekor Ganjil (%)",
  "KEI_EKOR_GENAP": "Kei Ekor Genap (%)",
  "KEI_EKOR_KECIL": "Kei Ekor Kecil (%)",
  "KEI_GANJIL": "Kei Ganjil (%)",
  "KEI_GENAP": "Kei Genap (%)",
  "KEI_KECIL": "Kei Kecil (%)",
  "KEI_KEPALA_BESAR": "Kei Kepala Besar (%)",
  "KEI_KEPALA_GANJIL": "Kei Kepala Ganjil (%)",
  "KEI_KEPALA_GENAP": "Kei Kepala Genap (%)",
  "KEI_KEPALA_KECIL": "Kei Kepala Kecil (%)",
  "KEI_KOP_BESAR": "Kei Kop Besar (%)",
  "KEI_KOP_GANJIL": "Kei Kop Ganjil (%)",
  "KEI_KOP_GENAP": "Kei Kop Genap (%)",
  "KEI_KOP_KECIL": "Kei Kop Kecil (%)",
  "KEI_TENGAH": "Kei Tengah (%)",
  "KEI_TENGAH_HOMO": "Kei Homo Tengah (%)",
  "KEI_TENGAH_KEMBANG": "Kei Kembang Tengah (%)",
  "KEI_TENGAH_KEMBAR": "Kei Kembar Tengah (%)",
  "KEI_TENGAH_KEMPIS": "Kei Kempis Tengah (%)",
  "KEI_TENGAH_SILANG": "Kei Silang Tengah (%)",
  "KEI_TEPI": "Kei Tepi (%)",
  "Kei": "Kei (%)",
  "Key Name": "Tên từ khóa",
  "Keywords": "Từ Khóa",
  "LIMIT BUANG": "Limit Buang",
  "LIMIT TOTAL": "Limit Total",
  "LIMIT_BUANG_2D": "Limit Buang 2D",
  "LIMIT_BUANG_2D_DEPAN": "Limit Buang 2D Depan",
  "LIMIT_BUANG_2D_TENGAH": "Limit Buang 2D Tengah",
  "LIMIT_BUANG_3D": "Limit Buang 3D",
  "LIMIT_BUANG_4D": "Limit Buang 4D",
  "LIMIT_TOTAL_2D": "Limit Total 2D",
  "LIMIT_TOTAL_2D_DEPAN": "Limit Total 2D Depan",
  "LIMIT_TOTAL_2D_TENGAH": "Limit Total 2D Tengah",
  "LIMIT_TOTAL_3D": "Limit Total 3D",
  "LIMIT_TOTAL_4D": "Limit Total 4D",
  "Language": "Ngôn ngữ",
  "Last 30 Days": "Ngày ngày trừ 30",
  "Last 7 Days": "Ngày này mà tuần trước",
  "Last Activity": "Truy cập cuối",
  "Last Deposit Approved": "Lần gửi tiền cuối cùng được phê duyệt",
  "Last Failed Login From": "Đăng nhập cuối cùng thất bại từ {{ip}}",
  "Last IP": "Địa chỉ IP cuối cùng",
  "Last Login From": "Đăng nhập cuối cùng từ {{ip}}",
  "Last Month": "Tháng trước tháng này",
  "Last Update": "Last Update",
  "Last Withdrawal Approved": "Lần cuối cùng rút tiền được phê duyệt",
  "LengthMinMaxHintText": "Độ dài {{minLength}} đến {{maxLength}} (chỉ cho phép các chữ số hoặc in thường)",
  "LengthNumberOnly": "{{length}} ký tự. Chỉ Nhập Số.",
  "Level": "Cấp:",
  "Limit Buang": "Limit Buang",
  "Limit Buang required": "Yêu cầu nhập số limit buang.",
  "Limit Line": "Giới hạn đặt cược",
  "Limit Line 2D": "Giới hạn 2D",
  "Limit Line 2D Depan": "Giới hạn 2D Depan",
  "Limit Line 2D Tengah": "Giới hạn 2D Tengah",
  "Limit Line 3D": "Giới hạn 3D",
  "Limit Line 4D": "Giới hạn 4D",
  "Limit Lines": "Sửa giới hạn đặt cược",
  "Limit Lines Settings": "Sửa giới hạn đặt cược",
  "Limit Number Digits": "Giới hạn độ dài số",
  "Limit Setting": "Cài đặt giới hạn",
  "Limit Total": "Limit Total",
  "Limit Total required": "Yêu cầu nhập số limit total.",
  "Limit Transfer Bets": "Giới hạn chuyển nhượng",
  "Limit transfer": "Giới hạn mức chuyển khoản",
  "Link": "Liên Kết",
  "ListArg": "D/S {{arg}}",
  "Lock": "Khoá",
  "Locked": "Bị Khoá",
  "Locked Companies": "Locked Companies",
  "Locked Members": "Locked Members",
  "Locked Whitelabels": "Locked Whitelabels",
  "Login": "Đăng nhập",
  "Logo": "Logo Tiếng Việt",
  "Logout": "Đăng xuất",
  "Lose/Win": "Thua/Thắng",
  "Lost Money Report": "Báo cáo tiền bị thua",
  "MAX BET": "Max Bet",
  "MAX_BET_2D": "Max Bet 2D",
  "MAX_BET_2D_DEPAN": "Max Bet 2D Depan",
  "MAX_BET_2D_TENGAH": "Max Bet 2D Tengah",
  "MAX_BET_3D": "Max Bet 3D",
  "MAX_BET_4D": "Max Bet 4D",
  "MIN BET": "Min Bet",
  "Manage By": "Người quản lý",
  "Master Company": "Công ty chính",
  "Max": "Tối đa",
  "Max bet less than min bet": "Số tiền đặt cược nhỏ nhất lớn hơn số tiền đặt cược lớn nhất.",
  "Max bet required": "Yêu cầu nhập số đặt cược lớn nhất.",
  "Max length characters": "Độ dài tối đa {{length}} ký tự.",
  "MaxFileSizeNote": "Kích cỡ file: tối đa {{size}} MB",
  "MaxLengthChars": "Tối đa {{length}} ký tự.",
  "MaxLengthOptionalArg": "Tối đa {{length}} ký tự. Không bắt buộc.",
  "MaxLengthRequiredArg": "Tối đa {{length}} ký tự. Bắt buộc.",
  "Maximum / Minimum Bet": "Bet Lớn / Nhỏ",
  "Maximum Bet": "Bet lớn nhất",
  "Member": "Người chơi",
  "Member Bank Account": "Tài Khoản Thanh Toán",
  "Member Count": "Số lượng Member",
  "Member List": "Danh sách thành viên",
  "Member Username": "Tên tài khoản",
  "Member Win Loss": "Player Thắng / Thua",
  "Memo Compose": "Soạn bản ghi nhớ",
  "Message": "Tin Nhắn",
  "Meta": "meta data",
  "MetaWebpageNote": "Cho SEO: Tiêu đề, Meta (từ khóa, mô tả, ...)",
  "Min": "Tối thiểu",
  "Min Deposit Amount": "Tiền Nạp Tối Thiểu",
  "Min bet required": "Yêu cầu nhập số đặt cược nhỏ nhất.",
  "MinDepositTagDesc": "nó là giá trị nạp tiền ít nhất mà bạn thiết lập.",
  "MinWithdrawTagDesc": "nó là giá trị rút tiền ít nhất mà bạn thiết lập.",
  "Minimum Bet": "Bet nhỏ nhất",
  "Mobile Banners": "Mobile Banner",
  "Monday": "T2",
  "Move to trash": "Chuyển mail vào hộp thư rác",
  "Move to trash mail success": "Chuyển mail vào hộp thư rác thành công.",
  "Name": "Tên",
  "New Password": "Mật khẩu mới",
  "No": "Thứ tự",
  "No Group": "Không chọn nhóm",
  "No available period": "Không có period",
  "NoteNewGameSettingAdmin": "note of game settings for admin account",
  "NoteNewGameSettingsCompany": "note of game settings for company account",
  "Number": "Số",
  "Number WL/Company using": "Số công ty/WL đang sử dụng",
  "NumberOnlyAndBiggerThanZero": "Số lớn hơn 0. Bắt buộc.",
  "NumberOnlyPositiveRequired": "Số lớn hơn 0. Bắt buộc.",
  "Old Password": "Mật khẩu hiện tại",
  "Opening Hours": "Giờ hoạt động",
  "Optional": "Không bắt buộc",
  "Other Payment Methods": "Phương thức thanh toán khác",
  "Other Payment Methods Account": "Tài khoản phương thức thanh toán khác",
  "OtherProvider": "Nhà cung cấp khác",
  "Others": "Khác",
  "OthersBannerTips": "2 hình ảnh với kích thước 872 x 147 và 294 x 364",
  "Package Name": "Tên package",
  "Page Not Found Desc": "Trang bạn đang tìm không tồn tại",
  "Page Not Found Title": "Lạc lối rồi bồ ơi",
  "Paid Amount": "Tiền trả",
  "Password": "Mật Khẩu",
  "PasswordHint": "Ít nhất 6 ký tự. Trong đó có ký tự viết hoa, viết thường và chữ số",
  "PasswordRules": "Mật khẩu phải chứa cả chữ và số.",
  "Pending Deposit Requests": "Yêu cầu tiền gửi đang chờ xử lý",
  "Pending Withdrawal Requests": "Yêu cầu rút tiền đang chờ xử lý",
  "Period": "Kỳ xổ",
  "Period Online / Offline": "Kỳ xổ Trực tuyến / Ngoại tuyến",
  "Permission": "Quyền Hạn",
  "Phone Number": "Số điện thoại",
  "PhoneNumberHint": "Chỉ chữ số, bắt buộc",
  "Player Win/Loss": "Người chơi Thắng / Thua",
  "Please enter 1 or more characters": "Vui lòng nhập 1 hoặc nhiều ký tự hơn",
  "Please select at least 1 pool": "Vui lòng chọn ít nhất 1 pool",
  "Please select at least 1 provider": "Vui lòng chọn ít nhất một nhà cung cấp",
  "Pool Code": "Mã Đài",
  "Pool Name": "Tên Đài",
  "Pools": "Đài",
  "Pools List": "DS Đài",
  "Pools not found": "Ko tìm thấy đài này",
  "Pools/Games": "Đài/ Game",
  "Popup": "Popup Banner VN",
  "Pragmatic Play": "Pragmatic",
  "PragmaticPlayLive Referral": "Hoa hồng Pragmatic Play Live",
  "PragmaticPlayLive Transaction": "Giao dịch Pragmatic Play Live",
  "Pragmatic Referral": "Hoa hồng Pragmatic",
  "Pragmatic Transaction": "Giao dịch Pragmatic",
  "Prize (winning multipler)": "Prize (winning multiplier)",
  "Processed At": "Xử lý lúc",
  "Profile": "Hồ sơ",
  "Profit": "Lợi nhuận",
  "Profit (max)": "Lợi nhuận (lớn nhất)",
  "Profit (min)": "Lợi nhuận (nhỏ nhất)",
  "Promotion list": "Danh sách chương trình khuyến mãi",
  "Provider": "Nhà cung cấp",
  "Rate": "Tỷ lệ",
  "Rate / Cut": "Tỷ lệ/Cắt giảm",
  "Rate/Discount": "",
  "Real member": "Real member",
  "Reason": "Lý do",
  "ReasonHint": "Bắt buộc. Tối đa 200 từ",
  "Receiver": "Người nhận",
  "Reduce": "Trừ",
  "Ref No": "Số tham chiếu",
  "Referral": "Tiền hoa hồng",
  "Referral Bonus (Total)": "Tiền hoa hồng (Tổng cộng)",
  "Referral Code": "Mã giới thiệu",
  "Referral Downline list": "Danh sách giới thiệu tuyến dưới",
  "Referral Game": "Hoa hồng game",
  "Referral List": "DS referral",
  "Referral Notice": "Tỉ lệ thưởng giới thiệu sẽ được áp dụng từ phiên đoạn tiếp theo {{time}}",
  "Referral Pragmatic": "Hoa hồng Pragmatic",
  "Referral Rate": "Tỉ giá giới thiệu",
  "Referral Report": "Hoa hồng",
  "Referrer": "Người giới thiệu",
  "Reject": "Từ chối",
  "Rejected Deposit Requests": "Yêu cầu gửi tiền bị từ chối",
  "Rejected Withdrawal Requests": "Yêu cầu rút tiền bị từ chối",
  "Reply": "Trả lời",
  "RequestApprovedSuccess": "Tài khoản ngân hàng tuyến trên đã được phê duyệt",
  "RequestCanceledSuccess": "Tài khoản ngân hàng truyến trên bị huỷ",
  "RequestRejectedSuccess": "Tài khoản ngân hàng tuyến trên đã bị từ chối",
  "Reset": "Khôi phục",
  "Result": "Kết quả",
  "Result Date": "Ngày",
  "Result List": "DS kết quả",
  "Result Time": "Mở",
  "Result must be 4 digits": "Độ dài không đủ.",
  "Result must be {{min}} lengths": "Kết quả phải có độ dài {{min}}",
  "ResultNumberAddSuccess": "Kết quả đã được tạo thành công!",
  "ResultNumberCancelSuccess": "Kết quả đã được hủy thành công!",
  "ResultNumberMin": "Kết quả phải có {{min}} chữ số",
  "Round ID": "ID của vòng",
  "Rules": "T\u0026C",
  "RunningTextAfterLoginNote": "Văn bản này sẽ được thay thế văn bản front page sau khi thành viên đăng nhập.",
  "RunningTextBeforeLoginNote": "Văn bản này sẽ được chạy tại vị trí thông báo trên trang web, trước khi thành viên đăng nhập.",
  "RunningTextNoteTag": "Bạn có thể sử dụng những tag này bên trong nội dung. Khi hiển thị trên website, sẽ hiển thị thành chữ thay thế.",
  "Same Banks": "Cùng ngân hàng",
  "Saturday": "T7",
  "Save": "Lưu",
  "Scan": "Quét",
  "Scan Detail": "Chi tiết quét",
  "Scan Result": "Quét",
  "ScriptWebpageNote": "Dành cho các đoạn mã Livechat, Google Analytic,...",
  "Scripts": "Các đoạn mã",
  "Search": "Tìm",
  "Search By": "Tìm kiếm theo",
  "Search by": "Tìm kiếm theo",
  "Select": "Chọn",
  "Select Action": "Chọn hành động",
  "Select All": "Chọn tất cả",
  "Select Date": "Chọn ngày",
  "Select Providers": "Chọn nhà cung cấp",
  "Select action": "Chọn hành động",
  "Select bank": "Chọn ngân hàng",
  "Select period": "Chọn kỳ xổ",
  "Select pool": "Chọn pool",
  "Select sort": "Chọn kiểu sắp xếp",
  "Send Date": "Ngày gửi",
  "Send mail success": "Thư gửi thành công",
  "Sender": "Người gửi",
  "Sent": "Đã gửi",
  "Set Referral Rate": "Tùy chỉnh tiền giới thiệu",
  "Settle Time": "Thời gian",
  "ShioTableTagDesc": "Shio Table năm hiện tại",
  "Show Reason": "Xem lý do",
  "Sign In to your account": "Đăng nhập vào tài khoản của bạn",
  "Site": "Trang web",
  "SiteConfigAppLogoNote": "Hình ảnh ứng dụng tự động được thay thế khi tải lên mới. Kích thước tải lên tối đa là 2MB, 512x512 và là .png",
  "SiteConfigFavIconNote": "Hình ảnh favicon tự động được thay thế khi tải lên mới.",
  "SiteConfigLogoNote": "Hình ảnh logo sẽ tự động được thay thế khi tải lên mới.",
  "Sitemap": "Sơ đồ trang web",
  "SitemapUploadedTime": "sitemap.xml được tải lên vào lúc {{date}}",
  "Slot Spadegaming": "Spadegaming",
  "SlotDesktopBannersHelpText": "Khuyến nghị kích thước ảnh 657 x 440 và chất lượng HD. Nặng tối đa 5MB",
  "SlotMobileBannersHelpText": "Khuyến nghị kích thước ảnh 640 x 339 và chất lượng HD. Nặng tối đa 5MB",
  "Social Links": "Liên kết mạng xã hội",
  "Sort": "Xếp",
  "Sort Order": "Thứ tự xếp",
  "Spade Referral": "Hoa hồng Spadegaming",
  "Spade Transaction": "Giao dịch Spadegaming",
  "Status": "Trạng thái",
  "Subject": "Tiêu đề",
  "Submit": "Tạo",
  "Submit Edit": "Lưu chỉnh sửa",
  "Success": "Thành công",
  "Sunday": "CN",
  "Suspend": "Đình Chỉ",
  "Suspended": "Bị Đình Chỉ",
  "Suspended Companies": "Suspended Companies",
  "Suspended Members": "Suspended Members",
  "Suspended Whitelabels": "Suspended Whitelabels",
  "System": "Hệ thống",
  "System Profit": "Lợi nhuận hệ thống",
  "Target": "Mục tiêu",
  "Template": "Bản mẫu",
  "Templates List": "Danh sách giao diện mẫu",
  "This Month": "Tháng này",
  "Thumbnail": "Hình nhỏ",
  "Thursday": "T5",
  "Ticket ID": "ID vé",
  "Time": "Thời gian",
  "TimezoneProviderDailyReport": "Múi giờ {{timezone}}",
  "Title": "Tiêu đề",
  "Title required": "Cần có tiêu đề.",
  "To": "Đến",
  "Today": "Hôm nay",
  "Togel Referral": "Hoa hồng Togel",
  "Togel Transaction": "Giao dịch Togel",
  "TogelDesktopBannersHelpText": "Khuyến nghị kích thước ảnh 1232 x 424 và chất lượng HD. Nặng tối đa 5MB",
  "TogelMobileBannersHelpText": "Khuyến nghị kích thước ảnh 800 x 348 và chất lượng HD. Nặng tối đa 5MB",
  "Too many files": "Lỗi nhiều tập tin được chọn",
  "Total": "Tổng",
  "Total Bet": "Tổng tiền cược",
  "Total Bet Transactions": "Tổng lượt bet",
  "Total Bet Winners": "Tổng số người thắng cược",
  "Total Bill": "Hóa đơn tổng cộng",
  "Total Companies": "Total Companies",
  "Total Credit": "Total Credit",
  "Total Deposit": "Tổng nạp",
  "Total Global Members": "Total Global Members",
  "Total Global Online Members": "Total Global Online Members",
  "Total Lose": "Tổng thua",
  "Total Lost Bet": "Tổng số lần cược bị thua",
  "Total Lost Money": "Tổng số tiền bị mất",
  "Total Members": "Tổng số thành viên",
  "Total Number": "Tổng số cược",
  "Total Online Members": "Total Online Members",
  "Total Online Members List": "Total Online Members List",
  "Total Online Members by Downline": "Total Online Members by Downline",
  "Total Online Whitelabels": "Total Online Whitelabels",
  "Total Online Whitelabels List": "Total Online Whitelabels List",
  "Total Outstanding": "Total Outstanding",
  "Total Paid Amount": "Tổng số tiền đã trả",
  "Total Paid Bet": "Tổng tiền đặt cược",
  "Total Player Win Loss": "Tổng số người chơi thắng thua",
  "Total Profit": "Tổng lợi nhuận",
  "Total User Bet": "Tổng số người dùng đặt cược",
  "Total Users": "Tổng số người dùng",
  "Total Whitelabels": "Total Whitelabels",
  "Total Win": "Tổng thắng",
  "Total Winner": "Tổng số bet thắng",
  "Total Winning Amount": "Tổng số tiền thắng",
  "Total Withdraw": "Tổng rút",
  "Transaction Date": "Ngày Giao dịch",
  "Transaction Detail": "Chi tiết giao dịch",
  "Transaction Details not found": "Không tìm thấy chi tiết giao dịch.",
  "Transaction History": "Lịch sử giao dịch",
  "Transaction ID": "ID giao dịch",
  "Transaction Type": "Loại giao dịch",
  "Transfer": "Chuyển",
  "Transfer Bets": "Chuyển Nhượng Bets",
  "Transfer Detail Logs": "Chi tiết chuyển khoản",
  "Transfer From Bank": "Chuyển từ ngân hàng",
  "TransferBetSuccess": "Bet đã được chuyển",
  "Trash": "Hộp thư rác",
  "Tuesday": "T3",
  "Turnover": "Doanh số",
  "Type": "Loại",
  "Unban": "Bỏ cấm",
  "Unlock": "Mở khoá",
  "Unsuccess": "Không thành công",
  "Unsuspend": "Gỡ đình chỉ",
  "Update": "Cập nhật",
  "Update App List success": "Cập nhật DS ứng dụng thành công",
  "Update App Logo success": "Cập nhật Logo ứng dụng thành công.",
  "Update Company Bank Account success!": "Cập nhật thông tin tài khoản ngân hàng thành công",
  "Update Limit transfer bets success": "Cập nhật giới hạn transfer thành công.",
  "Update Pool Success": "Cập nhật trạng thái thành công",
  "Update Success": "Đã lưu chỉnh sửa",
  "Update auto memo success": "Cập nhật thành công ghi nhớ tự động.",
  "Update bank account balance success!": "Cập nhật số dư tài khoản ngân hàng thành công!",
  "Update customer service success": "Cập nhật thành công dịch vụ khách hàng",
  "Update footer text success": "Cập nhật footer text thành công",
  "Update game setting success!": "Cập nhật cài đặt game thành công!",
  "Update group Company Bank Account success!": "Cập nhật nhóm cho tài khoản ngân hàng thành công",
  "Update information success": "Cập nhật thông tin thành công",
  "Update limit deposit withdraw success!": "Cập nhật deposit withdraw thành công.",
  "Update member credit success!": "Cập nhật tín dụng thành viên thành công!",
  "Update member status success!": "Cập nhật trạng thái thành viên thành công!",
  "Update metadata success": "Cập nhật metadata thành công",
  "Update promotion success": "Cập nhật chương trình khuyến mãi thành công",
  "Update running text success": "Cập nhật running text thành công",
  "Update script success": "Cập nhật script thành công",
  "Update scripts success": "Cập nhật scripts thành công",
  "Update status Company Bank Account success!": "Cập nhật trạng thái tài khoản ngân hàng thành công",
  "Update status success": "Cập nhật trạng thái thành công.",
  "Update upline bank account success": "Cập nhật thành công tài khoản ngân hàng tuyến trên.",
  "UpdateAppListNoteMsg": "Member sẽ nhận được tin nhắn này khi cố gắng tải / cài đặt ứng dụng của chúng ta mà đã bị vô hiệu.",
  "UpdateBannersSuccess": "Cập nhật banners thành công.",
  "UpdateDataSuccess": "Cập nhật {{dataUpdate}} thành công.",
  "UpdateFromSystemMessage": "There is an update from the system. Please refresh the page and try again.",
  "Updated Date": "Ngày cập nhật",
  "Upline bank account approved": "Tài khoản ngân hàng tuyến trên đã được phê duyệt",
  "Upline bank account canceled": "Tài khoản ngân hàng truyến trên bị huỷ",
  "Upline bank account rejected": "Tài khoản ngân hàng tuyến trên đã bị từ chối",
  "Uplines": "Tuyến trên",
  "Upload Bonus": "Tải file bonus",
  "Upload app info successfully": "Thông tin ứng dụng đã cập nhật",
  "Upload favicon successfully": "favicon tải lên thành công",
  "Upload logo successfully": "logo tải lên thành công",
  "Upload sitemap success": "Tải sitemap lên thành công.",
  "UploadBonusFileHint": "Cho phép tải lên tập tin excel. Chỉ chấp nhận file có đuôi .xlsx. Bắt buộc",
  "User Name": "Tài khoản",
  "User Referral": "Người dùng giới thiệu",
  "Username": "Tên đăng nhập",
  "Username or name": "Tên hoặc tên đăng nhập",
  "UsernameHint": "Từ 2-6 ký tự. Chữ thường và số.",
  "Value %": "Phần trăm",
  "Value Percent": "Phần trăm",
  "View Images": "Xem ảnh",
  "WIN": "Win (x)",
  "WIN_1_D": "Win 1 digit (x)",
  "WIN_2D": "Win 2D (x)",
  "WIN_2D_DEPAN": "Win 2D Depan (x)",
  "WIN_2D_TENGAH": "Win 2D Tengah (x)",
  "WIN_2_D": "Win 2 digit (x)",
  "WIN_3D": "Win 3D (x)",
  "WIN_3_D": "Win 3 digit (x)",
  "WIN_4D": "Win 4D (x)",
  "WIN_4_D": "Win 4 digit (x)",
  "WIN_AS": "Win As (x)",
  "WIN_EKOR": "Win Ekor (x)",
  "WIN_KEPALA": "Win Kepala (x)",
  "WIN_KOP": "Win Kop (x)",
  "WL": "Whitelabel vn",
  "Webpage": "Trang web",
  "Website": "Web",
  "Website Status": "Website Status",
  "Wednesday": "T4",
  "Welcome member message": "Chào mừng thành viên mới",
  "WhiteIPHint": "Tối đa 20 IPs. Để trống để bỏ kiểm tra IP",
  "WhiteLabel": "WhiteLabel",
  "Whitelabel": "Whitelabel",
  "Whitelabel Commission": "Hoa hồng cho whitelabel",
  "Whitelabel Count": "Số lượng Whitelabel",
  "Whitelabel Name": "Tên Whitelabel",
  "Whitelist IP Address": "Danh sách IP trắng",
  "WhitelistIpHint": "Tối đa 20 IPs. Để trống để bỏ kiểm tra IP",
  "Win": "Thắng",
  "Win (Total)": "Thắng (tổng cộng)",
  "Win Loss": "Thắng thua",
  "Win Loss Report": "Thắng Thua",
  "Win digits required": "Yêu cầu nhập số giải thưởng",
  "Winners List": "DS người thắng",
  "Winning Amount": "Tiền thắng",
  "Withdraw": "Rút tiền",
  "Withdraw Max": "Số tiền rút tối đa",
  "Withdraw Max required": "Yêu cầu nhập số tiền rút tối đa",
  "Withdraw Min": "Số tiền rút tối thiểu",
  "Withdraw Min required": "Yêu cầu nhập số tiền rút tối thiểu",
  "Withdraw Request List": "DS yêu cầu rút tiền",
  "Withdrawal": "Rút tiền",
  "X Deposit": "X Nạp",
  "Yesterday": "Hôm nay trừ 1",
  "activate": "Kích hoạt",
  "active": "Hoạt động",
  "add": "cộng",
  "adjust": "điều chỉnh",
  "admin": "Admin",
  "all": "Tất cả",
  "already_in_that_status": "Trạng thái đã thay đổi rồi. Thử lại",
  "and": "và",
  "announcement": "Thông báo",
  "approve": "Duyệt",
  "approved": "Được duyệt",
  "archive": "lưu trữ",
  "archived": "Đã lưu trữ",
  "as": "As",
  "ban": "Cấm",
  "bank-management": "Quản lý ngân hàng",
  "bank_group_not_found": "Không tìm thấy nhóm ngân hàng",
  "banned": "Bị cấm",
  "bb_campuran": "BB Campuran",
  "belakang": "Belakang",
  "besar": "Số lớn",
  "bet": "Cược",
  "bolak_balik": "Bolak Balik",
  "bolak_balik_2d": "Bolak Balik 2D",
  "bolak_balik_4d": "Bolak Balik 4D",
  "bonus-payment": "Tiền thưởng",
  "bonus_deposit": "Thưởng Nạp Tiền",
  "bonus_new_member": "Thưởng Member Mới",
  "buffalo": "Trâu",
  "cancel": "hủy",
  "canceled": "Đã hủy",
  "canceled result": "HỦY KẾT QUẢ",
  "cancelled": "Hủy",
  "chicken": "Gà",
  "colok_bebas": "Colok Bebas",
  "colok_bebas_2d": "Colok Bebas 2D",
  "colok_jitu": "Colok Jitu",
  "colok_naga": "Colok Naga",
  "company": "Công ty",
  "confirm-active-bank": "Bạn có muốn bật Ngân hàng này lên?",
  "confirm-inactive-bank": "Bạn có muốn tắt Ngân hàng này đi?",
  "confirmActionPromotion": "Bạn có chắc là muốn {{action}} chương trình khuyến mãi này?",
  "create-edit-company": "Tạo và chỉnh sửa tài khoản Company",
  "create-edit-member": "Tạo và chỉnh sửa tài khoản Member",
  "create-edit-whitelabel": "Tạo và chỉnh sửa tài khoản Whitelabel",
  "customization": "Tuỳ chỉnh",
  "dasar": "Dasar",
  "date": "Ngày",
  "default": "Mặc định",
  "default group": "Nhóm mặc định",
  "delete": "xóa",
  "deletefault": "Mặc định",
  "depan": "Depan",
  "deposit": "Đặt cọc",
  "deposit_greater_max": "Tiền nạp quá lớn",
  "deposit_time_must_larger_than_previous": "số lần nạp tiền quá nhỏ",
  "deposit_time_must_less_than_next": "số lần nạp tiền quá lớn",
  "descTransHis_FreeBet": "Free Bet vn: {{event_name}} ({{bonus_amount}})",
  "descTransHis_IonBetCancelled": "Hủy bet: ({{amount}})",
  "descTransHis_IonBetDraw": "Hòa: ({{amount}})",
  "descTransHis_IonBetLose": "Thua: ({{amount}})",
  "descTransHis_IonBetRollback": "Thay đổi: ({{amount}})",
  "descTransHis_IonBetVoid": "Hoàn: ({{amount}})",
  "descTransHis_IonBetWon": "Thắng: ({{amount}})",
  "descTransHis_IonOrderDeduct": "Cược: ({{amount}})",
  "descTransHis_IonOrderRollback": "Hoàn tiền: ({{amount}})",
  "dog": "Chó",
  "dragon": "Rồng",
  "duplicate_bank_account_no": "Số tài khoản ngân hành bị trùng.",
  "duplicate_pool_code": "Mã đài đã tồn tại",
  "duplicate_pool_name": "Tên đài đã tồn tại",
  "e-money": "Ví điện tử",
  "ekor": "Ekor",
  "err:fake_online_number_valid": "Số lớn hơn hoặc = 0",
  "err_bet_tx_is_transferred": "Bet đã chuyển đi. Hiện tại không khả dụng.",
  "err_period_already_offline": "Ko thể cập nhật. Nhà đài đã đóng",
  "err_period_cancel_wrong_period": "Kỳ ko hợp lệ, thử lại",
  "err_period_has_closed": "Ko thể cập nhật. Nhà đài đã đóng",
  "err_period_update_before_close_time": "Chưa tới giờ đóng period",
  "err_period_update_wrong_period": "Kỳ ko hợp lệ, thử lại",
  "event_rules_description": "This bonus is for new members only and their 1st deposit time\nWhen new members deposit 1st time (approved request), the system will calculate bonus amount and give it to them (add to member balance) automatically.\nAnd if that approved deposit request is canceled, we will get the deposit amount and bonus back too.\nDon’t apply to manual deposit from BO.\nBonus amount = Amount (if company / WL set Rp amount)\nOr = deposit amount * Amount % (if company / WL set amount %)\nDeposit amount of new members must be = or \u003e Min Deposit Amount to get this bonus.",
  "fake_online_number_valid": "Số lớn hơn hoặc = 0",
  "form_validation_failed": "Mẫu gửi lên không hợp lệ.",
  "free_bet_amount_invalid": "Tiền thưởng không hợp lệ",
  "free_bet_bonus_deposit": "Bonus nạp tiền nhiều",
  "free_bet_bonus_new_description": "Bonus cho thành viên mới",
  "free_bet_max_amount_invalid": "Tiền thưởng tối đa không hợp lệ",
  "free_bet_min_deposit_amount_invalid": "Tiền Nạp tối thiểu không hợp lệ",
  "ganjil": "Số lẻ",
  "genap": "Số chẵn",
  "global-member-list": "DS tất cả member",
  "goat": "Dê",
  "homo": "Homo",
  "horse": "Ngựa",
  "in": "Tiền vào",
  "in - out": "Chênh lệch",
  "in(total)": "Tổng tiền vào",
  "inactivate": "Bất hoạt động",
  "inactive": "Không hoạt động",
  "invalid_draw_day": "Kỳ xổ ko hợp lệ",
  "invalid_limit_line_value": "Giới hạn ko hợp lệ",
  "invalid_value": "Giá trị không hợp lệ",
  "ion casino": "ION",
  "kecil": "Số bé",
  "kembang": "Kembang",
  "kembang_kempis": "Kembang - Kempis",
  "kembar": "Kembar",
  "kempis": "Kempis",
  "kepala": "Kepala",
  "kombinasi": "Kombinasi",
  "kop": "Kop",
  "limit_transfer_2d": "Hạn mức chuyển khoản 2D",
  "limit_transfer_2d_depan": "Hạn mức chuyển khoản 2D Depan",
  "limit_transfer_2d_tengah": "Hạn mức chuyển khoản 2D Tengah",
  "limit_transfer_3d": "Hạn mức chuyển khoản 3D",
  "limit_transfer_4d": "Hạn mức chuyển khoản 4D",
  "lock": "Khoá",
  "locked": "Bị Khoá",
  "logout-change-pwd": "Bạn đã bị đá vì bị đổi mật khẩu",
  "logout-inactive": "Bạn đã bị đá vì bị bất hoạt động",
  "logout-ip": "Bạn đã bị đá vì ip không cho phép",
  "logout-locked": "Bạn đã bị đá vì bị khoá",
  "lose": "Thua",
  "lost": "Thua",
  "manage-bank-account": "Truy cập tài khoản ngân hàng",
  "manage-request": "Truy câp danh sách yêu cầu nạp và rút tiền",
  "max_bet_less_than_min_bet": "Số tiền đặt cược nhỏ nhất lớn hơn số tiền đặt cược lớn nhất.",
  "memo": "Ghi nhớ",
  "monkey": "Khỉ",
  "no_permission": "Không có quyền truy cập",
  "no_permission_desc": "Trang bạn đang tìm tạm thời không thể truy cập",
  "online": "ONLINE",
  "out": "Tiền ra",
  "out(total)": "Tổng tiền ra",
  "ox": "Trâu",
  "pending": "Đang chờ xử lý",
  "pig": "Heo",
  "position": "vị trí",
  "promotion": "Khuyến mãi",
  "pulsa": "pulsa",
  "quick_2d": "Quick 2D",
  "quick_bet": "Quick Bet",
  "rabbit": "Thỏ",
  "rat": "Chuột",
  "reduce": "trừ",
  "rejected": "Từ chối",
  "required_captcha": "Yêu cầu nhập captcha",
  "result-list": "Danh sách kết quả",
  "result_time_should_be_later_close_time": "Giờ ra kết quả phải sau giờ đóng bet",
  "rooster": "Gà",
  "running": "Đang",
  "setting": "Truy cập cài đặt",
  "shio": "Shio",
  "silang": "Silang",
  "silang_homo": "Silang - Homo",
  "snake": "Rắn",
  "suspend": "Đình Chỉ",
  "suspended": "Bị Đình Chỉ",
  "tengah": "Tengah",
  "tepi": "Tepi",
  "tepi_tengah": "Tepi - Tengah",
  "tiger": "Hổ",
  "transferred": "Đã chuyển",
  "unarchived": "Kích hoạt",
  "unban": "Bỏ cấm",
  "unlock": "Bỏ Khoá",
  "unsuspend": "Bỏ Đình Chỉ",
  "update": "cập nhật",
  "view-dashboard": "Xem bảng điều khiển",
  "view-log": "Xem nhật ký",
  "view-report": "Xem báo cáo",
  "win": "Thắng",
  "withdraw": "Rút tiền",
  "withdraw_greater_max": "Tiền rút quá lớn",
  "Allow to access and manage the Master Account menu": "Cho phép truy cập và quản lý tài khoản Master ",
  "create-edit-master-wl": "Tạo và chỉnh sửa tài khoản Master",
  "Master Commission": "Hoa hồng cho master",
  "Edit Master Account": "Chỉnh sửa master ",
  "Master Whitelabel": "Master Whitelabel",
  "Whitelabel Username": "Whitelabel Username",
  "Master Profit": "Lợi nhuận SM",
  "Add Downline": "Thêm tuyến dưới",
  "Master Name": "Tên SM",
  "Enter master name": "Nhập tên master",
  "Master Commission Notice": "Tỉ lệ hoa hồng sẽ được áp dụng ngay lập tức",
  "Call interface too frequently": "Yêu cầu hết thời gian chờ",
  "Add Notification": "Tạo mới thông báo",
  "Add a new notification": "Tạo mới 1 thông báo",
  "confirmActionNotification": "Bạn có chắc bạn muốn {{action}} thông báo này?",
  "Update notification success": "Thông báo đã được cập nhật",
  "Update Notification": "Cập nhật thông báo",
  "Add notification success": "Thông báo được tạo thành công",
  "ImageRequired500MaxSizeArg": "Hình với đuôi jpeg, jpg, png, gif, bmp. Nặng tối đa {{size}}. Bắt buộc.",
  "descTransHis_roll_in": "Rút: ({{amount}})",
  "descTransHis_roll_out": "Cược: ({{amount}})",
  "descTransHis_debit": "Trừ: ({{amount}})",
  "descTransHis_credit": "Cộng: ({{amount}})",
  "descTransHis_bonus": "Tặng: ({{amount}})",
  "ION": "ION",
  "Habanero": "Habanero",
  "Spade": "Spadegaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "Joker": "JokerGaming",
  "CQ9": "CQ9",
  "CQ9 Transaction": "Giao dịch CQ9",
  "CQ9 Referral": "Tiền hoa hồng CQ9",
  "Targets required": "Mục tiêu bắt buộc",
  "New Users": "Player Mới",
  "Copy report to clipboard": "Sao chép",
  "Deposit Count": "Lượt Nạp Tiền",
  "Withdraw Count": "Lượt Rút Tiền",
  "Report for": "Báo cáo",
  "DescritionEnableOrDisableGame": "Chọn để cho phép member chơi game",
  "Same Payment": "Same Payment",
  "Payment account not found": "Không có tài khoản thanh toán",
  "Microgaming": "Microgaming",
  "Microgaming Transaction": "Giao dịch Microgaming",
  "Microgaming Referral": "Tiền hoa hồng Microgaming",
  "Bonus Turnover": "Thưởng Turnover",
  "free_bet_bonus_turnover": "Loại thưởng này dành cho tất cả người chơi khi turnover của họ đáp ứng được các điều kiện.\nHàng tuần, hệ thống sẽ tính toán tiền thường và cộng vào tài khoản của người chơi theo thời gian đã được thiết lập.\n\nTiền thưởng = Turnover mỗi tuần theo game provider * tỷ lệ %",
  "daily": "daily",
  "weekly": "weekly",
  "monthly": "monthly",
  "Turnover amount of new member must be from": "Turnover amount of new member must be from",
  "desBonusCashback": "Bonus Cashback Description",
  "Min Amount": "Min Amount vn",
  "Min Turnover": "Min Turnover vn",
  "Max Bonus": "Max Bonus vn",
  "Every Monday": "T2",
  "Every Tuesday": "T3",
  "Every Wednesday": "T4",
  "Every Thursday": "T5",
  "Every Friday": "T6",
  "Every Saturday": "T7",
  "Every Sunday": "CN",
  "CONDITION TO GET BONUS": "ĐK nhận thưởng",
  "CONDITION TO DO WD": "ĐK rút tiền",
  "Bonus Cashback": "Hoàn Tiền",
  "By Turnover": "Theo TO",
  "By WinLose": "Theo Thắng Thua",
  "Base by Turnover (* Deposit)": "Tính theo TO (x số tiền nạp)",
  "Base by Turnover (* (Deposit + Bonus))": "Tính theo TO (x (số tiền nạp + thưởng))",
  "Manual Freebet": "Loại Freebet",
  "Include E-Money": "Include E-Money",
  "Manual Withdrawal": "Rút Tiền Thủ Công",
  "Manual Deposit": "Nạp Tiền Thủ Công",
  "Pgsoft": "PG",
  "PG Soft Transaction": "Giao dịch PG",
  "PG Soft Referral": "Tiền hoa hồng PG",
  "GameTransferInAmount": "Tiền vào: ({{amount}})",
  "GameTransferOutAmount": "Tiền ra: ({{amount}})",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "PGSoft Transaction": "Giao dịch PG",
  "PGSoft Referral": "Tiền hoa hồng PG",
  "NoteFreebetBonusGame": "Lưu ý: Những game này ko có TO: CQ9 Fishing games, Spadegaming Table Games và Fishing Games",
  "NoteCleaningData": "Chỉ giữ lại lịch sử giao dịch của 3 tháng gần nhất",
  "Period Start From": "Period Start From",
  "Fake first 10 result numbers": "Fake first 10 result numbers",
  "InputNumberdigits": "Input {{length}} digits",
  "PGSoft Referral": "Tiền hoa hồng PG",
  "GameChangedAmount": "Game Changed: ({{amount}})",
  "TTG": "Top Trend Gaming",
  "TTG Transaction": "Giao dịch TTG",
  "TTG Referral": "Hoa hồng TTG",
  "GameBonusAmount": "Tiền Thưởng: ({{amount}})",
  "GameBetAmount": "Cược: ({{amount}})",
  "GameWonAmount": "Thắng: ({{amount}})",
  "GameCancelledAmount": "Hủy bet: ({{amount}})",
  "GameChangedAmount": "Thay đổi: ({{amount}})",
  "GameDrawAmount": "Hòa: ({{amount}})",
  "GameLoseAmount": "Thua: ({{amount}})",
  "GameVoidAmount": "Hoàn: ({{amount}})"
}
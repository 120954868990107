import APIUtils from './APIUtils'

class TopBarAPI {
  static getTopBarMenu() {
    return APIUtils.POST('/api/top_bar_menu', {})
      .then(response => {
        return response.data
      })
  }
}

export default TopBarAPI;

import APIUtils from './APIUtils'

class ImageApi {
  static upload(data) {
    let form  = new FormData()
    form.append("image", data)
    return APIUtils.POSTFile('/upload/image', form).then(response => {
        return response.data
    })
  }
}

export default ImageApi;

import { DELETE_TOKEN, SET_TOKEN } from '../constants';

const initAuth = {
  token: '',
}

const Auth = (state = initAuth, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case DELETE_TOKEN:
      return {
        ...state,
        token: "",
      }
    default:
      return state
  }
}

export default Auth

import APIUtils from './APIUtils'

class AuthAPI {
  static authorize() {
    return APIUtils.POST('/api/auth', {})
    .then(response => {
      APIUtils.saveUserInfomation(JSON.stringify(response.data))
      return response.data
    }).catch((err) => {
      APIUtils.logOut()
      // window.location.reload()
    })
  }

  static getSideBar() {
    return APIUtils.POST('/api/navigation', {})
    .then(response => {
        let result = response.data
        return result
    })
  }

  static getPermissions() {
    return APIUtils.POST('/api/self_permission')
      .then(response => response.data)
  }
}

export default AuthAPI;

import { BACKEND_CLIENT_VERSION } from "../constants";

const initState = {
  backendClientVersion: "",
}

const status = (state = initState, action) => {
  switch (action.type) {
    case BACKEND_CLIENT_VERSION:
      return {
        ...state,
        backendClientVersion: action.payload
      }
    default:
      return state;
  }
}

export default status;
export default {
  "2D depan": "2D Depan",
  "2D tengah": "2D Tengah",
  "2d": "2D",
  "2d_depan": "2D Depan",
  "2d_tengah": "2D Tengah",
  "3d": "3D",
  "4d": "4D",
  "4d3d2d": "4D / 3D / 2D",
  "4d3d2d_set": "4D / 3D / 2D",
  "4d_set": "4D Set",
  "5050": "50-50",
  "5050_2d": "50-50 2D",
  "APK Link": "APK Link",
  "APK Message": "APK Message",
  "APK Name": "APK Name",
  "APK Status": "APK Status",
  "ASC": "ASC",
  "About Us": "About Us",
  "Account ID": "Account ID",
  "Account Name": "Account Name",
  "Account Name required": "Invalid bank account name",
  "Account Number": "Account Number",
  "Account Number required": "Invalid bank account number",
  "Account name or number": "Bank Account Name/Account No",
  "Account type": "Account Type",
  "AccountNumberRangeDigits": "Must be {{min_number_of_digits}} - {{number_of_digits}} digits. Allows numbers only",
  "Action": "Action",
  "Active": "Active",
  "Active Companies": "Active Companies",
  "Active Members": "Active Members",
  "Active Whitelabels": "Active Whitelabels",
  "Activity": "Activity",
  "Add": "Add",
  "Add Result": "Add Result",
  "Add a new promotion": "Add a new promotion",
  "Add promotion": "Add Promotion",
  "Add result success": "The result has been submitted successfully! It will take some time to calculate the bets list ....",
  "Admin Log": "Admin Log",
  "After Login": "After Login",
  "Agent Type": "Account Type",
  "All Activities": "All activities",
  "All Banks": "All Banks",
  "All Company": "All Companies",
  "All Groups": "All Bank Groups",
  "All Member": "All Members",
  "All Providers": "All Providers",
  "All WhiteLabels": "All WhiteLabels",
  "All game": "All Games",
  "Allow to access all reports": "Allow to access all reports",
  "Allow to access and manage the Bank Account List": "Allow to access and manage the Bank Account Management",
  "Allow to access and manage the Deposit and Withdraw Request List": "Allow to access and manage the Deposit and Withdraw Request List",
  "Allow to access and manage the Member List": "Allow to access and manage the Member List",
  "Allow to access and manage the Result List": "Allow to access and manage the Result List",
  "Allow to access and manage the Whitelabel List": "Allow to access and manage the Whitelabel List",
  "Allow to access and update the Site Config": "Allow to access all features in Customization",
  "Allow to access the Announcement menu": "Allow to access the Announcement menu",
  "Allow to access the Bank Management menu": "Allow to access the Bank Management menu",
  "Allow to access the Bets menu": "Allow to access the Bets menu",
  "Allow to access the Bonus Payment menu": "Allow to access and manage Bonus:",
  "Allow to access the Company menu": "Allow to access the Company menu",
  "Allow to access the Dashboard menu": "Allow to access the Dashboard and Device Report menu",
  "Allow to access the Global Member List menu": "Allow to access the Global Member List menu",
  "Allow to access the Logs menu": "Allow to access the Logs menu",
  "Allow to access the Memo menu": "Allow to access the Memo menu",
  "Allow to access the Reports menu": "Allow to access the Reports menu",
  "Allow to access the Result List menu": "Allow to access the Results menu",
  "Allow to access the Whitelabel menu": "Allow to access the Whitelabel menu.",
  "Allow to access these menus": "Allow to access these menus:",
  "Allow to customize the Company site": "Allow to customize the Company site",
  "Allow to edit": "Allow to edit",
  "AllowedExtensionsList": "Allowed extensions: {{list}}",
  "Amount": "Amount",
  "Amount required": "Amount is required",
  "AmountFreebetFormText": "The amount is used for calculating bonus amount",
  "AmountHintMinValue": "Enter amount, greater than {{minValue}}",
  "Analyze Result": "Analyze Result",
  "App List": "App List",
  "App Logo": "App Logo",
  "App Name": "App Name",
  "Apply": "Apply",
  "Approve upload bonus success": "Upload file successfully!",
  "Approved Deposit Requests": "Approved Deposit Requests",
  "Approved Withdrawal Requests": "Approved Withdrawal Requests",
  "Are you sure": "Are you sure",
  "Are you sure want to cancel this result?": "Are you sure you want to cancel this result?",
  "Are you sure want to create bank group?": "Are you sure you want to create bank group?",
  "Are you sure want to create manual DW?": "Are you sure you want to deposit / withdraw this member?",
  "Are you sure want to deactivate this pool?": "Are you sure you want to deactivate this pool?",
  "Are you sure want to delete bank group?": "Are you sure you want to delete this bank group?",
  "Are you sure want to delete company bank account?": "Are you sure you want to delete this bank account?",
  "Are you sure want to delete this mail?": "Are you sure you want to delete this message?",
  "Are you sure want to edit credit?": "Are you sure you want to edit credit?",
  "Are you sure you want to cancel this bet?": "Are you sure you want to cancel this bet?",
  "At": "At",
  "Auto Memo": "Auto Memo",
  "Back": "Back",
  "Balance": "Balance",
  "Ban": "Ban",
  "Bank": "Bank",
  "Bank Account": "Bank Account",
  "Bank Account List": "Bank Account List",
  "Bank Account Name": "Bank Account Name",
  "Bank Account Name / Bank Account Number": "Bank account name/account no",
  "Bank Account No": "Bank Account No",
  "Bank Balance": "Bank Balance",
  "Bank Group": "Bank Group",
  "Bank Information": "Bank Information",
  "Bank Initials": "Bank Initials",
  "Bank Name": "Bank Name",
  "Bank Transfer Logs": "Bank Transfer Logs",
  "Bank account name or number": "Bank account name/account no",
  "Bank initals or Bank name": "Bank initials / Bank name",
  "BankAccountNumberHint": "Must be {{number_of_digit}} digits. Allow numbers only",
  "Banned Date": "Banned Date",
  "Banned Members": "Banned Members",
  "Banner": "Banner",
  "Banner is required": "Banner is required.",
  "Banners": "Banners",
  "Banners Image": "Banners Image",
  "Before Login": "Before Login",
  "Beginning Balance": "Beginning Balance",
  "Bet": "Bet",
  "Bet (Total)": "Bet (Total)",
  "Bet ID": "Bet ID",
  "Bet Lose": "Bet Lose",
  "Bet Options": "Bet Options",
  "Bet Win": "Bet Win",
  "Bet/Games ID": "Bet/Games ID",
  "Bet/Lose": "Bet / Lose",
  "Bets List": "Bets List",
  "Bets Table": "Bets Table",
  "Black": "Black",
  "Blacklist IP Address": "Blacklist IP Address",
  "BlacklistIpHint": "Max 20 IPs. Use to block member's IP when they access the website. Leave blank to ignore check IP address.",
  "Block Numbers": "Block Numbers",
  "Blue White": "Blue White",
  "Bonus": "Bonus",
  "Bonus Deposit": "Bonus Deposit",
  "Bonus New Member": "Bonus New Member",
  "Bonus Referral": "Bonus Referral",
  "Bonus Report": "Bonus Report",
  "Bonus/Promo/Freebet": "Bonus/Promo/Freebet",
  "Browser": "Browser",
  "Building App": "Building App",
  "By User": "By",
  "By device": "By",
  "CANCELED RESULT": "CANCELED RESULT",
  "Cancel": "Cancel",
  "Cancel Period success": "The result is canceling! It will take some time to calculate the bets list ....",
  "Cancel Request": "OK",
  "Cancel bet Success!": "The bet is canceled!",
  "Cancel bet member message": "Cancel bet member message",
  "Cancel transfer": "Cancel transfer",
  "CancelTransferBetSuccess": "Cancel transfer bet success!",
  "Change": "Change",
  "Change Password": "Change Password",
  "Change Status": "Change Status",
  "Change password": "Change Password",
  "ChangeUplineBankAccountSuccess": "The bank account has been changed successfully!",
  "Clear": "Clear",
  "Close": "Close",
  "Close Time": "Close Time",
  "Code": "English",
  "Color": "Color",
  "Commission": "Commission",
  "Commission Is Invalid": "Commission value of {{game}} is invalid",
  "Commission Notice": "New commission will be applied on next report month",
  "CommissionHintArg": "From {{min}}% to {{max}}%",
  "Company": "Company",
  "Company Commission": "Company Commission",
  "Company Name": "Company Name",
  "Company or Whitelabel": "Company / Whitelabel",
  "CompanyNameTagDesc": "it is your site name, Name in your profile.",
  "Compose": "Compose",
  "Confirm": "Confirm",
  "Confirm Password": "Confirm Password",
  "Confirm action message": "Are you sure you want to {{action}} this account?",
  "ConfirmApproveRequest": "Are you sure you want to approve this request?",
  "ConfirmCancelRequest": "Are you sure you want to cancel this request?",
  "ConfirmCancelTransferBet": "Are you sure you want to cancel transfer this bet?",
  "ConfirmRejectRequest": "Are you sure you want to reject this request?",
  "ConfirmTransferBet": "Are you sure you want to transfer this bet?",
  "Contact Us": "Contact Us",
  "Content": "Content",
  "ContentTagNote": "You can use these tags to put on your content. When displaying on your site, it will be actual value / words.",
  "ContentTagsTitle": "Content Tags",
  "Copied to clipboard": "Copied to clipboard.",
  "Country": "Country",
  "Create": "Create",
  "Create Bank": "Create Bank",
  "Create Bank Account": "Create Bank Account",
  "Create Bank Account Successfully!": "The bank account has been created successfully!",
  "Create Bank Group": "Create Bank Group",
  "Create Bank Group success": "The bank group has been created successfully!",
  "Create Company": "Create Company",
  "Create Manual DW Success": "Deposit / Withdraw successfully!",
  "Create Member": "Create Member",
  "Create Member Account Success": "The account has been created successfully!",
  "Create Other Payment Methods": "Create Other Payment Methods",
  "Create Pool Success": "The pool has been created successfully!",
  "Create Pools": "Create Pools",
  "Create Sub Account Success": "The account has been created successfully!",
  "Create Whitelabel": "Create Whitelabel",
  "Create promotion": "Create promotion",
  "Create promotion success": "The promotion has been created successfully!",
  "CreateArg": "Create {{arg}}",
  "CreateMemberEmailHint": "Enter email. Required.",
  "Created Date": "Created Date",
  "Credit": "Credit",
  "Customization": "Customization",
  "CutNotHavePercent": "Cut",
  "DESC": "DESC",
  "DISCOUNT": "Discount (%)",
  "DISCOUNT_2D": "Discount 2D (%)",
  "DISCOUNT_2D_DEPAN": "Discount 2D Depan (%)",
  "DISCOUNT_2D_TENGAH": "Discount 2D Tengah (%)",
  "DISCOUNT_3D": "Discount 3D (%)",
  "DISCOUNT_4D": "Discount 4D (%)",
  "Daily Free Bet": "Daily Free Bet",
  "Date": "Date",
  "Date of Week": "Day of Week",
  "Deactive": "Deactivate",
  "Deactive pool success": "The pool is deactivated!",
  "Debit": "Debit",
  "Deduct": "Deduct",
  "Default": "Default",
  "Delete": "Delete",
  "Delete Bank Group success": "The bank group has been deleted successfully!",
  "Delete Company Bank Account success!": "The bank account has been deleted successfully!",
  "Delete mail success": "Message is deleted!",
  "Deposit Max": "Max Deposit",
  "Deposit Max required": "Invalid max deposit",
  "Deposit Min": "Min Deposit",
  "Deposit Min required": "Invalid min deposit",
  "Deposit Request List": "Deposit Request List",
  "Deposit Time End": "Deposit Time End",
  "Deposit amount of new member must be from": "Deposit amount of new member must be from",
  "Deposit/Withdrawal Form": "Deposit/Withdrawal Form",
  "DepositTimeEndFormText": "The last deposit time that members can receive this bonus",
  "DepositWithdrawTooltip": "Deposit/Withdraw",
  "Description": "Description",
  "Description required": "Description is required",
  "DescriptionHintMaxLength": "Enter description, maximum {{maxLength}} characters",
  "Desktop Banners": "Desktop Banners",
  "Destination Bank": "Destination Bank",
  "Detail Type": "Detail Type",
  "Details": "Details",
  "Device": "Device",
  "Device Report": "Device Report",
  "Different Banks": "Different Banks",
  "Discount": "Discount (%)",
  "Discount required": "Discount % is required",
  "Display Bank List": "Display Bank List",
  "Downline": "Downline",
  "Downlines": "Downlines",
  "Download": "Download",
  "Download CSV": "Download CSV",
  "Download Excel": "Download Excel",
  "DragdropFileNote": "Only *.{{fileType}} will be accepted",
  "DragdropPlaceholder": "Drag 'n' drop some files here, or click to select files",
  "Draw Day": "Draw Day",
  "Edit": "Edit",
  "Edit Member": "Edit Member",
  "Edit Pool": "Edit Pools",
  "Edit promotion": "Update Promotion",
  "EditArg": "Edit {{arg}}",
  "Ending Balance": "Ending Balance",
  "Enter Account Name": "Enter bank account name",
  "Enter Account number": "Enter bank account no",
  "Enter Amount": "Enter Amount",
  "Enter Bank Account Name": "Enter bank account name",
  "Enter Bank Account Number": "Enter bank account no",
  "Enter Deposit Time End": "Enter Deposit Time End",
  "Enter Email": "Enter Email",
  "Enter Event Name": "Enter Event Name",
  "Enter Max Amount": "Enter Max Amount",
  "Enter Min Deposit Amount": "Enter Min Deposit Amount",
  "Enter Phone Number": "Enter phone number",
  "Enter company name": "Enter Company Name",
  "Enter confirm password": "Enter Confirm Password",
  "Enter current password": "Enter Old Password",
  "Enter favicon": "Enter favicon link",
  "Enter logo": "Enter logo link",
  "Enter name": "Enter Name",
  "Enter password": "Enter Password",
  "Enter username": "Enter Username",
  "Enter whitelabel name": "Enter whitelabel name",
  "EnterField": "Enter {{field}}",
  "EnterReason": "Enter Reason",
  "Error!": "Error",
  "Event": "Event",
  "Event Name": "Event Name",
  "Event Rule": "Event Rule:",
  "Event Type": "Event Type",
  "Event name": "Event name",
  "EventNameHint": "Required. Max 50 characters",
  "Everyday": "Everyday",
  "FAQ": "FAQ",
  "Failed Attempts Since Last Login": "Failed attempts since last login: {{times}} times",
  "Failed to fetch": "Failed to fetch. Please try again",
  "Fake member": "Fake member",
  "Favicon": "Favicon",
  "Field is required": "Please fill into this field",
  "File": "File",
  "File is larger than 2000000 bytes": "File size is larger than 2MB",
  "File is larger than 5000000 bytes": "File size is larger than 5MB",
  "File type must be image/*": "File type must be image/*",
  "Files": "Files",
  "Fill if percent is selected": "Fill if % is selected",
  "Fishing": "Fishing",
  "Fishing Game": "Fishing Game",
  "Fishing Spadegaming": "Fishing Spadegaming",
  "Footer": "Footer Text",
  "Free Bet": "Free Bet",
  "Free Bet Report": "Free Bet Report",
  "Friday": "Friday",
  "Friendly Game Instance ID": "Friendly Game Instance ID",
  "From": "From",
  "From Bank": "From Bank",
  "Game": "Game",
  "Game Bet/Lose": "Game Bet/Lose",
  "Game Code": "Game Code",
  "Game Name": "Game Name",
  "Game Refund": "Game Refund",
  "Game Settings": "Games",
  "Game Win": "Game Win",
  "GameBetLoseAmount": "Game Bet/Lose: ({{amount}})",
  "GameDepositAmount": "Game Deposit: ({{amount}})",
  "GameID": "Game ID",
  "GameJackpotWinAmount": "Game Jackpot Win: ({{amount}})",
  "GameRefundAmount": "Game Refund: ({{amount}})",
  "GameWinAmount": "Game Win: ({{amount}})",
  "GameWithdrawAmount": "Game Withdraw: ({{amount}})",
  "GameWithdrawAmountAuto": "Game Withdraw (Auto): ({{amount}})",
  "Games": "Games",
  "Grand Total": "Grand Total",
  "Group": "Group",
  "Group Bet Options": "Group Bet Options",
  "Group Name": "Group Name",
  "GroupArg": "Group {{arg}}",
  "Guess": "Guess",
  "Habanero Referral": "Habanero Referral",
  "Habanero Transaction": "Habanero Transaction",
  "Holiday": "Holiday",
  "How To Play": "How to Play",
  "ION Referral": "ION Referral",
  "ION Transaction": "ION Transaction",
  "IOS Link": "IOS Link",
  "IOS Message": "IOS Message",
  "IOS Name": "IOS Name",
  "IOS Status": "IOS Status",
  "IP Address": "IP Address",
  "ImageRequiredMaxSizeArg": "Max {{size}}. Recommended dimension 100 x 30. Required",
  "In use": "In use",
  "Inactivate": "Inactivate",
  "Inactive": "Inactive",
  "Inactive Companies": "Inactive Companies",
  "Inactive Whitelabels": "Inactive Whitelabels",
  "Inbox": "Inbox",
  "Incorrect Captcha": "Incorrect captcha",
  "Information": "Information",
  "Inject File": "Inject File",
  "Input 4 digits": "Input 4 digits",
  "Invalid number of digits!": "Bank Account Number Length must greater than 0.",
  "Joined": "Joined",
  "Joined Date": "Joined Date",
  "Joker Referral": "JokerGaming Referral",
  "Joker Transaction": "JokerGaming Transaction",
  "KEI_AS_BESAR": "Kei As Besar (%)",
  "KEI_AS_GANJIL": "Kei As Ganjil (%)",
  "KEI_AS_GENAP": "Kei As Genap (%)",
  "KEI_AS_KECIL": "Kei As Kecil (%)",
  "KEI_BELAKANG_HOMO": "Kei Homo Belakang (%)",
  "KEI_BELAKANG_KEMBANG": "Kei Kembang Belakang (%)",
  "KEI_BELAKANG_KEMBAR": "Kei Kembar Belakang (%)",
  "KEI_BELAKANG_KEMPIS": "Kei Kempis Belakang (%)",
  "KEI_BELAKANG_SILANG": "Kei Silang Belakang (%)",
  "KEI_BESAR": "Kei Besar (%)",
  "KEI_DEPAN_HOMO": "Kei Homo Depan (%)",
  "KEI_DEPAN_KEMBANG": "Kei Kembang Depan (%)",
  "KEI_DEPAN_KEMBAR": "Kei Kembar Depan (%)",
  "KEI_DEPAN_KEMPIS": "Kei Kempis Depan (%)",
  "KEI_DEPAN_SILANG": "Kei Silang Depan (%)",
  "KEI_EKOR_BESAR": "Kei Ekor Besar (%)",
  "KEI_EKOR_GANJIL": "Kei Ekor Ganjil (%)",
  "KEI_EKOR_GENAP": "Kei Ekor Genap (%)",
  "KEI_EKOR_KECIL": "Kei Ekor Kecil (%)",
  "KEI_GANJIL": "Kei Ganjil (%)",
  "KEI_GENAP": "Kei Genap (%)",
  "KEI_KECIL": "Kei Kecil (%)",
  "KEI_KEPALA_BESAR": "Kei Kepala Besar (%)",
  "KEI_KEPALA_GANJIL": "Kei Kepala Ganjil (%)",
  "KEI_KEPALA_GENAP": "Kei Kepala Genap (%)",
  "KEI_KEPALA_KECIL": "Kei Kepala Kecil (%)",
  "KEI_KOP_BESAR": "Kei Kop Besar (%)",
  "KEI_KOP_GANJIL": "Kei Kop Ganjil (%)",
  "KEI_KOP_GENAP": "Kei Kop Genap (%)",
  "KEI_KOP_KECIL": "Kei Kop Kecil (%)",
  "KEI_TENGAH": "Kei Tengah (%)",
  "KEI_TENGAH_HOMO": "Kei Homo Tengah (%)",
  "KEI_TENGAH_KEMBANG": "Kei Kembang Tengah (%)",
  "KEI_TENGAH_KEMBAR": "Kei Kembar Tengah (%)",
  "KEI_TENGAH_KEMPIS": "Kei Kempis Tengah (%)",
  "KEI_TENGAH_SILANG": "Kei Silang Tengah (%)",
  "KEI_TEPI": "Kei Tepi (%)",
  "Kei": "Kei (%)",
  "Key Name": "Key Name",
  "Keywords": "Keywords",
  "LIMIT BUANG": "Limit Buang",
  "LIMIT TOTAL": "Limit Total",
  "LIMIT_BUANG_2D": "Limit Buang 2D",
  "LIMIT_BUANG_2D_DEPAN": "Limit Buang 2D Depan",
  "LIMIT_BUANG_2D_TENGAH": "Limit Buang 2D Tengah",
  "LIMIT_BUANG_3D": "Limit Buang 3D",
  "LIMIT_BUANG_4D": "Limit Buang 4D",
  "LIMIT_TOTAL_2D": "Limit Total 2D",
  "LIMIT_TOTAL_2D_DEPAN": "Limit Total 2D Depan",
  "LIMIT_TOTAL_2D_TENGAH": "Limit Total 2D Tengah",
  "LIMIT_TOTAL_3D": "Limit Total 3D",
  "LIMIT_TOTAL_4D": "Limit Total 4D",
  "Language": "Language",
  "Last 30 Days": "Last 30 Days",
  "Last 7 Days": "Last 7 Days",
  "Last Activity": "Last Activity",
  "Last Deposit Approved": "Last Deposit Approved",
  "Last Failed Login From": "Last failed login from {{ip}}",
  "Last IP": "Last IP",
  "Last Login From": "Last login from {{ip}}",
  "Last Month": "Last Month",
  "Last Update": "Last Update",
  "Last Withdrawal Approved": "Last Withdrawal Approved",
  "LengthMinMaxHintText": "Length from {{minLength}} to {{maxLength}} (allow numbers or lowercase only)",
  "LengthNumberOnly": "{{length}} chars. Numbers only",
  "Level": "Level",
  "Limit Buang": "Limit Buang",
  "Limit Buang required": "Limit Buang is required",
  "Limit Line": "Limit Line",
  "Limit Line 2D": "Limit Line 2D",
  "Limit Line 2D Depan": "Limit Line 2D Depan",
  "Limit Line 2D Tengah": "Limit Line 2D Tengah",
  "Limit Line 3D": "Limit Line 3D",
  "Limit Line 4D": "Limit Line 4D",
  "Limit Lines": "Update Limit Line",
  "Limit Number Digits": "Bank Account Number Length",
  "Limit Setting": "Deposit/Withdrawal Limitation",
  "Limit Total": "Limit Total",
  "Limit Total required": "Limit Total is required",
  "Limit Transfer Bets": "Limit Transfer Bets",
  "Limit transfer": "Limit transfer",
  "Link": "Link",
  "ListArg": "List {{arg}}",
  "Lock": "Lock",
  "Locked": "Locked",
  "Locked Companies": "Locked Companies",
  "Locked Members": "Locked Members",
  "Locked Whitelabels": "Locked Whitelabels",
  "Login": "Login",
  "Logo": "Logo",
  "Logout": "Logout",
  "Lose/Win": "Lose/Win",
  "Lost Money Report": "Lost Money Report",
  "MAX BET": "Max Bet",
  "MAX_BET_2D": "Max Bet 2D",
  "MAX_BET_2D_DEPAN": "Max Bet 2D Depan",
  "MAX_BET_2D_TENGAH": "Max Bet 2D Tengah",
  "MAX_BET_3D": "Max Bet 3D",
  "MAX_BET_4D": "Max Bet 4D",
  "MIN BET": "Min Bet",
  "Manage By": "Manage By",
  "Master Company": "Vigor",
  "Max": "Max",
  "Max bet less than min bet": "Max bet is less than min bet",
  "Max bet required": "Max bet is required",
  "Max length characters": "Max length {{length}} characters.",
  "MaxFileSizeNote": "File size: maximum {{size}} MB",
  "MaxLengthChars": "Maximum {{length}} chars.",
  "MaxLengthOptionalArg": "Maximum {{length}} chars. Optional",
  "MaxLengthRequiredArg": "Maximum {{length}} chars. Required",
  "Maximum / Minimum Bet": "Maximum / Minimum Bet",
  "Maximum Bet": "Maximum Bet",
  "Member": "Members",
  "Member Bank Account": "Member Payment Accounts",
  "Member Count": "Member",
  "Member List": "Member List",
  "Member Username": "Member Username",
  "Member Win Loss": "Member Win Loss",
  "Memo Compose": "Memo Compose",
  "Message": "Message",
  "Meta": "Meta",
  "MetaWebpageNote": "For SEO: Title, Meta (keyword, description, ...)",
  "Min": "Min",
  "Min Deposit Amount": "Min Deposit Amount",
  "Min bet required": "Min bet is required",
  "MinDepositTagDesc": "it is minimum deposit that you set in Bank Account Management \u003e Limit Settings",
  "MinWithdrawTagDesc": "it is minimum withdraw that you set in Bank Account Management \u003e Limit Settings",
  "Minimum Bet": "Minimum Bet",
  "Mobile Banners": "Mobile Banners",
  "Monday": "Monday",
  "Move to trash": "Move to Trash",
  "Move to trash mail success": "Message is moved to trash!",
  "Name": "Name",
  "New Password": "New Password",
  "No": "No.",
  "No Group": "No group",
  "No available period": "No available period",
  "NoteNewGameSettingAdmin": "Note: New game settings will be applied to new company and whitelabel accounts.",
  "NoteNewGameSettingsCompany": "Note: New game settings will be applied on next period of that pools.",
  "Number": "Number",
  "NumberOnlyAndBiggerThanZero": "Number only. And must be \u003e 0",
  "NumberOnlyPositiveRequired": "Number only - greater than 0",
  "Old Password": "Old Password",
  "Opening Hours": "Opening Hours",
  "Optional": "Optional",
  "Other Payment Methods": "Other Payment Methods",
  "Other Payment Methods Account": "Other Payment Methods Account",
  "OtherProvider": "Other Provider",
  "Others": "Others",
  "Package Name": "Package Name",
  "Page Not Found Desc": "This page you are looking for was not found.",
  "Page Not Found Title": "Oops! You're lost.",
  "Paid Amount": "Paid Amount",
  "Password": "Password",
  "PasswordHint": "From 6 characters. Contains at least lowercase, uppercase and number.",
  "PasswordRules": "Password must contains both letters and numbers.",
  "Pending Deposit Requests": "Pending Deposit Requests",
  "Pending Withdrawal Requests": "Pending Withdrawal Requests",
  "Period": "Period",
  "Period Online / Offline": "Period Online / Offline",
  "Permission": "Permission",
  "Phone Number": "Phone Number",
  "PhoneNumberHint": "Number only. Required.",
  "Player Win/Loss": "Player Win/Loss",
  "Please enter 1 or more characters": "Please enter 1 or more characters",
  "Please select at least 1 pool": "Please select at least 1 pool",
  "Please select at least 1 provider": "Please select at least 1 provider",
  "Pool Code": "Pools Code",
  "Pool Name": "Pools Name",
  "Pools": "Pools",
  "Pools List": "Pools List",
  "Pools not found": "Pools not found",
  "Pools/Games": "Pools/Games",
  "Popup": "Popup Banner",
  "Pragmatic Play": "Pragmatic Play",
  "PragmaticPlayLive Referral": "Pragmatic Play Live Referral",
  "PragmaticPlayLive Transaction": "Pragmatic Play Live Transaction",
  "Pragmatic Referral": "Pragmatic Play Referral",
  "Pragmatic Transaction": "Pragmatic Play Transaction",
  "Prize (winning multipler)": "Prize (winning multiplier)",
  "Processed At": "Processed At",
  "Profile": "Profile",
  "Profit": "Profit",
  "Profit (max)": "Profit (max)",
  "Profit (min)": "Profit (min)",
  "Promotion list": "Promotion List",
  "Provider": "Provider",
  "Rate": "Rate",
  "Rate / Cut": "Rate / Cut",
  "Real member": "Real member",
  "Reason": "Reason",
  "ReasonHint": "Required. Max 200 characters",
  "Receiver": "Receiver",
  "Reduce": "Reduce",
  "Ref No": "Ref No.",
  "Referral": "Referral",
  "Referral Bonus (Total)": "Referral Bonus (Total)",
  "Referral Code": "Referral Code",
  "Referral Downline list": "Referral Downline list",
  "Referral Game": "Referral Game",
  "Referral List": "Referral List",
  "Referral Notice": "New referral rate will be applied immediately",
  "Referral Rate": "Referral Rate",
  "Referral Report": "Referral Report",
  "Referrer": "Referrer",
  "Reject": "Reject",
  "Rejected Deposit Requests": "Rejected Deposit Requests",
  "Rejected Withdrawal Requests": "Rejected Withdrawal Requests",
  "Reply": "Reply",
  "RequestApprovedSuccess": "The request has been approved successfully!",
  "RequestCanceledSuccess": "The request has been canceled successfully!",
  "RequestRejectedSuccess": "The request has been rejected successfully!",
  "Reset": "Reset",
  "Result": "Result",
  "Result Date": "Result Date",
  "Result List": "Result List",
  "Result Time": "Result Time",
  "ResultNumberAddSuccess": "The result number has been added successfully!",
  "ResultNumberCancelSuccess": "The result number is canceled successfully!",
  "ResultNumberMin": "Result must be {{min}} digits",
  "Round ID": "Round ID",
  "Rules": "Rules",
  "RunningTextAfterLoginNote": "This text will be replaced text of Front Page after member logged in.",
  "RunningTextBeforeLoginNote": "This text will be run at the announcement position on the site, before member login.",
  "Same Banks": "Same Banks",
  "Saturday": "Saturday",
  "Save": "Save",
  "Scan": "Scan",
  "Scan Detail": "Scan Detail",
  "Scan Result": "Scan Result",
  "ScriptWebpageNote": "For Livechat scripts, Google Analytics scripts, ...",
  "Scripts": "Scripts",
  "Search": "Search",
  "Search By": "Search",
  "Search by": "Search by",
  "Select": "Select",
  "Select Action": "Select Action",
  "Select All": "Select All",
  "Select Date": "Select Date",
  "Select Providers": "Select Providers",
  "Select action": "Select action",
  "Select bank": "Select bank",
  "Select period": "Period",
  "Select pool": "Select pools",
  "Select sort": "Select sort",
  "Send Date": "Sent Date",
  "Send mail success": "The message has been sent successfully!",
  "Sender": "Sender",
  "Sent": "Sent",
  "Settle Time": "Settle Time",
  "ShioTableTagDesc": "display current shio table (this year).",
  "Show Reason": "Reason",
  "Sign In to your account": "Sign In to your account",
  "Site": "Site",
  "SiteConfigAppLogoNote": "Note *: The app image is automatically replaced when the new upload. Maximum upload size is 2MB, dimension 512x512 and should be .png file",
  "SiteConfigFavIconNote": "Note *: The favicon image is automatically replaced when the new upload.",
  "SiteConfigLogoNote": "Note *: The logo image is automatically replaced when the new upload.",
  "Sitemap": "Sitemap",
  "SitemapUploadedTime": "sitemap.xml is uploaded at {{date}}",
  "Slot Spadegaming": "Slot Spadegaming",
  "Social Links": "Social Links",
  "Sort": "Sort",
  "Sort Order": "Sort Order",
  "Spade Referral": "Spadegaming Referral",
  "Spade Transaction": "Spadegaming Transaction",
  "Status": "Status",
  "Subject": "Subject",
  "Submit": "Submit",
  "Submit Edit": "Submit",
  "Success": "Success",
  "Sunday": "Sunday",
  "Suspended": "Suspended",
  "Suspended Companies": "Suspended Companies",
  "Suspended Members": "Suspended Members",
  "Suspended Whitelabels": "Suspended Whitelabels",
  "System": "System",
  "System Profit": "System Profit",
  "Target": "Target",
  "Template": "Template",
  "Templates List": "Templates List",
  "This Month": "This Month",
  "Thumbnail": "Thumbnail",
  "Thursday": "Thursday",
  "Ticket ID": "Ticket ID",
  "Time": "Time",
  "TimezoneProviderDailyReport": "Timezone {{timezone}}",
  "Title": "Title",
  "Title required": "Title is required.",
  "To": "To",
  "Today": "Today",
  "Togel Referral": "Togel Referral",
  "Togel Transaction": "Togel Transaction",
  "Too many files": "Too many files",
  "Total": "Total",
  "Total Bet": "Total Bets",
  "Total Bet Transactions": "Total Bet Transactions",
  "Total Bet Winners": "Total Bet Winners",
  "Total Bill": "Total Bill",
  "Total Companies": "Total Companies",
  "Total Credit": "Total Credit",
  "Total Deposit": "Total Deposit",
  "Total Global Members": "Total Global Members",
  "Total Global Online Members": "Total Global Online Members",
  "Total Lose": "Total Lose",
  "Total Lost Bet": "Total Lost Bet",
  "Total Lost Money": "Total Lost Money",
  "Total Members": "Total Members",
  "Total Number": "Total Numbers",
  "Total Online Members": "Total Online Members",
  "Total Online Members List": "Total Online Members List",
  "Total Online Members by Downline": "Total Online Members by Downline",
  "Total Online Whitelabels": "Total Online Whitelabels",
  "Total Online Whitelabels List": "Total Online Whitelabels List",
  "Total Outstanding": "Total Outstanding",
  "Total Paid Amount": "Total Paid Amount",
  "Total Paid Bet": "Total Paid Bet",
  "Total Player Win Loss": "Total Player Win Loss",
  "Total Profit": "Total Profit",
  "Total User Bet": "Total User Bet",
  "Total Users": "Total Users",
  "Total Whitelabels": "Total Whitelabels",
  "Total Win": "Total Win",
  "Total Winner": "Total User Win",
  "Total Winning Amount": "Total Winning Amount",
  "Total Withdraw": "Total Withdraw",
  "Transaction Date": "Transaction Date",
  "Transaction Detail": "Transaction Details",
  "Transaction Details not found": "Transaction Details not found.",
  "Transaction History": "Transaction History",
  "Transaction ID": "Transaction ID",
  "Transaction Type": "Transaction Type",
  "Transfer": "Transfer",
  "Transfer Bets": "Transfer Bets",
  "Transfer Detail Logs": "Transfer Detail Logs",
  "Transfer From Bank": "Transfer From Bank",
  "TransferBetSuccess": "The bet is transferred!",
  "Trash": "Trash",
  "Tuesday": "Tuesday",
  "Turnover": "Turnover",
  "Type": "Type",
  "Unban": "Unban",
  "Unlock": "Unlock",
  "Unsuccess": "Failed",
  "Unsuspend": "Unsuspend",
  "Update": "Update",
  "Update App List success": "Upload application successfully!",
  "Update App Logo success": "Update App Logo success.",
  "Update Company Bank Account success!": "The bank account info has been updated successfully!",
  "Update Limit transfer bets success": "Update Limit transfer bets success.",
  "Update Pool Success": "The period status has been updated successfully!",
  "Update Success": "Updated successfully!",
  "Update auto memo success": "Update auto memo success.",
  "Update bank account balance success!": "Bank account balance has been updated successfully!",
  "Update customer service success": "Customer service information is updated!",
  "Update footer text success": "Update footer text success",
  "Update game setting success!": "The game settings has been updated successfully!",
  "Update group Company Bank Account success!": "The bank group has been changed successfully!",
  "Update information success": "Information is updated!",
  "Update limit deposit withdraw success!": "The limit settings has been updated successfully!",
  "Update member credit success!": "Member credit has been updated successfully!",
  "Update member status success!": "The account has been updated successfully!",
  "Update metadata success": "Meta is updated!",
  "Update promotion success": "Promotion is updated!",
  "Update running text success": "Running text is updated!",
  "Update script success": "Scripts are updated!",
  "Update scripts success": "Scripts are updated!",
  "Update status Company Bank Account success!": "The bank account status has been updated successfully!",
  "UpdateAppListNoteMsg": "The members will get this message when trying to download / install our inactive applications.",
  "UpdateBannersSuccess": "Update Banners successfully!",
  "UpdateDataSuccess": "Update {{dataUpdate}} success.",
  "UpdateFromSystemMessage": "There is an update from the system. Please refresh the page and try again.",
  "Updated Date": "Updated Date",
  "Uplines": "Uplines",
  "Upload Bonus": "Upload Bonus",
  "Upload app info successfully": "Upload app info successfully!",
  "Upload favicon successfully": "Upload favicon successfully!",
  "Upload logo successfully": "Upload logo successfully!",
  "Upload sitemap success": "Upload sitemap.xml successfully!",
  "UploadBonusFileHint": "Only accept excel file (.xlsx) to upload. With 2 columns A and B",
  "User Name": "Username",
  "User Referral": "User Referral",
  "Username": "Username",
  "Username or name": "Username / Name",
  "UsernameHint": "Length 2 to 6 (only allow number or lowercase character)",
  "Value Percent": "Value (%)",
  "View Images": "View Images",
  "WIN": "Win (x)",
  "WIN_1_D": "Win 1 digit (x)",
  "WIN_2D": "Win 2D (x)",
  "WIN_2D_DEPAN": "Win 2D Depan (x)",
  "WIN_2D_TENGAH": "Win 2D Tengah (x)",
  "WIN_2_D": "Win 2 digits (x)",
  "WIN_3D": "Win 3D (x)",
  "WIN_3_D": "Win 3 digits (x)",
  "WIN_4D": "Win 4D (x)",
  "WIN_4_D": "Win 4 digits (x)",
  "WIN_AS": "Win As (x)",
  "WIN_EKOR": "Win Ekor (x)",
  "WIN_KEPALA": "Win Kepala (x)",
  "WIN_KOP": "Win Kop (x)",
  "WL": "Whitelabel",
  "Webpage": "Web Page",
  "Website": "Website",
  "Website Status": "Website Status",
  "Wednesday": "Wednesday",
  "Welcome member message": "Welcome New Member",
  "WhiteIPHint": "Max 20 IPs. Leave blank to ignore check IP address.",
  "Whitelabel": "Whitelabel",
  "Whitelabel Commission": "Whitelabel Commission",
  "Whitelabel Count": "Whitelabel",
  "Whitelabel Name": "Whitelabel Name",
  "Whitelist IP Address": "Whitelist IP Address",
  "WhitelistIpHint": "Max 20 IPs. Leave blank to ignore check IP address.",
  "Win": "WIN",
  "Win (Total)": "Win (Total)",
  "Win Loss": "Win Loss",
  "Win Loss Report": "Win Loss Report",
  "Win digits required": "Win (x) is required",
  "Winners List": "Winners List",
  "Winning Amount": "Winning Amount",
  "Withdraw Max": "Max Withdraw",
  "Withdraw Max required": "Invalid max withdraw",
  "Withdraw Min": "Min Withdraw",
  "Withdraw Min required": "Invalid min withdraw",
  "Withdraw Request List": "Withdraw Request List",
  "Withdrawal": "Withdrawal",
  "X Deposit": "X Deposit Per Member",
  "Yesterday": "Yesterday",
  "activate": "Activate",
  "active": "Active",
  "add": "add",
  "adjust": "Adjust",
  "admin": "Admin",
  "all": "All",
  "and": "and",
  "announcement": "Announcement",
  "approve": "Approve",
  "approved": "Approved",
  "archive": "archive",
  "archived": "Archived",
  "as": "As",
  "ban": "ban",
  "bank-management": "Bank Management",
  "bank_group_not_found": "Bank group not found",
  "banned": "Banned",
  "bb_campuran": "BB Campuran",
  "belakang": "Belakang",
  "besar": "Big",
  "bet": "Bets",
  "bolak_balik": "Bolak Balik",
  "bolak_balik_2d": "Bolak Balik 2D",
  "bolak_balik_4d": "Bolak Balik 4D",
  "bonus-payment": "Bonus",
  "buffalo": "Buffalo",
  "cancel": "cancel",
  "canceled": "Canceled",
  "cancelled": "CANCELED",
  "chicken": "Chicken",
  "colok_bebas": "Colok Bebas",
  "colok_bebas_2d": "Colok Bebas 2D",
  "colok_jitu": "Colok Jitu",
  "colok_naga": "Colok Naga",
  "company": "Company",
  "confirm-active-bank": "Are you sure you want to activate this bank?",
  "confirm-inactive-bank": "Are you sure you want to inactivate this bank?",
  "confirmActionPromotion": "Are you sure you want to {{action}} this promotion?",
  "create-edit-company": "Create and Edit Company Accounts",
  "create-edit-member": "Create and Edit Member Accounts",
  "create-edit-whitelabel": "Create and Edit Whitelabel Accounts",
  "customization": "Customization",
  "dasar": "Dasar",
  "date": "Date",
  "default": "Default",
  "default group": "Default group",
  "delete": "delete",
  "depan": "Depan",
  "deposit": "Deposit",
  "deposit_greater_max": "Deposit amount too big",
  "deposit_time_must_larger_than_previous": "Deposit times too small",
  "deposit_time_must_less_than_next": "Deposit times too big",
  "descTransHis_FreeBet": "Free Bet: {{event_name}} ({{bonus_amount}})",
  "descTransHis_IonBetCancelled": "Game CANCELLED: ({{amount}})",
  "descTransHis_IonBetDraw": "Game DRAW: ({{amount}})",
  "descTransHis_IonBetLose": "Game Lose: ({{amount}})",
  "descTransHis_IonBetRollback": "Game Changed: ({{amount}})",
  "descTransHis_IonBetVoid": "Game VOID: ({{amount}})",
  "descTransHis_IonBetWon": "Game WON: ({{amount}})",
  "descTransHis_IonOrderDeduct": "Game Bet: ({{amount}})",
  "descTransHis_IonOrderRollback": "Game Refund: ({{amount}})",
  "dog": "Dog",
  "dragon": "Dragon",
  "duplicate_bank_account_no": "Duplicate bank account number",
  "e-money": "E-Money",
  "ekor": "Ekor",
  "err_period_cancel_wrong_period": "Invalid period. Please try again!",
  "err_period_update_before_close_time": "The period is running, has not closed yet.",
  "err_period_update_wrong_period": "Invalid period. Please try again!",
  "fake_online_number_valid": "Number only, \u003e or = 0",
  "form_validation_failed": "Form validation failed",
  "free_bet_bonus_deposit": "This bonus is for all members and applies from 2nd deposit time. \nWhen the request deposits are approved, the system will calculate bonus amount and give it to them (add to member balance) automatically if they meet conditions.\n1 member only can get 1 bonus per day.\nBonus will be added to member balance at 8:00AM - 12:00PM - 8:00PM - 11:59PM\nBonus amount = Amount (if you set Rp amount)\nOr = deposit amount * Amount % (if you set amount %)",
  "free_bet_bonus_new_description": "This bonus is for new members only and their 1st deposit time\nWhen new members deposit 1st time (approved request), the system will calculate bonus amount and give it to them (add to member balance) automatically if they meet conditions.\nBonus will be added to member balance at 8:00AM - 12:00PM - 8:00PM - 11:59PM\nAnd if that approved deposit request is canceled, we will get the deposit amount and bonus back too.\nDon’t apply to manual deposit from BO.\nBonus amount = Amount (if you set Rp amount)\nOr = deposit amount * Amount % (if you set amount %)",
  "ganjil": "Odd",
  "genap": "Even",
  "global-member-list": "Global Member List",
  "goat": "Goat",
  "homo": "Homo",
  "horse": "Horse",
  "in": "In",
  "in - out": "In - Out",
  "in(total)": "In (total)",
  "inactive": "Inactive",
  "invalid_value": "Invalid value",
  "ion casino": "ION Casino",
  "kecil": "Small",
  "kembang": "Kembang",
  "kembang_kempis": "Kembang - Kempis",
  "kembar": "Kembar",
  "kempis": "Kempis",
  "kepala": "Kepala",
  "kombinasi": "Kombinasi",
  "kop": "Kop",
  "limit_transfer_2d": "Limit Transfer 2D",
  "limit_transfer_2d_depan": "Limit Transfer 2D Depan",
  "limit_transfer_2d_tengah": "Limit Transfer 2D Tengah",
  "limit_transfer_3d": "Limit Transfer 3D",
  "limit_transfer_4d": "Limit Transfer 4D",
  "lock": "Lock",
  "locked": "Locked",
  "logout-change-pwd": "Your account has been changed password. Please contact your upline to get new password",
  "logout-inactive": "Your account has been inactivated. Please contact your upline to activate",
  "logout-ip": "Access Denied: Login attempt from unknown IP.",
  "logout-locked": "Your account has been locked. Please contact your upline to unlock",
  "lost": "LOSE",
  "manage-bank-account": "Bank Account Management",
  "manage-request": "Request Management",
  "max_bet_less_than_min_bet": "Max bet is less than min bet",
  "memo": "Memo",
  "monkey": "Monkey",
  "no_permission": "No permission",
  "no_permission_desc": "The page you are looking for is temporarily unavailable.",
  "online": "ONLINE",
  "out": "Out",
  "out(total)": "Out (total)",
  "ox": "Buffalo",
  "pending": "Pending",
  "pig": "Pig",
  "position": "position",
  "promotion": "Promotion",
  "pulsa": "Pulsa",
  "quick_2d": "Quick 2D",
  "quick_bet": "Quick Bet",
  "rabbit": "Rabbit",
  "rat": "Rat",
  "reduce": "reduce",
  "rejected": "Rejected",
  "required_captcha": "Captcha is required",
  "result-list": "Results",
  "running": "RUNNING",
  "setting": "Settings",
  "shio": "Shio",
  "silang": "Silang",
  "silang_homo": "Silang - Homo",
  "snake": "Snake",
  "suspend": "Suspend",
  "suspended": "Suspended",
  "tengah": "Tengah",
  "tepi": "Tepi",
  "tepi_tengah": "Tepi - Tengah",
  "tiger": "Tiger",
  "transferred": "Transferred",
  "unban": "unban",
  "unlock": "Unlock",
  "unsuspend": "Unsuspend",
  "update": "update",
  "view-dashboard": "View Dashboard",
  "view-log": "View Logs",
  "view-report": "View Reports",
  "win": "WIN",
  "withdraw": "Withdraw",
  "withdraw_greater_max": "Withdraw amount too big",
  "Allow to access and manage the Master Account menu": "Allow to access and manage the Master Account menu",
  "create-edit-master-wl": "Create and Edit Master Accounts",
  "Master Commission": "Master Commission",
  "Edit Master Account": "Edit Master Account",
  "Master Whitelabel": "Master Whitelabel",
  "Whitelabel Username": "Whitelabel Username",
  "Master Profit": "Master Profit",
  "Add Downline": "Add Downline",
  "Master Name": "Master Name",
  "Enter master name": "Enter master name",
  "Master Commission Notice": "New commission will be applied immediately",
  "Add Notification": "Add Notification",
  "Add a new notification": "Add a new notification",
  "confirmActionNotification": "Are you sure you want to {{action}} this notification?",
  "Update notification success": "Notification is updated!",
  "Update Notification": "Edit Notification",
  "Call interface too frequently": "The request timed out",
  "Add notification success": "Notification has been added successfully!",
  "ImageRequired500MaxSizeArg": "Max {{size}}. Recommended dimension 500 x 500. Required",
  "descTransHis_roll_in": "Game Roll in: ({{amount}})",
  "descTransHis_roll_out": "Game Roll out: ({{amount}})",
  "descTransHis_debit": "Game Debit:  ({{amount}})",
  "descTransHis_credit": "Game Credit: ({{amount}})",
  "descTransHis_bonus": "Game Bonus: ({{amount}})",
  "ION": "ION",
  "Habanero": "Habanero",
  "Spade": "Spadegaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "Joker": "JokerGaming",
  "CQ9": "CQ9",
  "CQ9 Transaction": "CQ9 Transaction",
  "CQ9 Referral": "CQ9 Referral",
  "Targets required": "Target required",
  "New Users": "New Users",
  "Copy report to clipboard": "Copy Report",
  "Deposit Count": "Deposit Count",
  "Withdraw Count": "Withdraw Count",
  "Report for": "Report for",
  "DescritionEnableOrDisableGame": "Check to allow the member plays games",
  "Same Payment": "Same Payment",
  "Payment account not found": "Payment account not found",
  "Microgaming": "Microgaming",
  "Microgaming Transaction": "Microgaming Transaction",
  "Microgaming Referral": "Microgaming Referral",
  "Bonus Turnover": "Bonus Turnover",
  "free_bet_bonus_turnover": "This bonus is for all members who played games and their turnover amount meets the conditions bonus.\nWeekly the system will calculate the bonus amount and give it to them (add to member balance) at the selected time.\nBonus amount = Weekly Game Provider Turnover of member * Rate %",
  "daily": "daily",
  "weekly": "weekly",
  "monthly": "monthly",
  "Turnover amount of new member must be from": "Turnover amount of new member must be from",
  "desBonusCashback": "This bonus is for all members who played games and their win loss (or turnover) amount meets the conditions bonus.\nWeekly the system will calculate the bonus amount and give it to them (add to member balance) at the selected time.\nBonus amount = abs(Weekly Game Provider Win loss (or Turnover) of member) * Rate %",
  "Min Amount": "Min Amount",
  "Min Turnover": "Min Turnover",
  "Max Bonus": "Max Bonus",
  "Every Monday": "Every Monday",
  "Every Tuesday": "Every Tuesday",
  "Every Wednesday": "Every Wednesday",
  "Every Thursday": "Every Thursday",
  "Every Friday": "Every Friday",
  "Every Saturday": "Every Saturday",
  "Every Sunday": "Every Sunday",
  "CONDITION TO GET BONUS": "CONDITION TO GET BONUS",
  "CONDITION TO DO WD": "CONDITION TO DO WD",
  "Bonus Cashback": "Bonus Cashback",
  "By Turnover": "By Turnover",
  "By WinLose": "By WinLose",
  "Base by Turnover (* Deposit)": "Base by Turnover (* Deposit)",
  "Base by Turnover (* (Deposit + Bonus))": "Base by Turnover (* (Deposit + Bonus))",
  "Manual Freebet": "Manual Freebet",
  "Include E-Money": "Include E-Money",
  "Manual Withdrawal": "Manual Withdrawal",
  "Manual Deposit": "Manual Deposit",
  "Pgsoft": "PG Soft",
  "PG Soft Transaction": "PG Soft Transaction",
  "PG Soft Referral": "PG Soft Referral",
  "GameTransferInAmount": "Game Transfer In: ({{amount}})",
  "GameTransferOutAmount": "Game Transfer Out: ({{amount}})",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "Deposit/Withdraw": "Deposit/Withdraw",
  "PGSoft Transaction": "PG Soft Transaction",
  "PGSoft Referral": "PG Soft Referral",
  "NoteFreebetBonusGame": "Note: The games don't have Turnover info: CQ9 Fishing games, Spadegaming Table Games and Fishing Games",
  "NoteCleaningData": "(We just keep transaction history of last 3 months)",
  "Period Start From": "Period Start From",
  "Fake first 10 result numbers": "Fake first 10 result numbers",
  "InputNumberdigits": "Input {{length}} digits",
  "CloseTimeUpdateMessage": "It will be closed sooner",
  "Your Close Time": "Your Close Time",
  "Update Close Time": "Update Close Time",
  "Than": "than",
  "GameChangedAmount": "Game Changed: ({{amount}})",
  "PGSoft Referral": "PG Soft Referral",
  "TTG": "TTG",
  "TTG Transaction": "TTG Transaction",
  "TTG Referral": "TTG Referral",
  "GameBonusAmount": "Game Bonus: ({{amount}})",
  "GameBetAmount": "Game Bet: ({{amount}})",
  "GameCancelledAmount": "Game CANCELLED: ({{amount}})",
  "GameDrawAmount": "Game Draw: ({{amount}})",
  "GameLoseAmount": "Game Lose: ({{amount}})",
  "GameVoidAmount": "Game Void: ({{amount}})",
  "GameWonAmount": "Game Won: ({{amount}})",
  "NoteFreebetBonusGame": "Note: The games don't have Turnover info: CQ9 Fishing games, Spadegaming Table Games and Fishing Games",
  "Current Turnover": "Current Turnover",
  "WinLoss Detail": "WinLoss Detail",
  "Min WinLoss": "Min WinLoss",
  "Current Winloss": "Current Winloss",
  "Bonus Amount": "Bonus Amount",
  "Turnover Detail": "Turnover Detail"
}
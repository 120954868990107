export default {
  "Code": "Vietnamese",
  "Error!": "Lỗi",
  "Failed to fetch": "Tải thất bại. Vui lòng thử lại",
  "Special characters is not allowed": "Không được nhập các ký tự đặc biệt.",
  "Webpage": "Web Page",
  "account_name_too_long": "Tên quá dài",
  "already_in_that_status": "Trạng thái đã thay đổi rồi. Thử lại",
  "bank_account_belong_to_active_group": "Tài khoản ngân hàng thuộc về nhóm đang hoạt động.",
  "bank_account_not_belong_to_group": "Tài khoản ngân hàng không thuộc nhóm nào.",
  "bank_not_found": "Không tìm thấy ngân hàng",
  "can_not_withdraw_with_member_others_payment": "Không thể rút tiền với kiểu thanh toán khác",
  "confirm_password_mismatch": "Mật khẩu không khớp",
  "deposit_greater_max": "Tiền nạp quá lớn",
  "deposit_less_min": "Số tiền gửi ít hơn tối thiểu",
  "deposit_min_greater_equal_deposit_max": "Số tiền đặt cọc nhỏ nhất lớn hơn số tiền đặt cọc lớn nhất.",
  "deposit_time_must_larger_than_previous": "số lần nạp tiền quá nhỏ",
  "deposit_time_must_less_than_next": "số lần nạp tiền quá lớn",
  "destination_to_send_memo_invalid": "Địa chỉ người nhận không hợp lệ",
  "duplicate_bank_code": "Trùng mã ngân hàng",
  "duplicate_payment_account_no": "Số tài khoản bị trùng",
  "duplicate_payment_provider": "Tên nhà cung cấp bị trùng",
  "duplicate_pool_code": "Mã đài đã tồn tại",
  "duplicate_pool_name": "Tên đài đã tồn tại",
  "duplicate_username": "Tên đăng nhập đã có",
  "err:member_not_found": "Không tìm thấy thành viên",
  "err_account_balance_insufficient_balance": "Số dư tài khoản không đủ",
  "err_bet_tx_is_transfer_status_invalid": "Bet đã được hủy chuyển nhượng",
  "err_bet_tx_is_transferred": "Bet đã chuyển đi. Hiện tại không khả dụng.",
  "err_bet_tx_not_running": "Bet này đang ko running",
  "err_block_number_duplicated": "_x0008_Số chặn bị trùng",
  "err_block_number_invalid_number": "Vui lòng nhập 4 chữ số",
  "err_invalid_limit_line_value": "Giới hạn ko hợp lệ",
  "err_invalid_period": "Kỳ sổ không hợp lệ",
  "err_period_already_offline": "Ko thể cập nhật. Nhà đài đã đóng",
  "err_period_already_online": "Kỳ đã trực tuyến",
  "err_period_cancel_wrong_period": "Kỳ ko hợp lệ, thử lại",
  "err_period_has_closed": "Ko thể cập nhật. Nhà đài đã đóng",
  "err_period_manager_cancelling": "Kỳ sổ này đang hủy kết quả. Hiện tại nó không dùng được.",
  "err_period_manager_updating": "Quản lý kì đang cập nhật",
  "err_period_not_found": "Không tìm thấy period",
  "err_period_result_blank": "Không tìm thấy kết quả",
  "err_period_result_updated": "Kỳ sổ này đang được cập nhật kết quả. Hiện tại nó không dùng được.",
  "err_period_update_before_close_time": "Thời gian cập nhật trước thời gian đóng",
  "err_period_update_wrong_period": "Kỳ ko hợp lệ, thử lại",
  "err_pool_invalid": "Đài không hợp lệ",
  "err_scan_bet_tx_invalid_number": "Số ko hợp lệ",
  "err_transaction_detail_not_found": "Không tim thấy chi tiết giao dịch",
  "field_can_not_be_blank": "Ô nhập không được để trống",
  "file_not_found": "Không có file",
  "file_too_large": "File too large (\u003e 2MB)",
  "form_validation_failed": "gửi form không thành công",
  "free_bet_amount_invalid": "Tiền thưởng không hợp lệ",
  "free_bet_can_not_edit_active_event": "Không thể chỉnh sửa sự kiện đang hoạt động",
  "free_bet_deposit_end_time_invalid": "Deposit Time End is invalid",
  "free_bet_event_name_invalid": "Tên sự kiện không hợp lệ",
  "free_bet_event_type_invalid": "Loại sự kiện không hợp lệ",
  "free_bet_max_amount_invalid": "Tiền thưởng tối đa không hợp lệ",
  "free_bet_min_deposit_amount_invalid": "Tiền Nạp tối thiểu không hợp lệ",
  "inactive_account": "Tài khoản đã bị bất hoạt động",
  "incorrect_password": "Sai mật khẩu",
  "internal_error": "Lỗi máy chủ nội bộ",
  "invalid_action_type": "Kiểu hành động không hợp lệ",
  "invalid_amount": "Số nhập không hợp lệ",
  "invalid_bank_account_name": "Tên tài khoản ngân hàng không hợp lệ",
  "invalid_bank_account_no": "Số tài khoản ngân hàng không hợp lệ",
  "invalid_bank_code": "Mã ngân hàng không hợp lệ",
  "invalid_bank_name": "Tên Ngân hàng không hợp lệ",
  "invalid_commission": "Hoa hồng không hợp lệ",
  "invalid_company_name": "Tên công ty không hợp lệ.",
  "invalid_contact_number": "Số điện thoại không hợp lệ",
  "invalid_current_password": "Mật khẩu hiện tại không hợp lệ",
  "invalid_display_name": "Tên hiển thị không hợp lệ",
  "invalid_draw_day": "Kỳ xổ ko hợp lệ",
  "invalid_email": "Địa chỉ email không hợp lệ",
  "invalid_file_format": "Định dạng tập tin không hợp lệ",
  "invalid_image_size": "Kích thước ảnh không hợp lệ",
  "invalid_image_type": "Định dạng hình ảnh không hợp lệ",
  "invalid_ip_address": "Địa chỉ IP không đúng định dạng",
  "invalid_limit_line_value": "Giới hạn ko hợp lệ",
  "invalid_max_number_of_digits": "Số tài khoản ngân hàng không hợp lệ.",
  "invalid_member_list": "DS người chơi ko hợp lệ",
  "invalid_min_number_of_digits": "Số tài khoản ngân hàng không hợp lệ.",
  "invalid_number_of_digits": "Số lượng số không hợp lệ",
  "invalid_number_of_digits_bank_account_no": "Số tài khoản ngân hàng/số chữ số không hợp lệ",
  "invalid_number_of_digits_payment_account_no": "Số lượng chữ số của tài khoản không hợp lệ",
  "invalid_package_name": "Tên package không hợp lệ",
  "invalid_password": "Mật khẩu không hợp lệ",
  "invalid_payment_account_name": "Tên tài khoản không hợp lệ",
  "invalid_payment_account_no": "Số tài khoản thanh toán không hợp lệ",
  "invalid_payment_provider": "Tên phương thức thanh toán không hợp lệ",
  "invalid_pool_code": "Mã đài ko hợp lệ",
  "invalid_pool_name": "Tên đài ko hợp lệ",
  "invalid_recaptcha": "Captcha không hợp lệ",
  "invalid_referral_rate": "Tỉ lệ hoa hồng không hợp lệ",
  "invalid_result": "",
  "invalid_template_key": "Template key không hợp lệ",
  "invalid_username": "Tên đăng nhập không hợp lệ",
  "ip_not_allowed": "Địa chỉ IP không hợp lệ",
  "locked_account": "Tài khoản đã bị khoá",
  "logo_not_found": "Không có logo",
  "member_not_found": "Không tìm thấy người chơi",
  "min_number_of_digits_must_less_than_or_equal_max_number_of_digits": "Chữ số tối thiểu phải nhỏ hơn hoặc bằng số chữ số lớn",
  "missing_reason": "Không được để trống lý do",
  "no_favicon": "Không có Favicon",
  "no_logo": "Không có Logo",
  "no_permission": "Không có quyền truy cập",
  "not_enough_balance_bank_account": "Số dư tài khoản ngân hàng không đủ",
  "not_found": "Không tồn tại",
  "payment_account_name_empty": "Yêu cầu nhập trường bắt buộc",
  "payment_account_no_empty": "Yêu cầu nhập trường bắt buộc",
  "pool_inactive": "Ko tìm thấy đài này",
  "provider_invalid": "Nhà cung cấp không hợp lệ",
  "reason_too_long": "Lý do quá dài, nên lòi.",
  "request_not_found": "Không tìm thấy yêu cầu.",
  "required_captcha": "Yêu cầu nhập captcha",
  "result_time_should_be_later_close_time": "Giờ ra kết quả phải sau giờ đóng bet",
  "suspended_account": "Không thể thực hiện hành động. Tài khoản của bạn đang bị đình chỉ.",
  "too_many_ips": "Quá nhiều IP",
  "two_bank_account_same_bank_one_group": "2 t/k ngân hàng từ một ngân hàng không được ở cùng một nhóm",
  "type_search_invalid": "Loại tìm kiếm không hợp lệ",
  "upline_bank_account_not_found": "không tìm thấy tài khoản ngân hàng tuyến trên",
  "withdraw_greater_max": "Tiền rút quá lớn",
  "withdraw_less_min": "Số tiền rút ít hơn tối thiểu",
  "withdraw_min_greater_equal_withdraw_max": "Số tiền rút ra nhỏ nhất lớn hơn số tiền rút ra lớn nhất.",
  "invalid_whitelabel_downline": "Invalid whitelabel downline",
  "invalid_template_key_length": "Invalid banner template",
  "invalid_title_length":	"invalid length title",
  "invalid_content_length": "Invalid length content",
  "member_has_no_payment_account": "Không cho phép member không có tài khoản thanh toán nào.",
  "free_bet_condition_turnover_amount_invalid": "Số nhập không hợp lệ",
  "free_bet_condition_withdraw_amount_invalid": "Số nhập không hợp lệ",
  "free_bet_provider_min_amount_invalid": "Giá trị nhỏ nhất ko hợp lệ",
  "free_bet_provider_max_bonus_invalid": "Giá trị thưởng lớn nhất ko hợp lệ",
  "free_bet_provider_rate_invalid": "% tỷ lệ ko hợp lệ",
  "invalid_start_period": "Kỳ sổ với 3 chữ số nhé",
  "invalid_result_size": "Nhập 10 kết quả (mỗi kết quả 4 chữ số) cách nhau bởi gạch ngang",
  "invalid_result_duplicate": "Kết quả bị trùng"
}
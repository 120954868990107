import React from "react";
import InputMask from "react-input-mask";
// 127.0.0.1
function InputIPAddress(props) {
  function checkIpValue(value) {
    const subips = value.split('.')
    if (subips.length > 4) {
      return false
    }
    const invalidSubips = subips.filter(ip => {
      ip = parseInt(ip)
      return ip < 0 || ip > 255
    })
    if (invalidSubips.length !== 0) {
      return false
    }
    let emptyIpCount = 0
    subips.forEach(ip => {
      if (ip === "") {
        emptyIpCount++
      }
    })
    if (emptyIpCount > 1) {
      return false
    }
    return true
  }
  const {innerRef, ...restOfProps} = props
  return (
    <InputMask
      {...restOfProps}
      inputRef={innerRef}
      className="form-control"
      formatChars={{
        "9": "[0-9.]",
      }}
      mask="999999999999999"
      maskChar={null}
      alwaysShowMask={false}
      beforeMaskedValueChange={(newState, oldState, userInput) => {
        var value = newState.value;
        var oldValue = oldState.value;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;
        const result = checkIpValue(value)
        if (!result) {
          value = value.trim()
          const newValue = value.substring(0, value.length - 1) + "." + value.substring(value.length - 1);
          if (checkIpValue(newValue)) {
            cursorPosition++
            selection = { start: cursorPosition, end: cursorPosition };
            value = newValue
          } else {
            value = oldValue
          }
        }

        return {
          value,
          selection
        };
      }}
    />
  )
}

export default InputIPAddress;
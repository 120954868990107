export default {
  "2D depan": "2D Depan",
  "2D tengah": "2D Tengah",
  "2d": "2D",
  "2d_depan": "2D Depan",
  "2d_tengah": "2D Tengah",
  "3d": "3D",
  "4d": "4D",
  "4d3d2d": "4D / 3D / 2D",
  "4d3d2d_set": "4D / 3D / 2D",
  "4d_set": "4D Set",
  "5050": "50-50",
  "5050_2d": "50-50 2D",
  "APK Link": "Link APK",
  "APK Message": "Pesan APK",
  "APK Name": "Nama APK",
  "APK Status": "Status APK",
  "ASC": "Menaik",
  "About Us": "Tentang Kami",
  "Account ID": "Account ID",
  "Account Name": "Nama Rekening",
  "Account Name required": "Nama rekening bank tidak valid",
  "Account Number": "Nomor Rekening",
  "Account Number required": "No rekening bank tidak valid",
  "Account name or number": "Nama Akun Bank / Akun No",
  "Account type": "Jenis akun",
  "AccountNumberRangeDigits": "Membutuhkan {{min_number_of_digits}} - {{number_of_digits}} digit. Hanya angka.",
  "Action": "Action",
  "Active": "Aktif",
  "Active Companies": "Active Companies",
  "Active Members": "Active Members",
  "Active Whitelabels": "Active Whitelabels",
  "Activity": "Activity",
  "Add": "Tambah",
  "Add Result": "Tambah Hasil",
  "Add a new promotion": "Tambahkan promosi baru",
  "Add promotion": "Tambahkan Promosi",
  "Add result success": "The result has been submitted successfully! It will take some time to calculate the bets list ....",
  "Admin Log": "Log Admin",
  "After Login": "Setelah Login",
  "Agent Type": "Jenis akun",
  "All Activities": "Semua kegiatan",
  "All Banks": "Semua bank",
  "All Company": "All Companies",
  "All Groups": "Semua bank grup",
  "All Member": "All Members",
  "All Providers": "All Providers",
  "All WhiteLabels": "All WhiteLabels",
  "All game": "Semua Game",
  "Allow to access all reports": "Izinkan untuk mengakses semua laporan",
  "Allow to access and manage the Bank Account List": "Izinkan untuk mengakses Manajemen Akun Bank",
  "Allow to access and manage the Deposit and Withdraw Request List": "Izinkan untuk mengakses permintaan Deposit dan Withdraw",
  "Allow to access and manage the Member List": "Izinkan untuk mengakses List Member",
  "Allow to access and manage the Result List": "Izinkan untuk mengakses Hasil Keluaran",
  "Allow to access and manage the Whitelabel List": "Izinkan untuk mengakses List Whitelabel",
  "Allow to access and update the Site Config": "Allow to access all features in Customization",
  "Allow to access the Announcement menu": "Izinkan untuk mengakses menu Pemberitahuan",
  "Allow to access the Bank Management menu": "Izinkan untuk mengakses menu Pengelolaan Bank",
  "Allow to access the Bets menu": "Izinkan untuk mengakses menu Bets",
  "Allow to access the Bonus Payment menu": "Izinkan untuk mengakses Bonus",
  "Allow to access the Company menu": "Izinkan untuk mengakses menu Company",
  "Allow to access the Dashboard menu": "Allow to access the Dashboard and Device Report menu",
  "Allow to access the Global Member List menu": "Izinkan untuk mengakses daftar member global",
  "Allow to access the Logs menu": "Izinkan untuk mengakses Menu Logs",
  "Allow to access the Memo menu": "Izinkan untuk mengakses menu Memo",
  "Allow to access the Reports menu": "Izinkan untuk mengakses menu Report",
  "Allow to access the Result List menu": "Izinkan untuk mengakses menu Keluaran",
  "Allow to access the Whitelabel menu": "Izinkan untuk mengakses menu Whitelabel",
  "Allow to access these menus": "Izinkan untuk mengakses menu ini:",
  "Allow to customize the Company site": "Izinkan untuk mengatur Company site",
  "Allow to edit": "Izinkan untuk mengedit",
  "AllowedExtensionsList": "Allowed extensions: {{list}}",
  "Amount": "Jumlah",
  "Amount required": "Jumlah diperlukan",
  "AmountFreebetFormText": "The amount is used for calculating bonus amount",
  "AmountHintMinValue": "Masukkan jumlah, lebih besar dari {{minValue}}",
  "Analyze Result": "Analisa Keluaran",
  "App List": "Daftar Aplikasi",
  "App Logo": "Logo Aplikasi",
  "App Name": "Nama Aplikasi",
  "Apply": "Terapkan",
  "Approve upload bonus success": "Unggah file dengan sukses!",
  "Approved Deposit Requests": "Permintaan Deposit Diterima",
  "Approved Withdrawal Requests": "Permintaan Withdraw Diterima",
  "Are you sure": "Apakah Anda yakin",
  "Are you sure want to cancel this result?": "Are you sure you want to cancel this result?",
  "Are you sure want to create bank group?": "Anda yakin ingin membuat grup bank ?",
  "Are you sure want to create manual DW?": "Apakah Anda yakin ingin deposit / withdraw akun ini?",
  "Are you sure want to deactivate this pool?": "Are you sure you want to deactivate this pool?",
  "Are you sure want to delete bank group?": "Anda yakin ingin menghapus grup bank ini?",
  "Are you sure want to delete company bank account?": "Anda yakin ingin menghapus rekening bank ini?",
  "Are you sure want to delete this mail?": "Anda yakin ingin menghapus pesan ini?",
  "Are you sure want to edit credit?": "Edit Kredit ?",
  "Are you sure you want to cancel this bet?": "Are you sure you want to cancel this bet?",
  "At": "Di",
  "Auto Memo": "Auto Memo",
  "Back": "Kembali",
  "Balance": "Balance",
  "Ban": "Ban",
  "Bank": "Bank",
  "Bank Account": "Rekening Bank",
  "Bank Account List": "Daftar Rekening Bank",
  "Bank Account Name": "Nama rekening",
  "Bank Account Name / Bank Account Number": "Nama akun bank / akun no",
  "Bank Account No": "Nomor rekening",
  "Bank Balance": "Balance Bank",
  "Bank Group": "Grup Bank",
  "Bank Information": "INFORMASI BANK",
  "Bank Initials": "Inisial Bank",
  "Bank Name": "Nama Bank",
  "Bank Transfer Logs": "Mutasi Bank",
  "Bank account name or number": "Nama akun bank / akun no",
  "Bank initals or Bank name": "Inisial bank / Nama bank",
  "BankAccountNumberHint": "Must be {{number_of_digit}} digits. Allow numbers only",
  "Banned Date": "Tanggal Ban",
  "Banned Members": "Banned Members",
  "Banner": "Spanduk",
  "Banner is required": "Spanduk diperlukan.",
  "Banners": "Spanduk",
  "Banners Image": "Banners Image",
  "Before Login": "Before Login",
  "Beginning Balance": "Saldo Awal",
  "Bet": "Bet",
  "Bet (Total)": "Bet (Total)",
  "Bet ID": "Bet ID",
  "Bet Lose": "Bet Lose",
  "Bet Options": "Bet Options",
  "Bet Win": "Bet Win",
  "Bet/Games ID": "Bet/Game ID",
  "Bet/Lose": "Bet / Lose",
  "Bets List": "List Bets",
  "Bets Table": "Bets Table",
  "Black": "Black",
  "Blacklist IP Address": "Alamat IP Blacklist",
  "BlacklistIpHint": "Max 20 IPs. Use to block member's IP when they access the website. Leave blank to ignore check IP address.",
  "Block Numbers": "Blok Angka",
  "Blue White": "Blue White",
  "Bonus": "Bonus",
  "Bonus Deposit": "Bonus Deposit",
  "Bonus New Member": "Bonus New Member",
  "Bonus Referral": "Bonus Referal",
  "Bonus Report": "Bonus Report",
  "Bonus/Promo/Freebet": "Bonus/Promo/Freebet",
  "Browser": "Browser",
  "Building App": "Building App",
  "By User": "Oleh",
  "By device": "By",
  "CANCELED RESULT": "CANCELED RESULT",
  "Cancel": "Batal",
  "Cancel Period success": "The result is canceling! It will take some time to calculate the bets list ....",
  "Cancel Request": "OK",
  "Cancel bet Success!": "The bet is canceled!",
  "Cancel bet member message": "Cancel bet member message",
  "Cancel transfer": "Cancel transfer",
  "CancelTransferBetSuccess": "Cancel transfer bet success!",
  "Change": "Ubah",
  "Change Password": "Ganti Kata Sandi",
  "Change Status": "Ganti status",
  "Change password": "Ganti Kata Sandi",
  "ChangeUplineBankAccountSuccess": "Akun bank berhasil diperbaharui !",
  "Clear": "Hapus",
  "Close": "Tutup",
  "Close Time": "Jam Tutup",
  "Code": "Indonesian",
  "Color": "Color",
  "Commission": "Komisi",
  "Commission Is Invalid": "Commission value of {{game}} is invalid",
  "Commission Notice": "New commission will be applied on next report month",
  "CommissionHintArg": "Dari {{min}}% hingga {{max}}%",
  "Company": "Company",
  "Company Commission": "Komisi Company",
  "Company Name": "Nama Company",
  "Company or Whitelabel": "Company / Whitelabel",
  "CompanyNameTagDesc": "it is your site name, Name in your profile.",
  "Compose": "Compose",
  "Confirm": "Konfirmasi",
  "Confirm Password": "Konfirmasi password",
  "Confirm action message": "Apakah Anda yakin ingin {{action}} akun ini?",
  "ConfirmApproveRequest": "Terima Permintaan ?",
  "ConfirmCancelRequest": "Tolak Transaksi ?",
  "ConfirmCancelTransferBet": "Are you sure you want to cancel transfer this bet?",
  "ConfirmRejectRequest": "Tolak Permintaan ?",
  "ConfirmTransferBet": "Are you sure you want to transfer this bet?",
  "Contact Us": "Hubungi Kami",
  "Content": "Konten",
  "ContentTagNote": "You can use these tags to put on your content. When displaying on your site, it will be actual value / words.",
  "ContentTagsTitle": "Content Tags",
  "Copied to clipboard": "Copied to clipboard.",
  "Country": "Negara",
  "Create": "Buat",
  "Create Bank": "Buat Bank",
  "Create Bank Account": "Buat Rekening Bank",
  "Create Bank Account Successfully!": "The bank account has been created successfully!",
  "Create Bank Group": "Buat Grup Bank",
  "Create Bank Group success": "Berhasil membuat grup Bank",
  "Create Company": "Buat Company",
  "Create Manual DW Success": "Deposit / Withdraw successfully!",
  "Create Member": "Buat Member",
  "Create Member Account Success": "Akun telah berhasil dibuat !",
  "Create Other Payment Methods": "Create Other Payment Methods",
  "Create Pool Success": "The pool has been created successfully!",
  "Create Pools": "Tambah Pasaran",
  "Create Sub Account Success": "Akun telah berhasil dibuat !",
  "Create Whitelabel": "Buat Whitelabel",
  "Create promotion": "Tambahkan Promosi",
  "Create promotion success": "Promosi telah berhasil dibuat!",
  "CreateArg": "Buat {{arg}}",
  "CreateMemberEmailHint": "Masukkan Email. Wajib.",
  "Created Date": "Dibuat di",
  "Credit": "Kredit",
  "Customization": "Kustomisasi",
  "CutNotHavePercent": "Potongan",
  "DESC": "Turun",
  "DISCOUNT": "Discount (%)",
  "DISCOUNT_2D": "Discount 2D (%)",
  "DISCOUNT_2D_DEPAN": "Discount 2D Depan (%)",
  "DISCOUNT_2D_TENGAH": "Discount 2D Tengah (%)",
  "DISCOUNT_3D": "Discount 3D (%)",
  "DISCOUNT_4D": "Discount 4D (%)",
  "Daily Free Bet": "Daily Free Bet",
  "Date": "Tanggal",
  "Date of Week": "Hari",
  "Deactive": "Deactive",
  "Deactive pool success": "The pool is deactivated!",
  "Debit": "Debit",
  "Deduct": "Kurang",
  "Default": "Default",
  "Delete": "Hapus",
  "Delete Bank Group success": "Grup Bank berhasil dihapus",
  "Delete Company Bank Account success!": "Akun Bank berhasil dihapus",
  "Delete mail success": "Memo dihapus!",
  "Deposit Max": "Maks Deposit",
  "Deposit Max required": "Withdraw maksimum tidak valid",
  "Deposit Min": "Min Deposit",
  "Deposit Min required": "Deposit minimum tidak valid",
  "Deposit Request List": "Daftar Permintaan Deposit",
  "Deposit Time End": "Deposit Time End",
  "Deposit amount of new member must be from": "Deposit amount of new member must be from",
  "Deposit/Withdrawal Form": "Deposit/Withdrawal Form",
  "DepositTimeEndFormText": "The last deposit time that members can receive this bonus",
  "DepositWithdrawTooltip": "Deposit / Withdraw",
  "Description": "Deskripsi",
  "Description required": "Deskripsi diperlukan",
  "DescriptionHintMaxLength": "Masukkan deskripsi, maksimum {{maxLength}} karakter",
  "Desktop Banners": "Spanduk Desktop",
  "Destination Bank": "Bank Tujuan",
  "Detail Type": "Detail Type",
  "Details": "Detail",
  "Device": "Device",
  "Device Report": "Device Report",
  "Different Banks": "Pilihan Lain",
  "Discount": "Discount (%)",
  "Discount required": "Diskon",
  "Display Bank List": "Bank Online Status",
  "Downline": "Downline",
  "Downlines": "Downline",
  "Download": "Download",
  "Download CSV": "Download CSV",
  "Download Excel": "Download Excel",
  "DragdropFileNote": "Silakan pilih file yang diakhiri dengan *.{{fileType}}",
  "DragdropPlaceholder": "Drag 'n' drop some files here, or click to select files",
  "Draw Day": "Hari Keluaran",
  "Edit": "Edit",
  "Edit Member": "Edit Member",
  "Edit Pool": "Edit Pasaran",
  "Edit promotion": "Perbarui Promosi",
  "EditArg": "Edit {{arg}}",
  "Ending Balance": "Saldo Akhir",
  "Enter Account Name": "Masukkan nama rekening bank",
  "Enter Account number": "Masukkan nomor rekening bank",
  "Enter Amount": "Enter Amount",
  "Enter Bank Account Name": "Masukkan nama rekening bank",
  "Enter Bank Account Number": "Masukkan nomor rekening bank",
  "Enter Deposit Time End": "Enter Deposit Time End",
  "Enter Email": "Enter Email",
  "Enter Event Name": "Enter Event Name",
  "Enter Max Amount": "Enter Max Amount",
  "Enter Min Deposit Amount": "Enter Min Deposit Amount",
  "Enter Phone Number": "Masukkan nomor telepon",
  "Enter company name": "Masukkan Nama Company",
  "Enter confirm password": "Masukkan Konfirmasi Kata Sandi",
  "Enter current password": "Masukkan Kata Sandi Lama",
  "Enter favicon": "Masukkan tautan favicon",
  "Enter logo": "Masukkan tautan logo",
  "Enter name": "Masukkan Nama",
  "Enter password": "Masukkan kata sandi",
  "Enter username": "Masukkan Username",
  "Enter whitelabel name": "Masukkan Nama Whitelabel",
  "EnterField": "Masukkan {{field}}",
  "EnterReason": "Masukkan Alasan",
  "Error!": "Kesalahan",
  "Event": "Event",
  "Event Name": "Nama Event",
  "Event Rule": "Event Rule:",
  "Event Type": "Event Type",
  "Event name": "Nama Event",
  "EventNameHint": "Wajib. Maks 50 karakter",
  "Everyday": "Setiap Hari",
  "FAQ": "FAQ",
  "Failed Attempts Since Last Login": "Upaya gagal sejak login terakhir: {{times}} kali",
  "Failed to fetch": "Failed to fetch. Please try again",
  "Fake member": "Fake member",
  "Favicon": "Favicon",
  "Field is required": "Please fill into this field",
  "File": "File",
  "File is larger than 2000000 bytes": "File size is larger than 2MB",
  "File is larger than 5000000 bytes": "File size is larger than 5MB",
  "File type must be image/*": "File type must be image/*",
  "Files": "Files",
  "Fill if percent is selected": "Isi bila pilih %",
  "Fishing": "Tembak Ikan",
  "Fishing Game": "Tembak Ikan",
  "Fishing Spadegaming": "Spadegaming",
  "Footer": "Footer Text",
  "Free Bet": "Free Bet",
  "Free Bet Report": "Free Bet Report",
  "Friday": "Jumat",
  "Friendly Game Instance ID": "Friendly Game Instance ID",
  "From": "Dari",
  "From Bank": "Dari Bank",
  "Game": "Game",
  "Game Bet/Lose": "Game Bet/Lose",
  "Game Code": "Game Code",
  "Game Name": "Game Nama",
  "Game Refund": "Game Refund",
  "Game Settings": "Game",
  "Game Win": "Game Win",
  "GameBetLoseAmount": "Game Bet/Lose: ({{amount}})",
  "GameDepositAmount": "Game Deposit: ({{amount}})",
  "GameID": "Game ID",
  "GameJackpotWinAmount": "Game Jackpot Win: ({{amount}})",
  "GameRefundAmount": "Game Refund: ({{amount}})",
  "GameWinAmount": "Game Win: ({{amount}})",
  "GameWithdrawAmount": "Game Withdraw: ({{amount}})",
  "GameWithdrawAmountAuto": "Game Withdraw (Auto): ({{amount}})",
  "Games": "Game",
  "Grand Total": "Jumlah Keseluruhan",
  "Group": "Grup",
  "Group Bet Options": "Group Bet Options",
  "Group Name": "Nama Grup",
  "GroupArg": "Grup {{arg}}",
  "Guess": "Tebak",
  "Habanero Referral": "Referal Habanero",
  "Habanero Transaction": "Transaksi Habanero",
  "Holiday": "Libur",
  "How To Play": "Panduan Permainan",
  "ION Referral": "Referal ION",
  "ION Transaction": "Transaksi ION",
  "IOS Link": "Link IOS",
  "IOS Message": "Pesan IOS",
  "IOS Name": "Nama IOS",
  "IOS Status": "Status IOS",
  "IP Address": "Alamat IP",
  "ImageRequiredMaxSizeArg": "Maks {{size}}. Dimensi yang disarankan 100 x 30. Wajib",
  "In use": "In use",
  "Inactivate": "Nonaktifkan",
  "Inactive": "Non-aktif",
  "Inactive Companies": "Inactive Companies",
  "Inactive Whitelabels": "Inactive Whitelabels",
  "Inbox": "Inbox",
  "Incorrect Captcha": "Captcha salah",
  "Information": "Informasi",
  "Inject File": "Inject File",
  "Input 4 digits": "4-digit angka",
  "Invalid number of digits!": "Panjang Nomor Rekening Bank harus lebih dari 0.",
  "Joined": "Joined",
  "Joined Date": "Join Date",
  "Joker Referral": "Referal JokerGaming",
  "Joker Transaction": "Transaksi JokerGaming",
  "KEI_AS_BESAR": "Kei As Besar (%)",
  "KEI_AS_GANJIL": "Kei As Ganjil (%)",
  "KEI_AS_GENAP": "Kei As Genap (%)",
  "KEI_AS_KECIL": "Kei As Kecil (%)",
  "KEI_BELAKANG_HOMO": "Kei Homo Belakang (%)",
  "KEI_BELAKANG_KEMBANG": "Kei Kembang Belakang (%)",
  "KEI_BELAKANG_KEMBAR": "Kei Kembar Belakang (%)",
  "KEI_BELAKANG_KEMPIS": "Kei Kempis Belakang (%)",
  "KEI_BELAKANG_SILANG": "Kei Silang Belakang (%)",
  "KEI_BESAR": "Kei Besar (%)",
  "KEI_DEPAN_HOMO": "Kei Homo Depan (%)",
  "KEI_DEPAN_KEMBANG": "Kei Kembang Depan (%)",
  "KEI_DEPAN_KEMBAR": "Kei Kembar Depan (%)",
  "KEI_DEPAN_KEMPIS": "Kei Kempis Depan (%)",
  "KEI_DEPAN_SILANG": "Kei Silang Depan (%)",
  "KEI_EKOR_BESAR": "Kei Ekor Besar (%)",
  "KEI_EKOR_GANJIL": "Kei Ekor Ganjil (%)",
  "KEI_EKOR_GENAP": "Kei Ekor Genap (%)",
  "KEI_EKOR_KECIL": "Kei Ekor Kecil (%)",
  "KEI_GANJIL": "Kei Ganjil (%)",
  "KEI_GENAP": "Kei Genap (%)",
  "KEI_KECIL": "Kei Kecil (%)",
  "KEI_KEPALA_BESAR": "Kei Kepala Besar (%)",
  "KEI_KEPALA_GANJIL": "Kei Kepala Ganjil (%)",
  "KEI_KEPALA_GENAP": "Kei Kepala Genap (%)",
  "KEI_KEPALA_KECIL": "Kei Kepala Kecil (%)",
  "KEI_KOP_BESAR": "Kei Kop Besar (%)",
  "KEI_KOP_GANJIL": "Kei Kop Ganjil (%)",
  "KEI_KOP_GENAP": "Kei Kop Genap (%)",
  "KEI_KOP_KECIL": "Kei Kop Kecil (%)",
  "KEI_TENGAH": "Kei Tengah (%)",
  "KEI_TENGAH_HOMO": "Kei Homo Tengah (%)",
  "KEI_TENGAH_KEMBANG": "Kei Kembang Tengah (%)",
  "KEI_TENGAH_KEMBAR": "Kei Kembar Tengah (%)",
  "KEI_TENGAH_KEMPIS": "Kei Kempis Tengah (%)",
  "KEI_TENGAH_SILANG": "Kei Silang Tengah (%)",
  "KEI_TEPI": "Kei Tepi (%)",
  "Kei": "Kei (%)",
  "Key Name": "Key Name",
  "Keywords": "Kata kunci",
  "LIMIT BUANG": "Limit Buang",
  "LIMIT TOTAL": "Limit Total",
  "LIMIT_BUANG_2D": "Limit Buang 2D",
  "LIMIT_BUANG_2D_DEPAN": "Limit Buang 2D Depan",
  "LIMIT_BUANG_2D_TENGAH": "Limit Buang 2D Tengah",
  "LIMIT_BUANG_3D": "Limit Buang 3D",
  "LIMIT_BUANG_4D": "Limit Buang 4D",
  "LIMIT_TOTAL_2D": "Limit Total 2D",
  "LIMIT_TOTAL_2D_DEPAN": "Limit Total 2D Depan",
  "LIMIT_TOTAL_2D_TENGAH": "Limit Total 2D Tengah",
  "LIMIT_TOTAL_3D": "Limit Total 3D",
  "LIMIT_TOTAL_4D": "Limit Total 4D",
  "Language": "Bahasa",
  "Last 30 Days": "30 Hari Terakhir",
  "Last 7 Days": "7 Hari Terakhir",
  "Last Activity": "Last Activity",
  "Last Deposit Approved": "Permintaan Deposit Terakhir",
  "Last Failed Login From": "Gagal masuk terakhir dari {{ip}}",
  "Last IP": "Last IP",
  "Last Login From": "Login terakhir dari {{ip}}",
  "Last Month": "Bulan lalu",
  "Last Update": "Last Update",
  "Last Withdrawal Approved": "Permintaan Withdraw Terakhir",
  "LengthMinMaxHintText": "Panjang {{minLength}} ke {{length}} (hanya boleh angka atau huruf kecil)",
  "LengthNumberOnly": "{{length}} karakter. Hanya angka",
  "Level": "Tingkat",
  "Limit Buang": "Limit Buang",
  "Limit Buang required": "Limit Buang",
  "Limit Line": "Limit Line",
  "Limit Line 2D": "Limit Line 2D",
  "Limit Line 2D Depan": "Limit Line 2D Depan",
  "Limit Line 2D Tengah": "Limit Line 2D Tengah",
  "Limit Line 3D": "Limit Line 3D",
  "Limit Line 4D": "Limit Line 4D",
  "Limit Lines": "Update Limit Line",
  "Limit Number Digits": "Panjang Nomor Rekening Bank",
  "Limit Setting": "Batasan Deposit / Withdraw",
  "Limit Total": "Limit Total",
  "Limit Total required": "Limit Total",
  "Limit Transfer Bets": "Limit Transfer Bets",
  "Limit transfer": "Limit transfer",
  "Link": "Link",
  "ListArg": "Daftar {{arg}}",
  "Lock": "Kunci",
  "Locked": "Terkunci",
  "Locked Companies": "Locked Companies",
  "Locked Members": "Locked Members",
  "Locked Whitelabels": "Locked Whitelabels",
  "Login": "Login",
  "Logo": "Logo",
  "Logout": "Keluar",
  "Lose/Win": "Lose / Win",
  "Lost Money Report": "Lost Money Report",
  "MAX BET": "Max Bet",
  "MAX_BET_2D": "Max Bet 2D",
  "MAX_BET_2D_DEPAN": "Max Bet 2D Depan",
  "MAX_BET_2D_TENGAH": "Max Bet 2D Tengah",
  "MAX_BET_3D": "Max Bet 3D",
  "MAX_BET_4D": "Max Bet 4D",
  "MIN BET": "Min Bet",
  "Manage By": "Manage By",
  "Master Company": "Vigor",
  "Max": "Max",
  "Max bet less than min bet": "Max bet is less than min bet",
  "Max bet required": "Maximal Bet",
  "Max length characters": "Panjang maksimum {{length}} karakter.",
  "MaxFileSizeNote": "File size: maximum {{size}} MB",
  "MaxLengthChars": "Maksimum {{length}} karakter.",
  "MaxLengthOptionalArg": "Maksimum {{length}} karakter. Tidak wajib",
  "MaxLengthRequiredArg": "Maksimum {{length}} karakter. Wajib",
  "Maximum / Minimum Bet": "Maximum / Minimum Bet",
  "Maximum Bet": "Maximum Bet",
  "Member": "Member",
  "Member Bank Account": "Member Payment Accounts",
  "Member Count": "Member",
  "Member List": "Daftar Member",
  "Member Username": "Member Username",
  "Member Win Loss": "Member Win Lose",
  "Memo Compose": "Tulis Memo",
  "Message": "Pesan",
  "Meta": "Meta",
  "MetaWebpageNote": "Untuk SEO: Judul, Meta (kata kunci, deskripsi, ...)",
  "Min": "Min",
  "Min Deposit Amount": "Min Deposit Amount",
  "Min bet required": "Minimal Bet",
  "MinDepositTagDesc": "it is minimum deposit that you set in Bank Account Management \u003e Limit Settings",
  "MinWithdrawTagDesc": "it is minimum withdraw that you set in Bank Account Management \u003e Limit Settings",
  "Minimum Bet": "Minimum Bet",
  "Mobile Banners": "Spanduk Seluler",
  "Monday": "Senin",
  "Move to trash": "Move to Trash",
  "Move to trash mail success": "Message is moved to trash!",
  "Name": "Nama",
  "New Password": "Kata Sandi Baru",
  "No": "No.",
  "No Group": "Tidak ada grup",
  "No available period": "No available period",
  "NoteNewGameSettingAdmin": "Note: New game settings will be applied to new company and whitelabel accounts.",
  "NoteNewGameSettingsCompany": "Note: New game settings will be applied on next period of that pools.",
  "Number": "Nomor",
  "NumberOnlyAndBiggerThanZero": "Number only. And must be \u003e 0",
  "NumberOnlyPositiveRequired": "Hanya angka - lebih besar dari 0",
  "Old Password": "Kata Sandi Lama",
  "Opening Hours": "Jam Buka",
  "Optional": "Optional",
  "Other Payment Methods": "Other Payment Methods",
  "Other Payment Methods Account": "Other Payment Methods Account",
  "OtherProvider": "Other Provider",
  "Others": "Others",
  "Package Name": "Nama Package",
  "Page Not Found Desc": "This page you are looking for was not found.",
  "Page Not Found Title": "Oops! You're lost.",
  "Paid Amount": "Bayar",
  "Password": "Kata sandi",
  "PasswordHint": "Minimal 6 karakter. Berisi setidaknya huruf kecil, huruf besar dan angka",
  "PasswordRules": "Kata sandi harus mempunyai huruf dan angka.",
  "Pending Deposit Requests": "Deposit Pending",
  "Pending Withdrawal Requests": "Permintaan Withdraw Ditunda",
  "Period": "Periode",
  "Period Online / Offline": "Periode Online / Offline",
  "Permission": "Izin",
  "Phone Number": "Nomor Telepon",
  "PhoneNumberHint": "Hanya angka. Wajib.",
  "Player Win/Loss": "Player Win/Loss",
  "Please enter 1 or more characters": "Silakan masukkan 1 atau lebih banyak karakter",
  "Please select at least 1 pool": "Please select at least 1 pool",
  "Please select at least 1 provider": "Please select at least 1 provider",
  "Pool Code": "Kode Pasaran",
  "Pool Name": "Nama Pasaran",
  "Pools": "Pasaran",
  "Pools List": "Daftar Pasaran",
  "Pools not found": "Pasaran tidak ditemukan",
  "Pools/Games": "Pasaran/Game",
  "Popup": "Popup Banner",
  "Pragmatic Play": "Pragmatic Play",
  "PragmaticPlayLive Referral": "Referal Pragmatic Play Live",
  "PragmaticPlayLive Transaction": "Transaksi Pragmatic Play Live",
  "Pragmatic Referral": "Referal Pragmatic Play",
  "Pragmatic Transaction": "Transaksi Pragmatic Play",
  "Prize (winning multipler)": "Prize (winning multiplier)",
  "Processed At": "Processed At",
  "Profile": "Profil",
  "Profit": "Profit",
  "Profit (max)": "Profit (max)",
  "Profit (min)": "Profit (min)",
  "Promotion list": "Daftar Promosi",
  "Provider": "Provider",
  "Rate": "Rate",
  "Rate / Cut": "Rate / Potongan",
  "Real member": "Real member",
  "Reason": "Alasan",
  "ReasonHint": "Wajib. Maks 200 karakter",
  "Receiver": "Receiver",
  "Reduce": "Kurangi",
  "Ref No": "Ref No.",
  "Referral": "Referal",
  "Referral Bonus (Total)": "Bonus Referal (Total)",
  "Referral Code": "Kode referal",
  "Referral Downline list": "Referral Downline list",
  "Referral Game": "Referal Game",
  "Referral List": "List Referal",
  "Referral Notice": "New referral rate will be applied immediately",
  "Referral Rate": "Referral Rate",
  "Referral Report": "Referral Report",
  "Referrer": "Referrer",
  "Reject": "Reject",
  "Rejected Deposit Requests": "Permintaan Deposit Ditolak",
  "Rejected Withdrawal Requests": "Permintaan Withdraw Ditolak",
  "Reply": "Balas",
  "RequestApprovedSuccess": "Permintaan berhasil diterima !",
  "RequestCanceledSuccess": "Transaksi berhasil ditolak !",
  "RequestRejectedSuccess": "Permintaan berhasil ditolak !",
  "Reset": "Reset",
  "Result": "Hasil",
  "Result Date": "Result Date",
  "Result List": "Hasil Keluaran",
  "Result Time": "Jam Buka",
  "ResultNumberAddSuccess": "The result number has been added successfully!",
  "ResultNumberCancelSuccess": "The result number is canceled successfully!",
  "ResultNumberMin": "Result must be {{min}} digits",
  "Round ID": "Round ID",
  "Rules": "SYARAT DAN KETENTUAN",
  "RunningTextAfterLoginNote": "Teks ini akan diganti teks Halaman Depan setelah member login.",
  "RunningTextBeforeLoginNote": "Teks ini akan dijalankan pada posisi pengumuman di situs, sebelum member login",
  "Same Banks": "Pilih Rekening",
  "Saturday": "Sabtu",
  "Save": "Save",
  "Scan": "Scan",
  "Scan Detail": "Scan Detail",
  "Scan Result": "Scan Keluaran",
  "ScriptWebpageNote": "Untuk skrip Livechat, skrip Google Analytics, ...",
  "Scripts": "Skrip",
  "Search": "Search",
  "Search By": "Search",
  "Search by": "Search by",
  "Select": "Pilih",
  "Select Action": "Koreksi",
  "Select All": "Select All",
  "Select Date": "Select Date",
  "Select Providers": "Select Providers",
  "Select action": "Pilih Koreksi",
  "Select bank": "Pilih bank",
  "Select period": "Periode",
  "Select pool": "Pilih pasaran",
  "Select sort": "Pilih sort",
  "Send Date": "Sent Date",
  "Send mail success": "The message has been sent successfully!",
  "Sender": "Sender",
  "Sent": "Sent",
  "Settle Time": "Settle Time",
  "ShioTableTagDesc": "display current shio table (this year).",
  "Show Reason": "Alasan",
  "Sign In to your account": "Sign In to your account",
  "Site": "Site",
  "SiteConfigAppLogoNote": "Note *: The app image is automatically replaced when the new upload. Maximum upload size is 2MB, dimension 512x512 and should be .png file",
  "SiteConfigFavIconNote": "Note *: The favicon image is automatically replaced when the new upload.",
  "SiteConfigLogoNote": "Note *: The logo image is automatically replaced when the new upload.",
  "Sitemap": "Sitemap",
  "SitemapUploadedTime": "sitemap.xml diunggah di {{date}}",
  "Slot Spadegaming": "Spadegaming",
  "Social Links": "Tautan Sosial",
  "Sort": "Sort",
  "Sort Order": "Sort Order",
  "Spade Referral": "Referal Spadegaming",
  "Spade Transaction": "Transaksi Spadegaming",
  "Status": "Status",
  "Subject": "Subject",
  "Submit": "Submit",
  "Submit Edit": "Submit",
  "Success": "Berhasil",
  "Sunday": "Minggu",
  "Suspended": "Ditangguhkan",
  "Suspended Companies": "Suspended Companies",
  "Suspended Members": "Suspended Members",
  "Suspended Whitelabels": "Suspended Whitelabels",
  "System": "Sistem",
  "System Profit": "Profit Sistem",
  "Target": "Target",
  "Template": "Template",
  "Templates List": "Templates List",
  "This Month": "Bulan ini",
  "Thumbnail": "Thumbnail",
  "Thursday": "Kamis",
  "Ticket ID": "Ticket ID",
  "Time": "Time",
  "TimezoneProviderDailyReport": "Timezone {{timezone}}",
  "Title": "Judul",
  "Title required": "Judul diperlukan.",
  "To": "Kepada",
  "Today": "Hari ini",
  "Togel Referral": "Referal Togel",
  "Togel Transaction": "Transaksi Togel",
  "Too many files": "Too many files",
  "Total": "Total",
  "Total Bet": "Total Bet",
  "Total Bet Transactions": "Total Jumlah Bet",
  "Total Bet Winners": "Total Bet Winners",
  "Total Bill": "Total Bill",
  "Total Companies": "Total Companies",
  "Total Credit": "Total Credit",
  "Total Deposit": "Total Deposit",
  "Total Global Members": "Total Global Members",
  "Total Global Online Members": "Total Global Online Members",
  "Total Lose": "Total Lose",
  "Total Lost Bet": "Total Lost Bet",
  "Total Lost Money": "Total Lost Money",
  "Total Members": "Total Members",
  "Total Number": "Total Nomor",
  "Total Online Members": "Total Online Members",
  "Total Online Members List": "Total Online Members List",
  "Total Online Members by Downline": "Total Online Members by Downline",
  "Total Online Whitelabels": "Total Online Whitelabels",
  "Total Online Whitelabels List": "Total Online Whitelabels List",
  "Total Outstanding": "Total Outstanding",
  "Total Paid Amount": "Total Prize",
  "Total Paid Bet": "Total Bet Bayar",
  "Total Player Win Loss": "Total Player Win Loss",
  "Total Profit": "Total Profit",
  "Total User Bet": "Total User Bet",
  "Total Users": "Total User",
  "Total Whitelabels": "Total Whitelabels",
  "Total Win": "Total Win",
  "Total Winner": "Total User Win",
  "Total Winning Amount": "Total Winning Amount",
  "Total Withdraw": "Total Withdraw",
  "Transaction Date": "Tanggal",
  "Transaction Detail": "Detail Transaksi",
  "Transaction Details not found": "Transaction Details not found.",
  "Transaction History": "History Transaksi",
  "Transaction ID": "Transaction ID",
  "Transaction Type": "Transaction Type",
  "Transfer": "Transfer",
  "Transfer Bets": "Bets Buangan",
  "Transfer Detail Logs": "Mutasi Bank Detail",
  "Transfer From Bank": "Bank Asal Transfer",
  "TransferBetSuccess": "The bet is transferred!",
  "Trash": "Trash",
  "Tuesday": "Selasa",
  "Turnover": "Turnover",
  "Type": "Type",
  "Unban": "Unban",
  "Unlock": "Membuka kunci",
  "Unsuccess": "Gagal",
  "Unsuspend": "Tidak ditangguhkan",
  "Update": "Perbarui",
  "Update App List success": "Unggah aplikasi dengan sukses!",
  "Update App Logo success": "Unggah logo aplikasi sukses!",
  "Update Company Bank Account success!": "Informasi Akun Bank berhasil diperbarui",
  "Update Limit transfer bets success": "Update Limit transfer bets success.",
  "Update Pool Success": "Status Periode berhasil diperbarui",
  "Update Success": "Berhasil diperbarui !",
  "Update auto memo success": "Update auto memo success.",
  "Update bank account balance success!": "Saldo dalam bank berhasil diperbarui !",
  "Update customer service success": "Informasi layanan pelanggan diperbarui!",
  "Update footer text success": "Update footer text success",
  "Update game setting success!": "Setting Permainan Berhasil Diperbarui !",
  "Update group Company Bank Account success!": "Grup Bank berhasil diubah",
  "Update information success": "Informasi di perbarui!",
  "Update limit deposit withdraw success!": "The limit settings has been updated successfully!",
  "Update member credit success!": "Saldo member berhasil diupdate !",
  "Update member status success!": "Akun telah berhasil diperbarui !",
  "Update metadata success": "Meta di perbarui!",
  "Update promotion success": "Promosi di perbarui!",
  "Update running text success": "Running Text di perbarui!",
  "Update script success": "Skrip di perbarui!",
  "Update scripts success": "Skrip di perbarui!",
  "Update status Company Bank Account success!": "Status Akun Bank berhasil diperbarui",
  "UpdateAppListNoteMsg": "The members will get this message when trying to download / install our inactive applications.",
  "UpdateBannersSuccess": "Unggah Spanduk dengan sukses!",
  "UpdateDataSuccess": "Update {{dataUpdate}} success.",
  "UpdateFromSystemMessage": "Ada Pembaruan di Halaman Web Silahkan Refresh/Segarkan Halaman Web Ini Kembali",
  "Updated Date": "Updated Date",
  "Uplines": "Upline",
  "Upload Bonus": "Upload Bonus",
  "Upload app info successfully": "Unggah info aplikasi dengan sukses!",
  "Upload favicon successfully": "Unggah favicon dengan sukses!",
  "Upload logo successfully": "Unggah logo dengan sukses!",
  "Upload sitemap success": "Unggah sitemap.xml dengan sukses!",
  "UploadBonusFileHint": "Format Excel Workbook (.xlsx) 2 kolom di A dan B",
  "User Name": "Username",
  "User Referral": "User Referal",
  "Username": "Username",
  "Username or name": "Username / Nama",
  "UsernameHint": "Panjang 2 ke 6 (hanya boleh angka atau huruf kecil)",
  "Value Percent": "Value (%)",
  "View Images": "View Images",
  "WIN": "Win (x)",
  "WIN_1_D": "Win 1 digit (x)",
  "WIN_2D": "Win 2D (x)",
  "WIN_2D_DEPAN": "Win 2D Depan (x)",
  "WIN_2D_TENGAH": "Win 2D Tengah (x)",
  "WIN_2_D": "Win 2 digit (x)",
  "WIN_3D": "Win 3D (x)",
  "WIN_3_D": "Win 3 digit (x)",
  "WIN_4D": "Win 4D (x)",
  "WIN_4_D": "Win 4 digit (x)",
  "WIN_AS": "Win As (x)",
  "WIN_EKOR": "Win Ekor (x)",
  "WIN_KEPALA": "Win Kepala (x)",
  "WIN_KOP": "Win Kop (x)",
  "WL": "Whitelabel",
  "Webpage": "Web Page",
  "Website": "Website",
  "Website Status": "Website Status",
  "Wednesday": "Rabu",
  "Welcome member message": "Welcome New Member",
  "WhiteIPHint": "Maks 20 ip. Biarkan kosong untuk mengabaikan memeriksa alamat ip.",
  "Whitelabel": "Whitelabel",
  "Whitelabel Commission": "Komisi Whitelabel",
  "Whitelabel Count": "Whitelabel",
  "Whitelabel Name": "Nama Whitelabel",
  "Whitelist IP Address": "IP yang disetujui",
  "WhitelistIpHint": "Maks 20 ip. Biarkan kosong untuk mengabaikan memeriksa alamat ip.",
  "Win": "WIN",
  "Win (Total)": "Win (Total)",
  "Win Loss": "Win Loss",
  "Win Loss Report": "Win Loss Report",
  "Win digits required": "Kemenangan",
  "Winners List": "Winners List",
  "Winning Amount": "Prize",
  "Withdraw Max": "Maks Withdraw",
  "Withdraw Max required": "Withdraw maksimum tidak valid",
  "Withdraw Min": "Min Withdraw",
  "Withdraw Min required": "Withdraw minimum tidak valid",
  "Withdraw Request List": "Daftar Permintaan Withdraw",
  "Withdrawal": "Withdraw",
  "X Deposit": "X Setoran Per Member",
  "Yesterday": "Kemarin",
  "activate": "Mengaktifkan",
  "active": "Aktif",
  "add": "tambah",
  "adjust": "Adjust",
  "admin": "Admin",
  "all": "Semua",
  "and": "dan",
  "announcement": "Pengumuman",
  "approve": "Approve",
  "approved": "Approved",
  "archive": "arsipkan",
  "archived": "Diarsipkan",
  "as": "As",
  "ban": "ban",
  "bank-management": "Manajemen Bank",
  "bank_group_not_found": "Grup bank tidak ditemukan",
  "banned": "Banned",
  "bb_campuran": "BB Campuran",
  "belakang": "Belakang",
  "besar": "Besar",
  "bet": "Bets",
  "bolak_balik": "Bolak Balik",
  "bolak_balik_2d": "Bolak Balik 2D",
  "bolak_balik_4d": "Bolak Balik 4D",
  "bonus-payment": "Bonus",
  "buffalo": "Kerbau",
  "cancel": "cancel",
  "canceled": "Canceled",
  "cancelled": "CANCELED",
  "chicken": "Ayam",
  "colok_bebas": "Colok Bebas",
  "colok_bebas_2d": "Colok Bebas 2D",
  "colok_jitu": "Colok Jitu",
  "colok_naga": "Colok Naga",
  "company": "Company",
  "confirm-active-bank": "Anda yakin ingin mengaktifkan bank ini?",
  "confirm-inactive-bank": "Apakah Anda yakin ingin menonaktifkan bank ini?",
  "confirmActionPromotion": "Anda yakin ingin {{action}} promosi ini?",
  "create-edit-company": "Buat dan Edit Akun Company",
  "create-edit-member": "Buat dan Edit Akun Member",
  "create-edit-whitelabel": "Buat dan Edit Akun Whitelabel",
  "customization": "Kustomisasi",
  "dasar": "Dasar",
  "date": "Tanggal",
  "default": "Default",
  "default group": "Grup default",
  "delete": "hapus",
  "depan": "Depan",
  "deposit": "Deposit",
  "deposit_greater_max": "Deposit amount too big",
  "deposit_time_must_larger_than_previous": "Jumlah Deposit terlalu kecil",
  "deposit_time_must_less_than_next": "Jumlah Deposit terlalu besar",
  "descTransHis_FreeBet": "Free Bet: {{event_name}} ({{bonus_amount}})",
  "descTransHis_IonBetCancelled": "Game CANCELLED: ({{amount}})",
  "descTransHis_IonBetDraw": "Game DRAW: ({{amount}})",
  "descTransHis_IonBetLose": "Game Lose: ({{amount}})",
  "descTransHis_IonBetRollback": "Game Changed: ({{amount}})",
  "descTransHis_IonBetVoid": "Game VOID: ({{amount}})",
  "descTransHis_IonBetWon": "Game WON: ({{amount}})",
  "descTransHis_IonOrderDeduct": "Game Bet: ({{amount}})",
  "descTransHis_IonOrderRollback": "Game Refund: ({{amount}})",
  "dog": "Anjing",
  "dragon": "Naga",
  "duplicate_bank_account_no": "Gandakan nomor rekening bank",
  "e-money": "E-Money",
  "ekor": "Ekor",
  "err_period_cancel_wrong_period": "Invalid period. Please try again!",
  "err_period_update_before_close_time": "The period is running, has not closed yet.",
  "err_period_update_wrong_period": "Invalid period. Please try again!",
  "fake_online_number_valid": "Hanya angka, \u003e atau = 0",
  "form_validation_failed": "Validasi Gagal",
  "free_bet_bonus_deposit": "This bonus is for all members and applies from 2nd deposit time. \nWhen the request deposits are approved, the system will calculate bonus amount and give it to them (add to member balance) automatically if they meet conditions.\n1 member only can get 1 bonus per day.\nBonus will be added to member balance at 8:00AM - 12:00PM - 8:00PM - 11:59PM\nBonus amount = Amount (if you set Rp amount)\nOr = deposit amount * Amount % (if you set amount %)",
  "free_bet_bonus_new_description": "This bonus is for new members only and their 1st deposit time\nWhen new members deposit 1st time (approved request), the system will calculate bonus amount and give it to them (add to member balance) automatically if they meet conditions.\nBonus will be added to member balance at 8:00AM - 12:00PM - 8:00PM - 11:59PM\nAnd if that approved deposit request is canceled, we will get the deposit amount and bonus back too.\nDon’t apply to manual deposit from BO.\nBonus amount = Amount (if you set Rp amount)\nOr = deposit amount * Amount % (if you set amount %)",
  "ganjil": "Ganjil",
  "genap": "Genap",
  "global-member-list": "Daftar Member Global",
  "goat": "Kambing",
  "homo": "Homo",
  "horse": "Kuda",
  "in": "Masuk",
  "in - out": "(Masuk - Keluar)",
  "in(total)": "Masuk (total)",
  "inactive": "Non-aktif",
  "invalid_value": "Invalid value",
  "ion casino": "ION Casino",
  "kecil": "Kecil",
  "kembang": "Kembang",
  "kembang_kempis": "Kembang - Kempis",
  "kembar": "Kembar",
  "kempis": "Kempis",
  "kepala": "Kepala",
  "kombinasi": "Kombinasi",
  "kop": "Kop",
  "limit_transfer_2d": "Limit Transfer 2D",
  "limit_transfer_2d_depan": "Limit Transfer 2D Depan",
  "limit_transfer_2d_tengah": "Limit Transfer 2D Tengah",
  "limit_transfer_3d": "Limit Transfer 3D",
  "limit_transfer_4d": "Limit Transfer 4D",
  "lock": "Kunci",
  "locked": "Terkunci",
  "logout-change-pwd": "Password Akun anda telah berhasil diubah. Silahkan hubungi upline anda untuk mendapatkan password baru",
  "logout-inactive": "Oops! Akun anda telah dinonaktifkan. Silahkan hubungi upline anda untuk mengaktifkan",
  "logout-ip": "Akses Ditolak: Upaya masuk dari IP yang tidak dikenal",
  "logout-locked": "Akun anda terkena kunci. Silahkan hubungi upline anda untuk membuka kunci",
  "lost": "LOSE",
  "manage-bank-account": "Manajemen Akun Bank",
  "manage-request": "Kelola Permintaan",
  "max_bet_less_than_min_bet": "Max bet is less than min bet",
  "memo": "Memo",
  "monkey": "Monyet",
  "no_permission": "Tidak ada izin",
  "no_permission_desc": "The page you are looking for is temporarily unavailable.",
  "online": "ONLINE",
  "out": "Keluar",
  "out(total)": "Keluar (total)",
  "ox": "Kerbau",
  "pending": "Pending",
  "pig": "Babi",
  "position": "posisi",
  "promotion": "Promosi",
  "pulsa": "Pulsa",
  "quick_2d": "Quick 2D",
  "quick_bet": "Quick Bet",
  "rabbit": "Kelinci",
  "rat": "Tikus",
  "reduce": "kurangi",
  "rejected": "Rejected",
  "required_captcha": "Validasi",
  "result-list": "Keluaran",
  "running": "RUNNING",
  "setting": "Pengaturan",
  "shio": "Shio",
  "silang": "Silang",
  "silang_homo": "Silang - Homo",
  "snake": "Ular",
  "suspend": "Menangguhkan",
  "suspended": "Ditangguhkan",
  "tengah": "Tengah",
  "tepi": "Tepi",
  "tepi_tengah": "Tepi - Tengah",
  "tiger": "Harimau",
  "transferred": "Transfered",
  "unban": "unban",
  "unlock": "Membuka kunci",
  "unsuspend": "Tidak ditangguhkan",
  "update": "update",
  "view-dashboard": "Lihat Dasbor",
  "view-log": "Lihat Log",
  "view-report": "Lihat Laporan",
  "win": "WIN",
  "withdraw": "Withdraw",
  "withdraw_greater_max": "Withdraw amount too big",
  "Allow to access and manage the Master Account menu": "Izinkan untuk mengakses menu Akun Master ",
  "create-edit-master-wl": "Buat dan Edit Akun Master",
  "Master Commission": "Komisi Master",
  "Edit Master Account": "Edit Master Account",
  "Master Whitelabel": "Master Whitelabel",
  "Whitelabel Username": "Whitelabel Username",
  "Master Profit": "Master Profit",
  "Add Downline": "Add Downline",
  "Master Name": "Nama Master",
  "Enter master name": "Masukkan Nama Master",
  "Master Commission Notice": "New commission will be applied immediately",
  "Call interface too frequently": "The request timed out",
  "Add Notification": "Tambahkan Pemberitahuan",
  "Add a new notification": "Tambahkan Pemberitahuan Baru",
  "confirmActionNotification": "Anda yakin ingin {{action}} pemberitahuan ini?",
  "Update notification success": "Pemberitahuan Telah Di Perbarui",
  "Update Notification": "Edit Pemberitahuan",
  "Add notification success": "Pemberitahuan telah berhasil di tambahkan",
  "ImageRequired500MaxSizeArg": "Maks {{size}}. Dimensi yang disarankan 500 x 500. Wajib",
  "descTransHis_roll_in": "Game Roll in: ({{amount}})",
  "descTransHis_roll_out": "Game Roll out: ({{amount}})",
  "descTransHis_debit": "Game Debit:  ({{amount}})",
  "descTransHis_credit": "Game Kredit: ({{amount}})",
  "descTransHis_bonus": "Game Bonus: ({{amount}})",
  "ION": "ION",
  "Habanero": "Habanero",
  "Spade": "Spadegaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "Joker": "JokerGaming",
  "CQ9": "CQ9",
  "CQ9 Transaction": "Transaksi CQ9",
  "CQ9 Referral": "Referal CQ9",
  "Targets required": "Target required",
  "New Users": "New Users",
  "Copy report to clipboard": "Copy Report",
  "Deposit Count": "Deposit Count",
  "Withdraw Count": "Withdraw Count",
  "Report for": "Report for",
  "Same Payment": "Same Payment",
  "Payment account not found": "Payment account not found",
  "Microgaming": "Microgaming",
  "Microgaming Transaction": "Transaksi Microgaming",
  "Microgaming Referral": "Referal Microgaming",
  "Bonus Turnover": "Bonus Turnover",
  "free_bet_bonus_turnover": "This bonus is for all members who played games and their turnover amount meets the conditions bonus.\nWeekly the system will calculate the bonus amount and give it to them (add to member balance) at the selected time.\nBonus amount = Weekly Game Provider Turnover of member * Rate %",
  "daily": "daily",
  "weekly": "weekly",
  "monthly": "monthly",
  "Turnover amount of new member must be from": "Turnover amount of new member must be from",
  "desBonusCashback": "This bonus is for all members who played games and their win loss (or turnover) amount meets the conditions bonus.\nWeekly the system will calculate the bonus amount and give it to them (add to member balance) at the selected time.\nBonus amount = abs(Weekly Game Provider Win loss (or Turnover) of member) * Rate %",
  "Min Amount": "Jumlah Min",
  "Min Turnover": "Min Turnover",
  "Max Bonus": "Max Bonus",
  "Every Monday": "Setiap Senin",
  "Every Tuesday": "Setiap Selasa",
  "Every Wednesday": "Setiap Rabu",
  "Every Thursday": "Setiap Kamis",
  "Every Friday": "Setiap Jumat",
  "Every Saturday": "Setiap Sabtu",
  "Every Sunday": "Setiap Minggu",
  "CONDITION TO GET BONUS": "CONDITION TO GET BONUS",
  "CONDITION TO DO WD": "CONDITION TO DO WD",
  "Bonus Cashback": "Cashback Bonus",
  "By Turnover": "By Turnover",
  "By WinLose": "By WinLose",
  "Base by Turnover (* Deposit)": "Base by Turnover (* Deposit)",
  "Base by Turnover (* (Deposit + Bonus))": "Base by Turnover (* (Deposit + Bonus))",
  "Manual Freebet": "Freebet Manual",
  "Include E-Money": "Include E-Money",
  "Manual Withdrawal": "Penarikan Manual",
  "Manual Deposit": "Setoran Manual",
  "Pgsoft": "PG Soft",
  "PG Soft Transaction": "Transaksi PG Soft",
  "PG Soft Referral": "Referal PG Soft",
  "GameTransferInAmount": "Game Transfer In: ({{amount}})",
  "GameTransferOutAmount": "Game Transfer Out: ({{amount}})",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "Deposit/Withdraw": "Deposit/Withdraw",
  "PGSoft Transaction": "Transaksi PG Soft",
  "PGSoft Referral": "Referal PG Soft",
  "NoteFreebetBonusGame": "Note: The games don't have Turnover info: CQ9 Fishing games, Spadegaming Table Games and Fishing Games",
  "NoteCleaningData": "(Riwayat Permainan Hanya Tersedia Selama 3 Bulan)",
  "Period Start From": "Period Start From",
  "Fake first 10 result numbers": "Fake first 10 result numbers",
  "InputNumberdigits": "Input {{length}} digits",
  "PGSoft Referral": "Referal PG Soft",
  "TTG": "TTG",
  "TTG Transaction": "Transaksi TTG",
  "TTG Referral": "Referal TTG",
  "GameBonusAmount": "Game Bonus: ({{amount}})",
  "GameBetAmount": "Game Bet: ({{amount}})",
  "GameCancelledAmount": "Game CANCELLED: ({{amount}})",
  "GameChangedAmount": "Game Changed: ({{amount}})",
  "GameDrawAmount": "Game Draw: ({{amount}})",
  "GameLoseAmount": "Game Lose: ({{amount}})",
  "GameVoidAmount": "Game Void: ({{amount}})",
  "GameWonAmount": "Game Won: ({{amount}})"
}
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/vi';

import Login from './views/Pages/Login'
import DefaultLayout from './containers/DefaultLayout'
import i18n from './i18n';

export const routes = (currentUser) => {
  var lang = currentUser === null ? 'vi' : currentUser.language;
  const userProfile = JSON.parse(localStorage.user);
  moment.locale('en')
  const promise =  i18n.changeLanguage(lang).then(() => {
    return i18n.loadNamespaces('menu') // This will return a promise
    .then(() => {
      const privateRoutes = [
        {
          path: '/',
          exact: true,
          component: () => <Redirect to="/home/dashboard" />
        },
        {
          component: DefaultLayout,
          routes: [
            // ------------------------------------------------ Home ------------------------------------------
            {
              path: '/home',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Home'),
              exact: true,
              component: () => <Redirect to="/home/dashboard" />
            },
            {
              path: '/home/dashboard',
              name: i18n.t('menu:Dashboard'),
              exact: true,
              component: lazy(() => import('./views/Dashboard'))
            },
            // ------------------------------------------------ Home ------------------------------------------
            // ------------------------------------------------ Profile ------------------------------------
            {
              path: "/profile",
              name: i18n.t("menu:Edit Profile"),
              exact: true,
              component: lazy(() => import('./views/Profile/ProfileEdit'))
            },
            // ------------------------------------------------ Home ------------------------------------------
            // ------------------------------------------------ Profile ------------------------------------
            {
              path: "/settings/games",
              name: i18n.t("Game Settings"),
              exact: true,
              component: lazy(() => import('./views/GameSetting/GameSetting'))
            },
            {
              path: "/settings/limitline",
              name: i18n.t("menu:Limit Line"),
              exact: true,
              component: lazy(() => import('./views/GameSetting/LimitLine'))
            },
             // ------------------------------------------------ Pools Settings ---------------------------------------
             {
              path: '/settings/pools/create',
              name: i18n.t('Create Pools'),
              exact: true,
              component: lazy(() => import('./views/Pools/PoolCreate'))
            },
            {
              path: '/settings/pools',
              name: i18n.t('menu:Pools List'),
              exact: true,
              component: lazy(() => import('./views/Pools/PoolPages'))
            },
            {
              path: '/settings/periods-offline-online',
              name: i18n.t('menu:Pools List'),
              exact: true,
              component: lazy(() => import('./views/Pools/PeriodsOfflineOnline'))
            },
            {
              path: '/settings/pools/:pool_id/edit',
              name: i18n.t('menu:Edit Pool'),
              exact: true,
              component: lazy(() => import('./views/Pools/PoolEdit'))
            },
            // ------------------------------------------------ Profile ------------------------------------
            // ------------------------------------------------ Sub Account -----------------------------------
            {
              path: '/sub',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Sub Account'),
              exact: true,
              component: () => <Redirect to="/sub/list" />
            },
            {
              path: '/sub/create',
              name: i18n.t('menu:Create Sub Account'),
              exact: true,
              component: lazy(() => import('./views/SubAccount/SubAccountCreate'))
            },
            {
              path: '/sub/list',
              name: i18n.t('menu:Sub Account List'),
              exact: true,
              component: lazy(() => import('./views/SubAccount/SubAccountList'))
            },
            {
              path: "/sub/list/:id/edit",
              name: i18n.t("menu:Edit Sub Account"),
              exact: true,
              component: lazy(() => import('./views/SubAccount/SubAccountEdit'))
            },
            // ------------------------------------------------ Sub Account ------------------------------------

            // ------------------------------------------------ Company ----------------------------------------
            {
              path: '/company',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Company'),
              exact: true,
              component: () => <Redirect to="/company/list" />
            },
            {
              path: '/company/create',
              name: i18n.t('menu:Create Company'),
              exact: true,
              component: lazy(() => import('./views/Companies/CompanyCreate')),
            },
            {
              path: '/company/list',
              name: i18n.t('menu:Company List'),
              exact: true,
              component: lazy(() => import('./views/Companies/CompanyList')),
            },
            {
              path: ["/company/list/:company_id/pools", "/whitelabel/list/:company_id/pools"],
              name: i18n.t('menu:Pools List'),
              exact: true,
              component: lazy(() => import('./views/Companies/PoolsListCompany')),
            },
            {
              path: "/company/list/:id/edit",
              name: i18n.t("menu:Edit Company"),
              exact: true,
              component: lazy(() => import('./views/Companies/CompanyEdit'))
            },
            {
              path: ['/company/list/:company_id/member', '/whitelabel/list/:company_id/member'],
              name: i18n.t('menu:Member List'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberList')),
            },
            {
              path: ["/company/list/:company_id/member/:member_id/downline-list", "/whitelabel/list/:company_id/member/:member_id/downline-list"],
              name: i18n.t('Referral List'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberDownlineList')),
            },
            {
              path: ["/company/list/:company_id/member/:member_id/transaction-history", "/whitelabel/list/:company_id/member/:member_id/transaction-history"],
              name: i18n.t('menu:Transaction History'),
              exact: true,
              component: lazy(() => import('./views/TransactionHistory/TransactionHistory')),
            },

            //========================= Bank Comapny ========================//
            {
              path: '/bank-management',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Bank'),
              exact: true,
              component: () => <Redirect to="/bank-management/account/list" />
            },
            {
              path: '/bank-management/account/create',
              name: i18n.t('menu:Create Bank Account'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankAccountCreate.js'))
            },
            {
              path: '/bank-management/account/list',
              name: i18n.t('menu:Bank Account List'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankAccountList/BankAccountList'))
            },
            {
              path: '/bank-management/bank-group',
              name: i18n.t('Bank Group'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankGroupList.js'))
            },
            {
              path: '/bank-management/limit-setting',
              name: i18n.t('menu:Bank Limit Setting'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankLimitSetting/BankLimitSetting'))
            },
            {
              path: '/settings/limit_transfer_bets',
              name: i18n.t('menu:Limit Transfer Bets'),
              exact: true,
              component: lazy(() => import('./views/LimitTransferBets/LimitTransferBets'))
            },
            {
              path: '/bank-management/display-bank',
              name: i18n.t('Display Bank List'),
              exact: true,
              component: lazy(() => import('./views/Bank/DisplayBankList.js'))
            },
            {
              path: '/bank-management/bank-balance',
              name: i18n.t('menu:Bank Balance'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankBalance.js'))
            },
            {
              path: '/bank-management/bank-account-banned',
              name: i18n.t('menu:Bank Account Banned'),
              exact: true,
              component: lazy(() => import('./views/Bank/BannedBankAccountList/BannedBankAccountList'))
            },
            {
              path: '/company/transfer-log/list',
              name: i18n.t('menu:Bank Transfer Log'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankTransferLogs/BankTransferLogs'))
            },
            // ------------------------------------------------ Requests Management ----------------------------------------
            {
              path: '/request-management/deposit',
              name: i18n.t('Deposit Request List'),
              exact: true,
              component: lazy(() => import('./views/requestManagement/ListRequestManagement'))
            },
            {
              path: '/request-management/withdraw',
              name: i18n.t('Withdraw Request List'),
              exact: true,
              component: lazy(() => import('./views/requestManagement/ListRequestManagement'))
            },

            {
              path: '/request-management/:request_type/:member_id/transaction-history',
              name: i18n.t('menu:Transaction History'),
              exact: true,
              component: lazy(() => import('./views/TransactionHistory/TransactionHistory')),
            },

            // ------------------------------------------------ Bank ----------------------------------------
            {
              path: '/bank/list',
              name: i18n.t('menu:BankList'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankList/BankList'))
            },

            {
              path: "/bank/list/:id/edit",
              name: i18n.t("EditArg", {arg: i18n.t("Bank")}),
              exact: true,
              component: lazy(() => import('./views/Bank/BankEdit'))
            },
            {
              path: '/bank/create',
              name: i18n.t('menu:Create Bank'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankCreate'))
            },
            {
              path: '/bank-management/transfer-log/list',
              name: i18n.t('menu:Bank Transfer Log'),
              exact: true,
              component: lazy(() => import('./views/Bank/BankTransferLogs/BankTransferLogs'))
            },
            {
              path: "/others_payment_method/list/:id/edit",
              name: i18n.t("EditArg", {arg: i18n.t("Bank")}),
              exact: true,
              component: lazy(() => import('./views/Bank/BankList/OtherPaymentMethodsList/OtherPaymentMethodsEdit'))
            },
            // ------------------------------------------------ Bank ----------------------------------------

            // ------------------------------------------------ Result ----------------------------------------

            {
              path: '/results/list',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Result List'),
              exact: true,
              component: lazy(() => import('./views/Results/ResultList/ResultList')),
            },
            {
              path: '/results/scan',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Scan Result'),
              exact: true,
              component: lazy(() => import('./views/Results/ScanResult/ScanResult')),
            },
            {
              path: '/results/analyze',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Analyze Result'),
              exact: true,
              component: lazy(() => import('./views/Results/ScanResult/AnalyzeResult')),
            },
            // ------------------------------------------------ Member -----------------------------------------
            {
              path: '/member',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Member'),
              exact: true,
              component: () => <Redirect to="/member/list" />
            },
            {
              path: '/member/create',
              name: i18n.t('menu:Create Member'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberCreate')),
            },
            {
              path: '/member/list',
              name: i18n.t('menu:Member List'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberList')),
            },
            {
              path: '/member/list/:id/edit',
              name: i18n.t('menu:Member Edit'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberEdit')),
            },
            {
              path: '/member/list/:member_id/downline-list',
              name: i18n.t('Referral List'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberDownlineList')),
            },
            // ------------------------------------------------ Transaction -----------------------------------------
            {
              path: '/member/list/:member_id/transaction-history',
              name: i18n.t('menu:Transaction History'),
              exact: true,
              component: lazy(() => import('./views/TransactionHistory/TransactionHistory')),
            },
            {
              path: '/global-member',     // This is for breadcrumb. Fake route
              name: i18n.t('menu:Global Member'),
              exact: true,
              component: lazy(() => import('./views/GlobalMembers/GlobalMembers')),
            },
            {
              path: '/global-member/list/:member_id/downline-list',
              name: i18n.t('Referral List'),
              exact: true,
              component: lazy(() => import('./views/Members/MemberDownlineList')),
            },
            {
              path: '/global-member/list/:member_id/transaction-history',
              name: i18n.t('menu:Transaction History'),
              exact: true,
              component: lazy(() => import('./views/TransactionHistory/TransactionHistory')),
            },
            // {
            //   path: '/member/:member_id/transaction-history/:bet_id',
            //   name: i18n.t('menu:Transaction Detail'),
            //   exact: true,
            //   component: lazy(() => import('./views/TransactionHistory/TransactionDetail')),
            // },

            // ------------------------------------------------ Bets -----------------------------------------
            {
              path: '/bets/table',
              name: i18n.t('menu:Bets Table'),
              exact: true,
              component: lazy(() => import('./views/Bets/BetsTable/BetsTable')),
            },
            {
              path: '/bets/list',
              name: i18n.t('menu:Bets List'),
              exact: true,
              component: lazy(() => import('./views/Bets/BetsList/BetsList')),
            },
            {
              path: '/bets/transferred/list',
              name: i18n.t('menu:Transfer Bets'),
              exact: true,
              component: lazy(() => import('./views/TransferBets/TransferBets')),
            },

            // ------------------------------------------------ Report -----------------------------------------
            {
              path: '/reports/referral_report',
              name: i18n.t('menu:Referral Report'),
              exact: true,
              component: lazy(() => import('./views/Report/ReferralReport/ReferralReport')),
            },
            {
              path: '/reports/winloss_report',
              name: i18n.t('menu:Win Loss Report'),
              exact: true,
              component: (userProfile.account_type === 'admin' || userProfile.account_type === 'adminsub' || userProfile.account_type === 'master whitelabel') ? lazy(() => import('./views/Report/WinLossReport/WinLossReportAdmin')) : lazy(() => import('./views/Report/WinLossReport/WinLossReportCompany')),
            },

            {
              path: '/reports/agent_daily_report',
              name: i18n.t('menu:Agent Daily Report'),
              exact: true,
              component: lazy(() => import('./views/Report/WinLossReport/AgentDailyWinLossReport')),
            },

            {
              path: '/settings/winloss_report/members',
              name: i18n.t('menu:Win Loss Report Members'),
              exact: true,
              component: lazy(() => import('./views/Report/WinLossReport/MemberWinLoss')),
            },
            {
              path: '/reports/winloss_report/member/:member_id/transaction-history',
              name: i18n.t('menu:Win Loss Report Members'),
              exact: true,
              component: lazy(() => import('./views/Report/WinLossReport/WinLossTransactionHistory')),
            },
            {
              path: '/reports/lost_money_report',
              name: i18n.t('menu:Lost Money Report'),
              exact: true,
              component: lazy(() => import('./views/Report/LostMoneyReport/LostMoneyReport')),
            },

            // ------------------------------------------------ Settings ---------------------------------------
            {
              path: '/settings',
              name: i18n.t('menu:Settings'),
              exact: true,
              component: () => <Redirect to="/settings/referral-rate"/>
            },
            {
              path: '/settings/referral-rate',
              name: i18n.t('menu:Referral Rate'),
              exact: true,
              component: lazy(() => import('./views/Settings/Referral/Referral'))
            },
            {
              path: '/settings/commission',
              name: i18n.t('menu:Commission'),
              exact: true,
              component: lazy(() => import('./views/Settings/CompanyComissionContainer'))
            },
            {
              path: '/settings/togel_commission',
              name: i18n.t('menu:Togel Commission'),
              exact: true,
              component: lazy(() => import('./views/Settings/TogelComissionContainer'))
            },

             // ------------------------------------------------ Action log ---------------------------------------
            {
              path: '/action-log/c-company-logs',
              name: i18n.t('menu:Company Log'),
              exact: true,
              component: lazy(() => import('./views/Log/LogList'))
            },
            {
              path: '/action-log/admin-logs',
              name: i18n.t('menu:Admin Log'),
              exact: true,
              component: lazy(() => import('./views/Log/LogList'))
            },
            {
              path: '/action-log/member-logs',
              name: i18n.t('menu:Member Log'),
              exact: true,
              component: lazy(() => import('./views/Log/LogList'))
            },
            {
              path: '/action-log/master-whitelabel-logs',
              name: i18n.t('menu:Master Log'),
              exact: true,
              component: lazy(() => import('./views/Log/LogList'))
            },

            {
              path: '/action-log/c-whitelabel-logs',
              name: i18n.t('menu:Whitelabel Log'),
              exact: true,
              component: lazy(() => import('./views/Log/LogList'))
            },
            // ------------------------------------------------ Customization ---------------------------------------
            {
              path: '/ctmzt/site_config',
              name: i18n.t('menu:Site Config'),
              exact: true,
              component: lazy(() => import('./views/Customization/SiteConfig/SiteConfig'))
            },
            {
              path: '/ctmzt/running_text',
              name: i18n.t('menu:Running Text'),
              exact: true,
              component: lazy(() => import('./views/Customization/RunningText/RunningText'))
            },
            {
              path: '/ctmzt/web_page',
              name: i18n.t('menu:Webpage'),
              exact: true,
              component: lazy(() => import('./views/Customization/Webpage/Webpage'))
            },
            {
              path: '/ctmzt/customer_service',
              name: i18n.t('menu:Customer Service'),
              exact: true,
              component: lazy(() => import('./views/Customization/CustomerService/CustomerService'))
            },
            {
              path: '/ctmzt/information',
              name: i18n.t('menu:Information'),
              exact: true,
              component: lazy(() => import('./views/Customization/Information/Information'))
            },
            {
              path: '/ctmzt/promotion',
              exact: true,
              component: () => <Redirect to="/ctmzt/promotion/list" />
            },
            {
              path: '/ctmzt/promotion/list',
              name: i18n.t('menu:Promotion'),
              exact: true,
              component: lazy(() => import('./views/Customization/Promotion/Promotion'))
            },
            {
              path: '/ctmzt/promotion/add',
              name: i18n.t('menu:Add Promotion'),
              exact: true,
              component: lazy(() => import('./views/Customization/Promotion/PromotionAdd'))
            },
            {
              path: '/ctmzt/global_promotion',
              exact: true,
              component: () => <Redirect to="/ctmzt/global_promotion/list" />
            },

            {
              path: '/ctmzt/global_promotion/list',
              name: i18n.t('menu:Global Promotion'),
              exact: true,
              component: lazy(() => import('./views/Customization/GlobalPromotion'))
            },
            {
              path: '/ctmzt/global_promotion/add',
              name: i18n.t('menu:Add Global Promotion'),
              exact: true,
              component: lazy(() => import('./views/Customization/GlobalPromotion/PromotionAdd'))
            },

            {
              path: '/ctmzt/banner',
              name: i18n.t('menu:Banners'),
              exact: true,
              component: lazy(() => import('./views/Customization/Banners/Banners'))
            },
            {
              path: '/ctmzt/global_banner',
              name: i18n.t('menu:Global Banner'),
              exact: true,
              component: lazy(() => import('./views/Customization/Banners/GlobalBanners'))
            },
            {
              path: '/ctmzt/template',
              name: i18n.t('menu:Templates List'),
              exact: true,
              component: lazy(() => import('./views/Customization/TemplatesList/TemplatesList'))
            },

            {
              path: '/ctmzt/app_notication',
              name: i18n.t('menu:App Notification'),
              exact: true,
              component: ()=> <Redirect to="/ctmzt/app_notication/list" />
            },
            {
              path: '/ctmzt/app_notication/list',
              name: i18n.t('menu:Notification List'),
              exact: true,
              component: lazy(() => import('./views/Customization/Notifications'))
            },
            {
              path: '/ctmzt/app_notication/add',
              name: i18n.t('menu:Notification Add'),
              exact: true,
              component: lazy(() => import('./views/Customization/Notifications/NotificationAdd'))
            },
            {
              path: '/ctmzt/app_notication/:id/edit',
              name: i18n.t('menu:Notification Edit'),
              exact: true,
              component: lazy(() => import('./views/Customization/Notifications/NotificationEdit'))
            },

            
            // // ------------------------------------------------ Bonus ---------------------------------------
            {
              path: '/bonus/free_bet/list',
              name: i18n.t('menu:Free Bet'),
              exact: true,
              component: lazy(() => import('./views/Bonus/FreeBet/FreeBet'))
            },
            {
              path: '/bonus/free_bet/list/:bet_id/edit',
              name: i18n.t('menu:Edit Free Bet'),
              exact: true,
              component: lazy(() => import('./views/Bonus/FreeBet/EditFreeBet'))
            },
            {
              path: '/bonus/free_bet/report',
              name: i18n.t('menu:Free Bet Report'),
              exact: true,
              component: lazy(() => import('./views/Bonus/FreeBet/ReportFreeBet'))
            },
            {
              path: '/bonus/upload_bonus',
              name: i18n.t('menu:Upload Bonus'),
              exact: true,
              component: lazy(() => import('./views/Bonus/UploadBonus/UploadBonus'))
            },
            {
              path: '/bonus/bonus_report',
              name: i18n.t('menu:Bonus Report'),
              exact: true,
              component: lazy(() => import('./views/Bonus/BonusReport/BonusReport'))
            },

            {
              path: '/bonus/tracking',
              name: i18n.t('menu:Bonus Tracking'),
              exact: true,
              component: lazy(() => import('./views/Bonus/BonusReport/BonusTrackingReport'))
            },
            // ------------------------------------------------ Memo ---------------------------------------
            {
              path: '/memo',
              name: i18n.t('menu:Memo'),
              exact: true,
              component: lazy(() => import('./views/Memo/Memo'))
            },
            {
              path: '/auto-memo',
              name: i18n.t('menu:Auto Memo'),
              exact: true,
              component: lazy(() => import('./views/Memo/AutoMemo/AutoMemo'))
            },
            // ------------------------------------------------ Whitelabel ----------------------------------------
            {
              path: '/whitelabel/create',
              name: i18n.t('menu:Create Whitelabel'),
              exact: true,
              component: lazy(() => import("./views/Whitelabel/WhitelabelCreate"))
            },
            {
              path: '/whitelabel/list',
              name: i18n.t('menu:Whitelabel List'),
              exact: true,
              component: lazy(() => import("./views/Whitelabel/WhitelabelList"))
            },
            {
              path: "/whitelabel/list/:id/edit",
              name: i18n.t("menu:Edit Whitelabel"),
              exact: true,
              component: lazy(() => import('./views/Whitelabel/WhitelabelEdit'))
            },
            {
              path: "/block-numbers",
              name: i18n.t("menu:Edit Whitelabel"),
              exact: true,
              component: lazy(() => import('./views/BlockNumbers/BlockNumbers'))
            },
             // ------------------------------------------------ Master Whitelabel ----------------------------------------
             {
              path: '/master_whitelabel/create',
              name: i18n.t('menu:Create Master Account'),
              exact: true,

              component: lazy(() => import("./views/MasterWhitelabel/MasterWhitelabelCreate"))
            },
            {
              path: '/master_whitelabel/list',
              name: i18n.t('menu:Master Account List'),
              exact: true,
              component: lazy(() => import("./views/MasterWhitelabel/MasterWhitelabelList"))
            },
            {
              path: "/master_whitelabel/list/:id/edit",
              name: i18n.t("Edit Master Account"),
              exact: true,
              component: lazy(() => import('./views/MasterWhitelabel/MasterWhitelabelEdit'))
            },
            {
              path: "/master_whitelabel/list/:master_id/whitelabel/downline-list",
              name: i18n.t('Downline List'),
              exact: true,
              component: lazy(() => import('./views/MasterWhitelabel/MasterDownlineList')),
            },
            // ------------------------------------------------ Provider daily report ---------------------------------------
            {
              path: ["/daily_report/ion", "/daily_report/spade", "/daily_report/habanero", "/daily_report/pragmatic", "/daily_report/pragmaticplaylive", "/daily_report/joker"],
              name: i18n.t("menu: Daily Reports"),
              exact: true,
              component: lazy(() => import("./views/ProviderDailyReport/ProviderDailyReport"))
            },
            // ------------------------------------------------ else ---------------------------------------
            {
              path: ['/', '/login'],
              exact: true,
              component: () => <Redirect to="/home/dashboard" />
            },
            {
              path: "/home/device-report",
              name: i18n.t('menu:Device Report'),
              exact: true,
              component: lazy(() => import('./views/Dashboard/DeviceReport/DeviceReport'))
            },
            {
              path: '/*',
              component: lazy(() => import('./views/Pages/Page404/Page404.js'))
            },
          ]
        }
      ]
      return privateRoutes
    });
  });
  return promise
}

export const loginRoutes = [
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    component: () => <Redirect to="/login" />
  }
]

export default {
  "Action Log": "Nhật ký hành động",
  "Add Promotion": "Thêm chương trình khuyến mãi",
  "Admin Log": "Nhật ký Admin",
  "Admin Menu": "Bảng kê Admin",
  "Analyze Result": "Phân tích",
  "Auto Memo": "Ghi nhớ tự động",
  "Bank": "Ngân Hàng",
  "Bank Account Banned": "Khoá T/K Ngân Hàng",
  "Bank Account List": "D/S T/K Ngân Hàng",
  "Bank Account Management": "Quản lý T/K Ngân hàng",
  "Bank Balance": "Tiền Dự Ngân Hàng",
  "Bank Group": "Nhóm Ngân Hàng",
  "Bank Limit Setting": "Cài đặt giới hạn",
  "Bank List": "D/S Ngân Hàng",
  "Bank Management": "Quản Lý Ngân Hàng",
  "Bank Transfer Log": "Sổ Giao Dịch Ngân Hàng",
  "BankList": "Danh sách ngân hàng",
  "Banner": "Banner",
  "Bets": "Cược",
  "Bets List": "DS bet",
  "Bets Table": "Bảng cược",
  "Bets Transferred List": "Danh sách chuyển cược",
  "Bonus": "Thưởng",
  "Bonus Report": "Báo cáo thưởng",
  "Code": "Vietnamese",
  "Commission": "Hoa hồng",
  "Company": "Công ty",
  "Company Commission": "Hoa hồng",
  "Company List": "Danh sách công ty",
  "Company Log": "Nhật ký Công ty",
  "Company Menu": "Bảng điều khiển công ty",
  "Create Bank": "Tạo Ngân Hàng",
  "Create Bank Account": "Tạo TK Ngân hàng",
  "Create Company": "Tạo công ty",
  "Create Member": "Tạo Người Chơi",
  "Create Pool Success": "Tạo pool thành công!",
  "Create Sub Account": "Tạo tài khoản phụ",
  "Create Sub Admin": "Tạo Sub Admin",
  "Create Sub Company": "Tạo Sub Công ty",
  "Create Whitelabel": "Tạo Whitelabel",
  "Create whitelabel": "Tạo Whitelabel",
  "Customer Service": "Dịch vụ hỗ trợ",
  "Customization": "Tuỳ chỉnh",
  "Daily Report": "Báo cáo hàng ngày",
  "Daily Reports": "Báo cáo hàng ngày",
  "Dashboard": "Bảng điều khiển",
  "Deactive pool success": "Vô hiệu hoá pool thành công!",
  "Deposit": "Gửi tiền",
  "Device Report": "Báo cáo thiết bị",
  "Display Bank List": "D/S Ngân Hàng Phô Trương",
  "Downline List": "Danh sách tuyến dưới",
  "Edit Company": "Chỉnh sửa công ty",
  "Edit Pool": "Sửa đài",
  "Edit Profile": "Chỉnh sửa thông tin tài khoản",
  "Edit Sub Account": "Chỉnh sửa tài khoản phụ",
  "Edit Whitelabel": "Sửa Whitelabel",
  "Free Bet": "Free Bet vn",
  "Free Bet Report": "Free Bet Report vn",
  "Game Settings": "Sửa game setting",
  "Get Bank": "Lấy Ngân hàng",
  "Get Bank Account": "Lấy Tk Ngân hàng",
  "Global Member": "Truy cập danh sách tất cả member",
  "Home": "Trang chủ",
  "Information": "Thông tin",
  "Limit Line": "Giới hạn đặt cược",
  "Limit Setting": "Chỉnh Giới Hạn",
  "Limit Transfer Bets": "Giới hạn chuyển nhượng",
  "Log": "Bảng hành động",
  "Manage Bank": "Quản lý Ngân hàng",
  "Member": "Người Chơi",
  "Member Edit": "Sửa Người Chơi",
  "Member List": "D/S Người Chơi",
  "Member Log": "Nhật ký Thành viên",
  "Memo": "Ghi nhớ",
  "Pools List": "DS Đài",
  "Promotion": "Khuyến mãi",
  "Referral Rate": "Tiền giới thiệu",
  "Referral Report": "Báo cáo về giới thiệu",
  "Report": "Báo cáo",
  "Reports": "Báo cáo",
  "Request Manage": "Quản Lý Yêu Cầu",
  "Result List": "DS kết quả",
  "Results": "Danh sách kết quả",
  "Running Text": "Văn bản chạy",
  "Scan Result": "Quét",
  "Settings": "Cài đặt",
  "Settings Pools": "Cài đặt Pools",
  "Site Config": "Cấu hình trang web",
  "Sub Account": "Tài khoản phụ",
  "Sub Account List": "Danh sách tài khoản phụ",
  "Sub Admin List": "D/S Sub Admin",
  "Sub Company List": "D/S Sub Công ty",
  "Template": "Danh sách giao diện mẫu",
  "Templates List": "Danh sách giao diện mẫu",
  "Transaction History": "Lịch sử giao dịch",
  "Transfer Bets": "Chuyển Nhượng Bets",
  "Transfer Log": "Nhật ký chuyển khoản",
  "Upload Bonus": "Tải file bonus",
  "Web Page": "Trang web",
  "Webpage": "Trang web",
  "Whitelabel List": "Danh sách Whitelabel",
  "Whitelabel Log": "Nhật ký Whitelabel",
  "Win Loss Report": "Báo cáo thắng/thua",
  "Win Loss Report Members": "Báo cáo thắng/thua của người chơi",
  "Withdraw": "Rút tiền",
  "bet": "Cược",
  "result-list": "Danh sách kết quả",
  "update": "cập nhật",
  "Master Log": "Nhật ký Master",
  "Master": "Super Master",
  "Create Master Account": "Tạo SM",
  "Master Account List": "D/S SM",
  "Global Banner":	"Banner Chung",
  "Global Promotion":	"Khuyến Mãi Chung",
  "Add Global Promotion": "Thêm khuyến mãi chung",
  "App Notification": "Thông báo ứng dụng",
  "Notification List": "DS Thông báo ứng dụng",
  "Notification Add": "Tạo mới thông báo",
  "Notification Edit": "Cập nhật thông báo",
  "Togel Commission": "Hoa hồng Togel",
  "Agent Daily Report": "Báo cáo Agent hàng ngày",
  "CQ9": "CQ9",
  "Joker": "JokerGaming",
  "Spade": "SpadeGaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "Microgaming": "Microgaming",
  "TTG": "Top Trend Gaming",
  "Bonus Tracking": "Bonus Tracking"
}
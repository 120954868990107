import { createStore, applyMiddleware, compose } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './reducers';

const initialState = {};
const enhancers = [];

const config = {
  blacklist: [PERSIST],
};
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['status']
}
const middlewares = [createStateSyncMiddleware(config)];
const persistedReducer = persistReducer(persistConfig, rootReducer)

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

/** Setup redux-devtools **/
// if (process.env.NODE_ENV !== "production") {
//   // eslint-disable-next-line no-underscore-dangle
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension());
//   }
// }

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers,
);
/** Setup redux-devtools - END **/

export const store = createStore(persistedReducer, initialState, composedEnhancers)
export const persistor = persistStore(store);

initStateWithPrevTab(store);

export default {
  "Action Log": "Action Log",
  "Add Promotion": "Add Promotion",
  "Admin Log": "Admin Logs",
  "Admin Menu": "Admin Menu",
  "Analyze Result": "Analyze Result",
  "Auto Memo": "Auto Memo",
  "Bank": "Bank",
  "Bank Account Banned": "Banned Bank Account",
  "Bank Account List": "Bank Account List",
  "Bank Account Management": "Bank Account Management",
  "Bank Balance": "Bank Balance",
  "Bank Group": "Bank Group",
  "Bank Limit Setting": "Deposit/Withdrawal Limitation",
  "Bank List": "Bank List",
  "Bank Management": "Bank Management",
  "Bank Transfer Log": "Bank Transfer Logs",
  "BankList": "Bank List",
  "Banner": "Banners",
  "Bets": "Bets",
  "Bets List": "Bets List",
  "Bets Table": "Bets Table",
  "Bonus": "Bonus",
  "Bonus Report": "Bonus Report",
  "Code": "English",
  "Commission": "Commission",
  "Company": "Company",
  "Company Commission": "Commission",
  "Company List": "Company List",
  "Company Log": "Company Logs",
  "Company Menu": "Company Menu",
  "Create Bank": "Create Bank",
  "Create Bank Account": "Create Bank Account",
  "Create Company": "Create Company",
  "Create Member": "Create Member",
  "Create Sub Account": "Create Sub Account",
  "Create Sub Admin": "Create Sub Account",
  "Create Sub Company": "Create Sub Account",
  "Create Whitelabel": "Create Whitelabel",
  "Customer Service": "Customer Service",
  "Customization": "Customization",
  "Daily Reports": "Providers Daily Report",
  "Dashboard": "Dashboard",
  "Deposit": "Deposit",
  "Device Report": "Device Report",
  "Display Bank List": "Display Bank List",
  "Downline List": "Downline List",
  "Edit Company": "Edit Company",
  "Edit Pool": "Edit Pool",
  "Edit Profile": "Edit Profile",
  "Edit Sub Account": "Edit Sub Account",
  "Edit Whitelabel": "Edit Whitelabel",
  "Free Bet": "Free Bet",
  "Free Bet Report": "Free Bet Report",
  "Game Settings": "Games",
  "Get Bank Account": "Get Bank Account",
  "Global Member": "Global Member List",
  "Home": "Home",
  "Information": "Information",
  "Limit Line": "Limit Line",
  "Limit Setting": "Limit Settings",
  "Limit Transfer Bets": "Limit Transfer Bets",
  "Log": "Logs",
  "Member": "Member",
  "Member Edit": "Edit Member",
  "Member List": "Member List",
  "Member Log": "Member Logs",
  "Memo": "Memo",
  "Pools List": "Pools List",
  "Promotion": "Promotion",
  "Referral Rate": "Referral Rate",
  "Referral Report": "Referral Report",
  "Reports": "Reports",
  "Request Manage": "Requests Management",
  "Result List": "Result List",
  "Results": "Results",
  "Running Text": "Running Text",
  "Scan Result": "Scan Result",
  "Settings": "Settings",
  "Site Config": "Site Config",
  "Sub Account": "Sub Account",
  "Sub Account List": "Sub Account List",
  "Sub Admin List": "Sub Account List",
  "Sub Company List": "Sub Account List",
  "Template": "Templates List",
  "Transaction History": "Transaction History",
  "Transfer Bets": "Transfer Bets",
  "Transfer Log": "Transfer Logs",
  "Upload Bonus": "Upload Bonus",
  "Web Page": "Web Page",
  "Whitelabel List": "Whitelabel List",
  "Whitelabel Log": "Whitelabel Logs",
  "Win Loss Report": "Win Loss Report",
  "Win Loss Report Members": "Members Win Loss Report",
  "Withdraw": "Withdraw",
  "Master Log": "Master Logs",
  "Master": "Master",
  "Create Master Account": "Create Master Account",
  "Master Account List": "Master Account List",
  "Global Banner":	"Global Banners",
  "Global Promotion":	"Global Promotion",
  "Add Global Promotion": "Add Global Promotion",
  "App Notification": "App Notification",
  "Notification List": "App Notification List",
  "Notification Add": "Add Notification",
  "Notification Edit": "Edit Notification",
  "Togel Commission": "Togel Commission",
  "Agent Daily Report": "Agent Daily Report",
  "CQ9": "CQ9",
  "Joker": "JokerGaming",
  "Spade": "SpadeGaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "Microgaming": "Microgaming",
  "TTG": "TTG",
  "Bonus Tracking": "Bonus Tracking"
}
export default {
  "Code": "English",
  "Error!": "Error!",
  "Special characters is not allowed": "Special characters is not allowed.",
  "account_name_too_long": "Account name too long",
  "already_in_that_status": "The status has already changed. Please refresh",
  "bank_account_belong_to_active_group": "Bank account belong to active group",
  "bank_account_not_belong_to_group": "Cannot activate a bank account with no group",
  "bank_not_found": "Bank not found",
  "can_not_withdraw_with_member_others_payment": "Can not withraw with member others payment",
  "confirm_password_mismatch": "Confirm Password mismatched",
  "deposit_greater_max": "Deposit amount too big",
  "deposit_less_min": "Deposit too small",
  "deposit_min_greater_equal_deposit_max": "Min deposit is greater than max deposit",
  "deposit_time_must_larger_than_previous": "Deposit times too small",
  "deposit_time_must_less_than_next": "Deposit times too big",
  "destination_to_send_memo_invalid": "Destination to send memo invalid",
  "duplicate_bank_code": "Duplicate bank initials",
  "duplicate_payment_account_no": "Duplicate account number",
  "duplicate_payment_provider": "Duplicate payment provider",
  "duplicate_pool_code": "Pools code already exists. Please use another one!",
  "duplicate_pool_name": "Pools name already exists. Please use another one!",
  "duplicate_username": "Username already exists. Please use another one!",
  "err_account_balance_insufficient_balance": "Account balance is not enough",
  "err_bet_tx_is_transfer_status_invalid": "The bet is canceled transfer already.",
  "err_bet_tx_is_transferred": "The bet is transferred already. Not available right now.",
  "err_bet_tx_not_running": "The bet transaction is not running",
  "err_block_number_duplicated": "Block number duplicated",
  "err_invalid_limit_line_value": "Invalid limit line value",
  "err_invalid_period": "Invalid Period",
  "err_period_already_offline": "Cannot update right now. The pool is closed already",
  "err_period_already_online": "Period Already Online",
  "err_period_cancel_wrong_period": "Invalid period. Please try again!",
  "err_period_has_closed": "Cannot update right now. The pool is closed already",
  "err_period_manager_cancelling": "The period is canceling result. Not available right now.",
  "err_period_manager_updating": "Period Manager Update",
  "err_period_not_found": "Period not found",
  "err_period_result_blank": "Result number not found",
  "err_period_result_updated": "The period is updating result. Not available right now.",
  "err_period_update_before_close_time": "Period update before close time",
  "err_period_update_wrong_period": "Invalid period. Please try again!",
  "err_pool_invalid": "Invalid Pool",
  "err_scan_bet_tx_invalid_number": "Invalid result number",
  "err_transaction_detail_not_found": "Transaction Detail not found",
  "field_can_not_be_blank": "Field cannot be blank",
  "file_not_found": "File not found",
  "file_too_large": "File too large (\u003e 2MB)",
  "form_validation_failed": "Form validation failed",
  "free_bet_amount_invalid": "Amount is invalid",
  "free_bet_can_not_edit_active_event": "Cannot edit an active bonus event",
  "free_bet_deposit_end_time_invalid": "Deposit Time End is invalid",
  "free_bet_event_name_invalid": "Event Name is invalid",
  "free_bet_event_type_invalid": "Freebet event type invalid",
  "free_bet_max_amount_invalid": "Max bonus amount is invalid",
  "free_bet_min_deposit_amount_invalid": "Min Deposit Amount is invalid",
  "inactive_account": "Oops! Your account has been inactivated. Please contact your upline to activate",
  "incorrect_password": "Incorrect password",
  "internal_error": "Internal Server Error",
  "invalid_action_type": "Invalid action",
  "invalid_amount": "Invalid amount",
  "invalid_bank_account_name": "Invalid bank account name",
  "invalid_bank_account_no": "Invalid bank account number",
  "invalid_bank_code": "Invalid bank code",
  "invalid_bank_name": "Invalid bank name",
  "invalid_commission": "Invalid commission",
  "invalid_company_name": "Invalid company name",
  "invalid_contact_number": "Invalid phone number",
  "invalid_current_password": "Invalid current password",
  "invalid_display_name": "Invalid name",
  "invalid_draw_day": "Invalid draw day",
  "invalid_email": "Invalid email",
  "invalid_file_format": "Invalid file format",
  "invalid_image_size": "Invalid Image Size",
  "invalid_image_type": "Invalid Image Type",
  "invalid_ip_address": "Incorrect IP Address",
  "invalid_max_number_of_digits": "Bank Account Number Length must greater than 0.",
  "invalid_member_list": "Invalid member list",
  "invalid_min_number_of_digits": "Bank Account Number Length must greater than 0.",
  "invalid_number_of_digits": "Bank Account Number Length must greater than 0.",
  "invalid_number_of_digits_bank_account_no": "Invalid Number of digits bank account number",
  "invalid_number_of_digits_payment_account_no": "Invalid numbers of digits payment account no",
  "invalid_package_name": "Invalid package name",
  "invalid_password": "Invalid password",
  "invalid_payment_account_name": "Invalid payment account name",
  "invalid_payment_account_no": "Invalid payment account number",
  "invalid_payment_provider": "Invalid payment provider",
  "invalid_pool_code": "Invalid pools code",
  "invalid_pool_name": "Invalid pools name",
  "invalid_recaptcha": "Invalid captcha",
  "invalid_referral_rate": "Invalid referral rate",
  "invalid_template_key": "Invalid template key",
  "invalid_username": "Invalid username",
  "ip_not_allowed": "Access Denied: Log in attempt from unknown IP",
  "locked_account": "Oops! Your account has been locked. Please contact your upline to unlock",
  "logo_not_found": "Logo not found",
  "member_not_found": "Member not found",
  "min_number_of_digits_must_less_than_or_equal_max_number_of_digits": "Min number of digits must less than or equal max number of digits",
  "missing_reason": "Reason cannot be blank",
  "no_favicon": "No favicon",
  "no_logo": "No logo",
  "no_permission": "No permission",
  "not_enough_balance_bank_account": "Bank account balance is not enough",
  "not_found": "Not found",
  "payment_account_name_empty": "Please fill into this field",
  "payment_account_no_empty": "Please fill into this field",
  "pool_inactive": "Pools not found",
  "provider_invalid": "Provider Invalid",
  "reason_too_long": "Reason too long",
  "request_not_found": "Request not found",
  "result_time_should_be_later_close_time": "Result time must be later than close time",
  "suspended_account": "Cannot perform action, your account has been suspended, please contact your upline",
  "too_many_ips": "IP limited",
  "two_bank_account_same_bank_one_group": "Two bank accounts with same bank in one group",
  "type_search_invalid": "Type Search Invalid",
  "upline_bank_account_not_found": "Upline bank account not found",
  "withdraw_greater_max": "Withdraw amount too big",
  "withdraw_less_min": "Withdraw too small",
  "withdraw_min_greater_equal_withdraw_max": "Min withdraw is greater than max withdraw",
  "invalid_whitelabel_downline": "Invalid whitelabel downline",
  "invalid_template_key_length": "Invalid banner template",
  "invalid_title_length":	"invalid length title",
  "invalid_content_length": "Invalid length content",
  "member_has_no_payment_account": "Not allow a member with no payment account.",
  "free_bet_condition_turnover_amount_invalid": "Invalid amount",
  "free_bet_condition_withdraw_amount_invalid": "Invalid amount",
  "free_bet_provider_min_amount_invalid": "Invalid min amount",
  "free_bet_provider_max_bonus_invalid": "Invalid max bonus",
  "free_bet_provider_rate_invalid": "Invalid rate %",
  "invalid_start_period": "Please input period with 3 digits",
  "invalid_result_size": "Please input 10 result numbers (each result 4 digits) seperated by dash",
  "invalid_result_duplicate": "Result numbers duplicated"
}
import APIUtils from './APIUtils'

class LoginAPI {
  static generateCaptcha(data) {
    return APIUtils.POST(`/captcha/generate`, data)
      .then(response => response)
  }

  static login(username, password, captcha) {
    return APIUtils.POSTnoAuth('/login', {
      username: username,
      password: password,
      captcha,
    }).then(response => {
        const { token } = response.data
        APIUtils.saveToken(token)
        return response.data
    })
  }
}

export default LoginAPI;

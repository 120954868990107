import moment from 'moment'

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatAccountNo = s => {
  return s.toString().replace(/\d{4}(?=.)/g, '$& ');
}

export const convertDateRange = range => {
  if (!range) {
    return {
      start: moment().format('L'),
      end: moment().format('L')
    }
  }
  
  const dates = range.match(/\d{2}\/\d{2}\/\d{4}/g)
  if (dates.length !==  2) {
    return {
      start: "",
      end: ""
    }
  }

  return {
    start: dates[0],
    end: dates[1]
  }
}

// export const accountLevelFromAccountType = (accountType) => {
//   let level = ''
//   if (accountType.startsWith('admin')) {
//     level = 'admin'
//   } else if (accountType.startsWith('company')) {
//     level = 'company'
//   }
//   return level
// }

export const formatTime = (date) => {
  return moment(date).format("ddd, MMM DD, H:mm:ss Z");
}

export const translateList = (t, list) => {
  return list.map(element => { return t(element) })
}

export const mapBootstrapClassToStatus = (status) => {
  if (status.includes('lock')) {
    return 'danger'
  } 
  if (status.includes('suspend')) {
    return 'warning'
  }
  if (status.includes('active')) {
    return 'dark'
  }
  return 'primary'
}

export const customDateRangeDisplay = (t) => {
  const today = t('Today')
  const yesterday = t('Yesterday')
  const last7days = t('Last 7 Days')
  const last30days = t('Last 30 Days')
  const thisMonth = t('This Month')
  const lastMonth = t('Last Month')
  var obj = {}
  obj[today] = [moment(), moment()]
  obj[yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
  obj[last7days] = [moment().subtract(6, 'days'), moment()]
  obj[last30days] = [moment().subtract(29, 'days'), moment()]
  obj[thisMonth] = [moment().startOf('month'), moment().endOf('month')]
  obj[lastMonth] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  return obj
}

export const mapColorClassToStatus = (status) => {
  if (status.includes('lock')) {
    return 'lock-button';
  } 
  if (status.includes('suspend')) {
    return 'suspend-color';
  }
  if (status.includes('ban') || status === "inactive") {
    return 'ban-button';
  }
  return 'primary';
}
export default {
  "Action Log": "Action Log",
  "Add Promotion": "Tambah Promosi",
  "Admin Log": "Admin Logs",
  "Admin Menu": "Admin Menu",
  "Analyze Result": "Analisa Keluaran",
  "Auto Memo": "Auto Memo",
  "Bank": "Bank",
  "Bank Account Banned": "Banned Akun Bank",
  "Bank Account List": "Daftar Rekening Bank",
  "Bank Account Management": "Manajemen Akun Bank",
  "Bank Balance": "Balance Bank",
  "Bank Group": "Grup Bank",
  "Bank Limit Setting": "Batasan Deposit / Withdraw",
  "Bank List": "Daftar Bank",
  "Bank Management": "Manajemen Bank",
  "Bank Transfer Log": "Mutasi Bank",
  "BankList": "Daftar Bank",
  "Banner": "Spanduk",
  "Bets": "Bets",
  "Bets List": "List Bets",
  "Bets Table": "Bets Table",
  "Bonus": "Bonus",
  "Bonus Report": "Bonus Report",
  "Code": "Indonesian",
  "Commission": "Komisi",
  "Company": "Company",
  "Company Commission": "Komisi",
  "Company List": "Daftar Company",
  "Company Log": "Company Logs",
  "Company Menu": "Company Menu",
  "Create Bank": "Buat Bank",
  "Create Bank Account": "Buat Rekening Bank",
  "Create Company": "Buat Company",
  "Create Member": "Buat Member",
  "Create Sub Account": "Buat Sub Baru",
  "Create Sub Admin": "Buat Sub Akun",
  "Create Sub Company": "Buat Sub Akun",
  "Create Whitelabel": "Buat Whitelabel",
  "Customer Service": "Pelayanan pelanggan",
  "Customization": "Kustomisasi",
  "Daily Reports": "Providers Daily Report",
  "Dashboard": "Dasbor",
  "Deposit": "Setoran",
  "Device Report": "Device Report",
  "Display Bank List": "Bank Online Status",
  "Downline List": "Downline List",
  "Edit Company": "Edit Company",
  "Edit Pool": "Edit Pool",
  "Edit Profile": "Edit Profil",
  "Edit Sub Account": "Edit Sub Akun",
  "Edit Whitelabel": "Edit Whitelabel",
  "Free Bet": "Free Bet",
  "Free Bet Report": "Free Bet Report",
  "Game Settings": "Games",
  "Get Bank Account": "Get Bank Account",
  "Global Member": "Daftar Member Global",
  "Home": "Home",
  "Information": "Informasi",
  "Limit Line": "Limit Line",
  "Limit Setting": "Limit Settings",
  "Limit Transfer Bets": "Limit Transfer Bets",
  "Log": "Log",
  "Member": "Member",
  "Member Edit": "Edit Member",
  "Member List": "Daftar Member",
  "Member Log": "Member Logs",
  "Memo": "Memo",
  "Pools List": "Daftar Pasaran",
  "Promotion": "Promosi",
  "Referral Rate": "Referral Rate",
  "Referral Report": "Report Referal",
  "Reports": "Laporan",
  "Request Manage": "Manajemen Permintaan",
  "Result List": "Hasil Keluaran",
  "Results": "Keluaran",
  "Running Text": "Running Text",
  "Scan Result": "Scan Keluaran",
  "Settings": "Pengaturan",
  "Site Config": "Site Config",
  "Sub Account": "Sub Akun",
  "Sub Account List": "Daftar Sub Akun",
  "Sub Admin List": "Daftar Sub Akun",
  "Sub Company List": "Daftar Sub Akun",
  "Template": "Templates List",
  "Transaction History": "History Transaksi",
  "Transfer Bets": "Bets Buangan",
  "Transfer Log": "Transfer Log",
  "Upload Bonus": "Upload Bonus",
  "Web Page": "Web Page",
  "Whitelabel List": "Daftar Whitelabel",
  "Whitelabel Log": "Whitelabel Logs",
  "Win Loss Report": "Win Loss Report",
  "Win Loss Report Members": "Members Win Loss Report",
  "Withdraw": "Penarikan",
  "Master Log": "Master Logs",
  "Master": "Akun Master",
  "Create Master Account": "Buat Akun Master",
  "Master Account List": "Daftar Akun Master",
  "Global Banner":	"Spanduk Global",
  "Global Promotion":	"Promosi Global",
  "Add Global Promotion": "Tambah Promosi Global",
  "App Notification": "Pemberitahuan Aplikasi",
  "Notification List": "Daftar Pemberitahuan Aplikasi",
  "Notification Add": "Tambahkan Pemberitahuan",
  "Notification Edit": "Edit Pemberitahuan",
  "Togel Commission": "Komisi Togel",
  "Agent Daily Report": "Agent Daily Report",
  "CQ9": "CQ9",
  "Joker": "JokerGaming",
  "Spade": "SpadeGaming",
  "PragmaticPlayLive": "Pragmatic Play Live",
  "PGSoft":	"PG Soft",
  "Pragmatic": "Pragmatic Play",
  "Microgaming": "Microgaming",
  "TTG": "TTG",
  "Bonus Tracking": "Bonus Tracking"
}
import { UPDATE_RESULT_STATUS, IS_NEW_BACKEND_CLIENT_VERSION } from "../constants";

const initState = {
  resultStatus: "",
  backendClientVersion: "",
  isReloadPage: false,
}

const status = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_RESULT_STATUS:
      return {
        ...state,
        resultStatus: action.payload
      }
    case IS_NEW_BACKEND_CLIENT_VERSION:
      return {
        ...state,
        isReloadPage: action.payload
      }
    default:
      return state;
  }
}

export default status;
import React, {
  useState, useEffect, lazy, Suspense
} from 'react';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { renderRoutes} from 'react-router-config';
import AuthAPI from './api/AuthAPI'
import APIUtils from './api/APIUtils'
import SocketComponent from './SocketComponent'
import { routes, loginRoutes } from './routes';
import { NotificationProvider } from 'context/NotificationContext';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import 'babel-polyfill';



const InformModal = lazy(() => import("views/Shared/InformModal/InformModal"));
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
const history = createBrowserHistory();

const App = () => {
  const [r, setRoutes] = useState([]);
  const token = useSelector(state => state.auth.token);
  const isReloadPage = useSelector(state => state.status.isReloadPage);
  const [firstToken, setFirstToken] = useState(token);

  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')))
  const initialNotification = {
    deposit: 0,
    withdraw: 0,
    needUpdate: false
  };

  const notificationReducer = (state, action) => {
    switch (action.type) {
      case 'update_notification':
        return {
          ...state,
          has_new_request: action.has_new_request,
          deposit: action.deposit,
          withdraw: action.withdraw,
        };
      case 'reconnect_notification': {
        return {
          ...state,
          needUpdate: !state.needUpdate
        }
      }
      default:
        return state;
    }
  };

  useEffect(() => {
    if (firstToken && firstToken !== token) {
      if (token === "") {
        window.location.href = '/login'
      } else {
        window.location.reload();
      }
    }
  }, [token, firstToken])

  useEffect(() => {
    if (!firstToken) {
      setFirstToken(token)
    }
  }, [token, setFirstToken])

  useEffect(() => {
    if (APIUtils.isAuthed())  {
      AuthAPI.authorize().then((user) => {
        setCurrentUser(user)
        return user
      }).then((user) => {
        routes(user).then((rr) => {
          setRoutes(rr)
        })
      })
    } else {
      setRoutes(loginRoutes)
    }
  }, [])

  return (
    <div>
      <NotificationProvider initialState={initialNotification} reducer={notificationReducer}>
      <Router history={history}>
        <Helmet>
          <title>VIGOR BACKEND</title>
        </Helmet>
        <Suspense fallback={loading()}>
          <ToastContainer position="top-right" autoClose={5000}/>
          <SocketComponent />
          <InformModal
            open={isReloadPage}
          />
          {renderRoutes(r, { currentUser: currentUser })}
        </Suspense>
      </Router>
    </NotificationProvider>
    </div>
  );
}

export default App;


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
import translationEN from './locales/en/translation';
import errorEN from './locales/en/err';
import menuEN from './locales/en/menu';

import translationVI from './locales/vi/translation';
import errorVI from './locales/vi/err';
import menuVI from './locales/vi/menu';

import translationID from './locales/id/translation';
import errorID from './locales/id/err';
import menuID from './locales/id/menu';

const resources = {
  en: {
    translation: translationEN,
    err: errorEN,
    menu: menuEN
  },
  vi: {
    translation: translationVI,
    err: errorVI,
    menu: menuVI
  },
  id: {
    translation: translationID,
    err: errorID,
    menu: menuID
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;
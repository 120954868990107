import React, { useState, useEffect } from "react";
import { Input } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import ImageApi from 'api/image'

function InputFile(props) {
  const {absoluteDefaultValue, defaultValue, setValue, setError, clearError, name, innerRef, ...restOfProps} = props
  const { t } = useTranslation(["err", "translation"])
  const [absoluteUrl, setAbsoluteUrl] = useState('');

  useEffect(() => {
    setAbsoluteUrl(absoluteDefaultValue)
  }, [absoluteDefaultValue]);

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      clearError(name)
      ImageApi.upload(file).then(res =>{
        setAbsoluteUrl(res.absolute_url)
        setValue(name, res.relative_url)
      }).catch((err) => {
        setError(name, 'error', t(err.message))
      })
    }
  }

  const renderPreview = () => {
    if (absoluteUrl) {
      return <img alt='preview-input' style={{'width': '200px'}} src={absoluteUrl}/>
    }
    return null
  }

  return (
    <>
      <Input type="file" onChange={handleChange}/>
      <Input
        innerRef={innerRef}
        {...restOfProps}
        name={name}
        type="hidden"/>
      {renderPreview()}
    </>
  )
}

export default InputFile;

import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';

import {
	AppAside,
	AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
// import { navigation } from '../../_nav';

// routes config
// api
import AuthAPI from '../../api/AuthAPI'
import APIUtils from '../../api/APIUtils'
import DefaultHeader from './DefaultHeader'
import { useTranslation } from 'react-i18next';
const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));

const DefaultLayout = (props) => {
	const [t] = useTranslation()
	const loading = () => <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
	const { route, currentUser, ...attributes } = props;
	const [navigation, setNavigation] = useState({ items: [] })
	const [pageTitle, setPageTitle] = useState('VIGOR BACKEND');
	const history = useHistory()

	useEffect(() => {
		async function getNav() {
			await AuthAPI.getSideBar().then((rs) => {
				let nav = [rs.NavHeader, ...rs.NavItems]
				nav.forEach(item => {
					item.name = t('menu:' + item.name)
					if (item.children) {
						item.children.forEach(child => {
							child.name = t('menu:' + child.name)
						})
					}
				})
				setNavigation({ items: nav })
			}).catch((err) => {
				console.log(err)
			})
		}
		getNav();

		handleHeaderTitle();

		// handle webbrowser change url
		history.listen((location)=> {
			handleHeaderTitle();
		})

	}, [t]);

	const handleHeaderTitle = () => {
		const branch = matchRoutes(route.routes, window.location.pathname);
		if (branch && branch[0]) {
			setPageTitle(branch[0].route.name)
		}
	}

	const signOut = (e) => {
		e.preventDefault()
		APIUtils.logOut()
		// window.location.reload()
	}

	const goToProfile = (e) => {
		e.preventDefault()
		history.push(`/profile`)
	}

	return (
		<div className="app">
			 <Helmet>
				<title>{pageTitle}</title>
			</Helmet>
			<AppHeader fixed>
				<Suspense fallback={loading()}>
					<DefaultHeader goToProfile={e => goToProfile(e)} onLogout={e => signOut(e)} currentUser={currentUser} />
				</Suspense>
			</AppHeader>
			<div className="app-body">
				<AppSidebar fixed display="lg">
					<AppSidebarHeader />
					<AppSidebarForm />
					<Suspense>
						<AppSidebarNav navConfig={navigation} {...attributes} />
					</Suspense>
					<AppSidebarFooter />
					<AppSidebarMinimizer />
				</AppSidebar>
				<main className="main">
					<AppBreadcrumb appRoutes={route.routes} />
					<Container fluid>
						<p></p>
						<Suspense fallback={loading()}>
							{renderRoutes(route.routes, { currentUser: currentUser })}
						</Suspense>
					</Container>
				</main>
				<AppAside fixed>
					<Suspense fallback={loading()}>
						<DefaultAside />
					</Suspense>
				</AppAside>
			</div>
			<AppFooter>
				<Suspense fallback={loading()}>
					<DefaultFooter />
				</Suspense>
			</AppFooter>
		</div>
	);
}

export default DefaultLayout;

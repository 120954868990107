import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';
import get from 'lodash/get';
import { useNotification } from 'context/NotificationContext';
import { accountType } from "views/Shared/utils/function";

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import TopBarAPI from 'api/TopBarAPI';
import useRouter from 'views/Shared/hooks/useRouter';
import logo from 'assets/img/brand/logo.png'
import sygnet from 'assets/img/brand/favicon.png';

const DefaultHeader = (props) => {
  const { t } = useTranslation();
  const userProfile = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState({});
  const router = useRouter();
  const [notification, dispatchNotification] = useNotification();

  useEffect(() => {
    TopBarAPI.getTopBarMenu().then(res => {
      setData(res);
      dispatchNotification({type: 'update_notification', deposit: res.deposit, withdraw: res.withdraw})
    })
  }, [dispatchNotification, notification.needUpdate])

  const goToLogPage = () => {
    if (accountType() === 'Admin') {
      router.history.push(`/action-log/admin-logs?keywords=${userProfile.username}&search=username`)
    } else if (accountType() === 'Company') {
      router.history.push(`/action-log/c-company-logs?keywords=${userProfile.username}&search=username`)
    } else if (accountType() === "Master") {
      router.history.push(`/action-log/master-whitelabel-logs?keywords=${userProfile.username}&search=username`)
    } else if (accountType() === 'Whitelabel') {
      router.history.push(`/action-log/c-whitelabel-logs?keywords=${userProfile.username}&search=username`)
    }
  }

  const renderActivity = () => {
    const activity = get(data, 'activity', {});

    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="div">
          <h4 id="activityHeader" className='notification-wrapper'>
            <i className="fas fa-clipboard-list" />
            <UncontrolledTooltip placement="bottom" target="activityHeader">
              {t('Activity')}
            </UncontrolledTooltip>
          </h4>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>{t('Activity')}</DropdownItem>
          {
            !!get(activity, 'last_failed_login.date_time') && <DropdownItem onClick={goToLogPage}>
              <h6 className='mb-0'>
                <Trans i18nKey='Last Failed Login From' values={{ip: activity.last_failed_login.ip}} />
              </h6>
              <small>{activity.last_failed_login.date_time}</small>
            </DropdownItem>
          }
          {
            !!(+activity.count_failed_login) && <DropdownItem onClick={goToLogPage}>
              <h6 className='mb-0'>
                <Trans i18nKey='Failed Attempts Since Last Login' values={{times: activity.count_failed_login}}/>
              </h6>
            </DropdownItem>
          }
          {
            !!get(activity, 'last_login.date_time') && <DropdownItem onClick={goToLogPage}>
              <h6 className='mb-0'>
                <Trans i18nKey='Last Login From' values={{ip: activity.last_login.ip}} />
              </h6>
              <small>{activity.last_login.date_time}</small>
            </DropdownItem>
          }
          <DropdownItem onClick={goToLogPage}>
            <div className='text-center'>{t('All Activities')}</div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center left-header">
        <AppSidebarToggler className="d-lg-none align-items-center" display="md" mobile />
        <div className="d-flex align-items-center">
          <AppNavbarBrand
            full={{ src: logo, width: 'auto', height: 35, alt: 'Lottery Logo' }}
            minimized={{ src: sygnet, width: 30, height: 30, alt: 'Lottery Logo' }}
          />
          <AppSidebarToggler className="d-md-down-none" display="lg" />
        </div>
      </div>
      <div className="d-flex align-items-end" style={{ background: "#ffffff" }}>
        <div className="d-flex align-items-end mr-4">
          {
            notification.deposit !== undefined && <h4 className='notification-wrapper mr-4' id="depositHeader">
              <Link to='/request-management/deposit'>
                <i className="fas fa-hand-holding-usd"/>
                <UncontrolledTooltip placement="bottom" target="depositHeader">
                  {t('Deposit')}
                </UncontrolledTooltip>
              </Link>
              {notification.deposit ? <div
                className='notification-counter bg-danger text-white'>{notification.deposit}</div> : <></>}
            </h4>
          }
          {
            notification.withdraw !== undefined && <h4 className='notification-wrapper mr-4' id="withdrawHeader">
              <Link to='/request-management/withdraw'>
                <i className="fas fa-money-bill-wave"/>
                <UncontrolledTooltip placement="bottom" target="withdrawHeader">
                  {t('Withdraw')}
                </UncontrolledTooltip>
              </Link>
              {notification.withdraw ? <div
                className='notification-counter bg-danger text-white'>{notification.withdraw}</div> : <></>}
            </h4>
          }
          { renderActivity() }
        </div>
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3 d-none" >
            <NavLink to="/dashboard" className="nav-link" >Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3 d-none">
            <NavLink to="/users" className="nav-link">Users</NavLink>
          </NavItem>
          <NavItem className="px-3 d-none">
            <NavLink to="#" className="nav-link">When things go wrong. Disable cache !!!!!</NavLink>
          </NavItem>
        </Nav>
        <Nav className="" navbar>
          <DefaultHeaderDropdown
            goToProfile={props.goToProfile}
            onLogout={props.onLogout}
            currentUser={props.currentUser}
            accnt 
          />
        </Nav>
      </div>
    </React.Fragment>
  );
}

export default DefaultHeader;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { 
  Button, Card, CardBody, CardGroup, Col, Container, Form,
  FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Row 
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import LoginAPI from 'api/LoginAPI'
import APIUtils from 'api/APIUtils'
import Captcha from 'views/Shared/Captcha/Captcha';

function Login(props) {
  const { 
    register, handleSubmit, errors, setError, control,
    setValue, watch, clearError
  } = useForm(); // initialise the hook
  const { t } = useTranslation(['translation','err',]);
  const [logOutReason, setLogOutReason] = useState(APIUtils.getLogOutReason())
  const token = useSelector(state => state.auth.token);
  const [captcha, setCaptcha] = useState('');
  const captchaRef = useRef(null);

  const verifyValue = watch('captcha.verify_value')

  useEffect(() => {
    if (!!token) {
      // Reload when login
      window.location.reload();
    }
  }, [token])

  useEffect(() => {
    if (logOutReason) {
      setError('username', 'error', t(logOutReason))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOutReason]);

  useEffect(() => {
    if (verifyValue && verifyValue.length === 1) {
      clearError('captcha')
    }
  }, [verifyValue]);


  const onSubmit = data => {
    const { captcha: { verify_value } } = data;

    APIUtils.deleteLogOutReason()
    setLogOutReason('')
    LoginAPI.login(data.username, data.password, {
      verify_value,
      captcha_id: captcha,
    })
    .catch((err) => {
      if (err.message) {
        for (const field in err.data) {
          setError(field, 'error', t(err.data[field]))
          setValue('captcha.verify_value', '')
        }
      }
      resetRef();
    })
  };

  const onChangeCaptcha = (captcha) => {
    setCaptcha(captcha);
  }

  const resetRef = () => {
    captchaRef.current.reset();
  }

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xl="4" md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1>{t('Login')}</h1>
                    <p className="text-muted">{t("Sign In to your account")}</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="username" innerRef={register} invalid={!!errors.username}
                        type="text" placeholder={t("Username")} autoComplete="username" />
                      <FormFeedback>{errors.username && errors.username.message}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="password" innerRef={register} invalid={!!errors.password}
                        type="password" placeholder={t("Password")} autoComplete="current-password" />
                      <FormFeedback>{errors.password && errors.password.message}</FormFeedback>
                    </InputGroup>
                    <InputGroup>
                      <Captcha
                        onChange={onChangeCaptcha}
                        ref={captchaRef}
                      />
                      <Input
                        innerRef={register}
                        name="captcha"
                        type="hidden"
                      />
                      {/* <FormFeedback className="mb-3">{errors.captcha}</FormFeedback> */}
                      <Controller
                        as={NumberFormat}
                        className="form-control ml-2"
                        style={{
                          height: 25
                        }}
                        maxLength="5"
                        rules={{
                          required: true,
                        }}
                        name="captcha.verify_value"
                        autoComplete="off"
                        control={control}
                        allowLeadingZeros
                        allowNegative={false}
                        decimalScale={0}
                      />
                      <FormFeedback className="mb-3">{errors.recaptcha && errors.recaptcha.message}</FormFeedback>
                    </InputGroup>
                    {(errors?.captcha && !errors?.captcha?.verify_value) && <small className="required-field-error">{t(errors.captcha.message)}</small>}
                    {errors?.captcha?.verify_value?.type === 'required' && <small className="required-field-error">{t('required_captcha')}</small>}
                    <Row className="mt-2">
                      <Col>
                        <Button color="primary" className="px-4">{t('Login')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
